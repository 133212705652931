import { config } from '../../config';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import { notification } from 'antd';
import { DEFAULT_ZERO_VALUE, MAX_PERCENTAGE } from '../../assets/constants/global';
import { DISCOUNT_TYPE, ADDITIONAL_OPTION_TYPE } from './home-constants';
import { userService } from '../user/user-service';
import { geocodeByAddress } from 'react-places-autocomplete';
import Cookies from 'js-cookie';

const loadResultsAction = (options) => {
    options.startTripDatetime = moment(options.startTripDatetime).format("YYYY-MM-DDTHH:mm:ss.ms");
    options.returnTransferTripDatetime = options.returnTransferTripDatetime ? moment(options.returnTransferTripDatetime).format("YYYY-MM-DDTHH:mm:ss.ms") : null;
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(options)
    };
    
    return fetch(`${config.apiUrl}/api/route/get`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data) {
                return { ...data, pickupLocationObj: options.pickupLocation, destinationObj: options.dropoffLocation };
            }
        });
}

const loadHtml= (fileKey) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(fileKey)
    };
    
    return fetch(`${config.apiUrl}/api/htmlStaticPages/load-html`, requestOptions)
        .then(data => {
            if (data) {
                return data.text();
            }
        });
}

const loadResults = (options, pickupLocationString, destinationString) => {
    let { pickupLocation, dropoffLocation } = options;
    if (!pickupLocation || !dropoffLocation) {
        return Promise.all([ getAddressFromString(pickupLocationString), getAddressFromString(destinationString) ])
            .then(values => {
                if (values) {
                    options.pickupLocation = values[0];
                    options.dropoffLocation = values[1];
                }
            })
            .then(() => loadResultsAction(options))
    }

    return Promise.resolve(loadResultsAction(options));
}

const loadLocations = (pickupLocationString, destinationString) => {
    return Promise.all([ getAddressFromString(pickupLocationString), getAddressFromString(destinationString) ])
        .then(values => {
            if (values) {
                return { pickupLocation: values[0], dropoffLocation: values[1] }
            }
        })
}

const logUnfinalized = (obj) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj)
    };

    return fetch(`${config.apiUrl}/api/unfinalized-trip/create`, requestOptions)
        .then(handleResponse);
}

const handleResponse = (response) => {
    return response ? response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 400 || response.status === 404) {
                return Promise.reject(data);
            }

            if (response.status === 401) {
                return userService.refreshToken();
            }

            const error = (data && data.message) || data || response.statusText;
            return Promise.reject(error);
        }

        return data;
    }) : Promise.reject('error');
}

const routeNotFoundSubmitAction = (tripRequest, user) => {
    tripRequest.startTripDatetime = moment(tripRequest.startTripDatetime).format("YYYY-MM-DDTHH:mm:ss.ms");
    tripRequest.returnTransferTripDatetime = tripRequest.returnTransferTripDatetime ? moment(tripRequest.returnTransferTripDatetime).format("YYYY-MM-DDTHH:mm:ss.ms") : null;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user, trip: tripRequest })
    };

    return fetch(`${config.apiUrl}/api/notification/route-not-found`, requestOptions)
        .then(handleResponse);
}

const routeNotFoundSubmit = (tripRequest, user, pickupLocationString, destinationString) => {
    let { pickupLocation, dropoffLocation } = tripRequest;
    if (!pickupLocation || !dropoffLocation) {
        return Promise.all([ getAddressFromString(pickupLocationString), getAddressFromString(destinationString) ])
            .then(values => {
                if (values) {
                    tripRequest.pickupLocation = values[0];
                    tripRequest.dropoffLocation = values[1];
                }
            })
            .then(() => routeNotFoundSubmitAction(tripRequest, user))
    }

    return Promise.resolve(routeNotFoundSubmitAction(tripRequest, user));
}

const fetchSessionId = (summary, user, tripJson, userName) => {
    summary.startTripDatetime = moment(summary.startTripDatetime).format("YYYY-MM-DDTHH:mm:ss.ms");
    summary.returnTransferTripDatetime = summary.returnTransferTripDatetime ? moment(summary.returnTransferTripDatetime).format("YYYY-MM-DDTHH:mm:ss.ms") : null;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ trip: summary, user, tripJson, userName })
    };

    return fetch(`${config.apiUrl}/api/stripe/checkout-session`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            if (data) {
                const { sessionId, tripIds } = data;

                return { sessionId, tripIds };
            }
        });
}

const createPaypal = (summary, user) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ trip: summary, user })
    };

    return fetch(`${config.apiUrl}/api/paypal/create`, requestOptions)
        .then(handleResponse);
}

const setDriverInfo = (driverInfo, isAgent) => {
    return checkDriverToken(driverInfo.token, isAgent)
        .then(data => {
            return confirmDriverInfo(driverInfo, isAgent);
        })
        .catch(error => {
            notification.error({
                message: error.toString()
            });
        })
}

const setDriverWithoutDetailsInfo = (token, isAgent) => {
    return checkDriverToken(token, isAgent)
        .then(data => {
            return confirmDriverWithoutDetailsInfo(token, isAgent);
        })
        .catch(error => {
            notification.error({
                message: error.toString()
            });
        })
}

const confirmDriverInfo = (driverInfo, isAgent) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(driverInfo)
    };
    const url = isAgent ? `${config.apiUrl}/api/affiliate-confirmation/confirm-agent` : `${config.apiUrl}/api/affiliate-confirmation/confirm-driver`;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            notification.success({
                message: `${isAgent ? 'Agent' : 'Trip'} confirmed successfully!`
            });
        })
        .catch(error => {
            notification.error({
                message: error.toString()
            });
        });
}

const confirmDriverWithoutDetailsInfo = (token, isAgent) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(token)
    };
    const url = isAgent ? `${config.apiUrl}/api/affiliate-confirmation/confirm-agent-without-details` : `${config.apiUrl}/api/affiliate-confirmation/confirm-without-details`;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            notification.success({
                message: `${isAgent ? 'Agent' : 'Trip'} confirmed successfully!`
            });
        })
        .catch(error => {
            notification.error({
                message: error.toString()
            });
        });
}

const checkDriverToken = (token, isAgent) => {
    const requestOptions = {
        method: 'GET'
    };
 
    return fetch(`${config.apiUrl}/api/affiliate-confirmation/check-token?token=${token}&isAgent=${isAgent}`, requestOptions)
        .then(handleResponse)
        .catch(error => {
            throw error;
        });
}

const createCheckoutPaypal = (summary, user, tripJson, userName) => {
    summary.startTripDatetime = moment(summary.startTripDatetime).format("YYYY-MM-DDTHH:mm:ss.ms");
    summary.returnTransferTripDatetime = summary.returnTransferTripDatetime ? moment(summary.returnTransferTripDatetime).format("YYYY-MM-DDTHH:mm:ss.ms") : null;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ trip: summary, user, tripJson, userName })
    };

    return fetch(`${config.apiUrl}/api/paypal/checkout-session`, requestOptions)
    .then(handleResponse);   
}

const paypalCheckoutSessionCompleted = (tripStatusRequest) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(tripStatusRequest)
    };

    return fetch(`${config.apiUrl}/api/paypal/checkout-session-completed`, requestOptions)
        .then(response => response.ok);
}


const paypalCancelStatus = (tripStatusRequest) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(tripStatusRequest)
    };

    return fetch(`${config.apiUrl}/api/paypal/update-cancel-payment`, requestOptions)
        .then(response => response.ok);
}

const paypalFailStatus = (tripStatusRequest) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(tripStatusRequest)
    };

    return fetch(`${config.apiUrl}/api/paypal/update-failed-payment`, requestOptions)
        .then(response => response.ok);
}

const invoicePayment = (tripRequest, login, user, tripJson) => {
    tripRequest.startTripDatetime = moment(tripRequest.startTripDatetime).format("YYYY-MM-DDTHH:mm:ss.ms");
    tripRequest.returnTransferTripDatetime = tripRequest.returnTransferTripDatetime ? moment(tripRequest.returnTransferTripDatetime).format("YYYY-MM-DDTHH:mm:ss.ms") : null;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ login, trip: tripRequest, user, tripJson })
    };

    return fetch(`${config.apiUrl}/api/invoice/create-trip`, requestOptions)
        .then(handleResponse);
}

const updateCancelStatus = (summary, user, orderID) => {
    return createPaypal(summary, user)
            .then(tripIds => {
                
                return paypalCancelStatus({
                    sessionId: orderID,
                    sessionDetails: JSON.stringify({ orderID }),
                    tripIds
                });
            })
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

const updateFailStatus = (summary, user, err) => {
    return createPaypal(summary, user)
            .then(tripIds => {
                
                return paypalFailStatus({
                    sessionId: '',
                    sessionDetails: JSON.stringify(err),
                    tripIds
                });
            })
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

const beginCheckout = (summary, user, tripJson, userName) => {
    return Promise.all([ loadStripe(config.stripePKey), fetchSessionId(summary, user, tripJson, userName) ])
                  .then(values => {
                      const stripe = values[0];
                      const { sessionId } = values[1];
                  
                      if (stripe && sessionId) {
                          return { stripe, sessionId };
                      }
                  })
                  .then(({ stripe, sessionId }) => stripe.redirectToCheckout({ sessionId }))
                  .then(results => {
                      return results;
                  });
}

const beginEditStripeCheckout = (sessionId) => {
    return Promise.resolve(loadStripe(config.stripePKey))
                  .then(value => {
                      const stripe = value;
                  
                      if (stripe && sessionId) {
                          return { stripe, sessionId };
                      }
                  })
                  .then(({ stripe, sessionId }) => stripe.redirectToCheckout({ sessionId }))
                  .then(results => {
                      return results;
                  });
}

const editCheckout = (editTripRequest, userRequest, tripId) => {
    editTripRequest.startTripDatetime = moment(editTripRequest.startTripDatetime).format("YYYY-MM-DDTHH:mm:ss.ms");

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization': `Bearer ${Cookies.get('token')}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...editTripRequest, user: userRequest })
    };

    return fetch(`${config.apiUrl}/api/trip/edit`, requestOptions)
        .then(handleResponse)
        .then(({data}) => {
            const { sessionDetail, transactionPaymentType, type } = data;
            if (type === 'Unсhanged' || type === "Refund" || transactionPaymentType === null) {
                notification.success({
                    message: `Trip #${tripId} successfully edited!`
                });
                return { edited: true };
            } else if (type === 'Payment') {
                if (transactionPaymentType === 'Stripe') {
                    return beginEditStripeCheckout(sessionDetail.sessionId)
                     .then(ok => ({ ok }))
                } else if (transactionPaymentType === "PayPal") {
                    return { orderId: sessionDetail.orderId }
                }
            }
        });
}

const editCheckoutPaypal = (editTripRequest) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization': `Bearer ${Cookies.get('token')}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(editTripRequest)
    };

    return fetch(`${config.apiUrl}/api/trip/edit`, requestOptions)
        .then(handleResponse)
        .then(({ data }) => {
            const { sessionDetail, transactionPaymentType, type } = data;
            if (type === 'Payment' && transactionPaymentType === "PayPal") {
                return { orderId: sessionDetail.orderId };
            }
        });
}

const returnToCheckout = (sessionId) => {
    const requestOptions = {
        method: 'GET'
    };
 
    return fetch(`${config.apiUrl}/api/stripe/get-trip-json?tripJsonId=${sessionId}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

const checkPromocode = (promocode) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ currentDate: moment(), promoCode: promocode })
    };

    return fetch(`${config.apiUrl}/api/promocode/apply`, requestOptions)
        .then(handleResponse);
}

const getAddressFromString = (address) => {
    return geocodeByAddress(address)
        .then(results => {
            return results[0];
        })
        .catch(error => console.error('Error', error));
}

const calculateTotal = (addedOptions, selectedCar, returnTransfer) => {
    const trip = calculateTrip(selectedCar);
    const returnTrip = returnTransfer ? calculateReturnTrip(selectedCar) : DEFAULT_ZERO_VALUE;
    const additionalOptions = calculateAdditionalOptions(selectedCar, addedOptions);
    const returnAdditionalOptions = returnTransfer ? additionalOptions : DEFAULT_ZERO_VALUE;

    return trip + returnTrip + additionalOptions + returnAdditionalOptions;
}

const calculateAdditionalOptions = (selectedCar, addedOptions) => {
    if (selectedCar) {
        let total = DEFAULT_ZERO_VALUE;

        Object.keys(addedOptions).forEach(key => {
            if (addedOptions[key] && addedOptions[key] > DEFAULT_ZERO_VALUE) {
                let option = selectedCar.additionalOptions.find(x => x.name === key);
                if (option) {
                    if (option.type === ADDITIONAL_OPTION_TYPE.VIP) {
                        let count = addedOptions[key];

                        if (count > option.maxClients) {
                            const numberSet = parseInt(Math.ceil((count - option.maxClients) / option.extraClients), 10);
                            total+= option.price + numberSet * option.extraClientPrice;
                        } else {
                            total += option.price;
                        }
                    } else {
                        total+= option.variants && option.variants.length > DEFAULT_ZERO_VALUE 
                              ? DEFAULT_ZERO_VALUE : option.countable 
                              ? option.price * addedOptions[key] : option.price;
                    }
                } else {
                    option = selectedCar.additionalOptions
                          && selectedCar.additionalOptions.find(y => y.variants && y.variants.find(x => x.name === key));
                    
                    if (option) {
                        total+= option.countable ? option.price * addedOptions[key] : option.price;
                    }
                }

                
            }
        });

        return total;
    }

    return DEFAULT_ZERO_VALUE;
}

const calculateTrip = (selectedCar) => {
    if (selectedCar) {
        const tripPrice = selectedCar.price ? selectedCar.price : DEFAULT_ZERO_VALUE;
        let total = tripPrice;

        if (selectedCar.extra_client_payments) {
            selectedCar.extra_client_payments.forEach(extra => {
                total += extra.clientTypeOfRule === DISCOUNT_TYPE.Percentage ? (tripPrice * extra.clientExtraPrice / MAX_PERCENTAGE) : extra.clientExtraPrice;
            });
        }

        return total;
    }

    return DEFAULT_ZERO_VALUE;
}

const calculateReturnTrip = (selectedCar) => {
    if (selectedCar) {
        const tripPrice = selectedCar.return_transfer_price ? selectedCar.return_transfer_price : DEFAULT_ZERO_VALUE;
        let total = tripPrice;

        if (selectedCar.return_transfer_extra_client_payments) {
            selectedCar.return_transfer_extra_client_payments.forEach(extra => {
                total += extra.clientTypeOfRule === DISCOUNT_TYPE.Percentage ? (tripPrice * extra.clientExtraPrice / MAX_PERCENTAGE) : extra.clientExtraPrice;
            });
        }

        return total;
    }

    return DEFAULT_ZERO_VALUE;
}

const calculateDiscount = (selectedCar, value, typeOfRule, returnTransfer) => {
    const trip = calculateTrip(selectedCar);
    const returnTrip = returnTransfer ? calculateReturnTrip(selectedCar) : DEFAULT_ZERO_VALUE;
    const tripPrice = trip + returnTrip;
    
    return typeOfRule === DISCOUNT_TYPE.Money ? value : tripPrice * value / MAX_PERCENTAGE;
}

export const homeService = {
    loadHtml,
    loadResults,
    beginCheckout,
    calculateTotal,
    calculateDiscount,
    createCheckoutPaypal,
    paypalCheckoutSessionCompleted,
    updateCancelStatus,
    updateFailStatus,
    invoicePayment,
    editCheckoutPaypal,
    routeNotFoundSubmit,
    returnToCheckout,
    checkPromocode,
    setDriverInfo,
    setDriverWithoutDetailsInfo,
    editCheckout,
    getAddressFromString,
    loadLocations,
    paypalCancelStatus,
    paypalFailStatus,
    logUnfinalized
};