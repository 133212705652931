import React from "react";
import { DEFAULT_ZERO_VALUE, MAX_PERCENTAGE, STRING_EMPTY } from '../../assets/constants/global';
import LoadingOverlay from 'react-loading-overlay';
import { Table, Tooltip, Upload, notification, Pagination, Collapse } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    CopyOutlined,
    CloseOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import validator from 'validator';
import JSONPretty from 'react-json-pretty';
import moment from 'moment';

const { Panel } = Collapse;

const beforeUpload = (setImportedData) => (file) => {
    const ext = file && file.name && file.name.split('.').pop();
    
    if (ext === 'xlsx' || ext === 'xls') {
        setImportedData(file);
    
        return true;
    }
    
    notification.error({
        message: `The specified file "${file.name}" could not be loaded. Only files with the following extension are allowed: *.xlsx, *.xls`,
        duration: 6
    });

    return false;
}

const getTooltipBody = (text, columnType) => {
    const formattedText = renderText(text, columnType);
    const tooltip = validator.isURL(formattedText.toString()) && !validator.isEmail(formattedText.toString()) 
                  ? <img src={text} alt={text} /> 
                  : <JSONPretty theme={{
                        main: 'color:#66d9ef;margin:0;',
                        error: 'color:#66d9ef;margin:0;',
                        key: 'color:#f92672;',
                        string: 'color:#fd971f;',
                        value: 'color:#a6e22e;',
                        boolean: 'color:#ac81fe;',
                    }} data={formattedText}></JSONPretty>;

    return (
        <div className='d-flex align-items-center justify-content-start overflow-auto'>
            <CopyToClipboard text={formattedText} onCopy={() => notification.success({ message: `"${formattedText}" copied!` })}>
                <CopyOutlined />
            </CopyToClipboard>
            {tooltip}
        </div>
    )
}

const renderText = (text, columnType) => {
    if (text) {
        if(columnType && columnType.type) {
            if (Array.isArray(text)) {
                return JSON.stringify(text);
            }

            if (columnType.type === "System.String") {
                return text.toString();
            }

            if (columnType.type === "System.DateTime") {
                return moment(text).format('lll Z');
            }

            return JSON.stringify(text);
        }

        return JSON.stringify(text);
    }

    return STRING_EMPTY;
}

export const BaseAdmin = ({
    data, errorMsg, isLoaded, pagination, handleImportData, specialRowKey = x => x.id,
    handleExportData, handlePageChange, handlePageSizeChange,
    setImportedData, downloadTemplate, cleanErrorMsg, sortData,
    showImport = true, showTemplate = true, showExport = true, showAdd = false,
    handleAddData, addTitle, columnTypes, specialColumns, specialHeader, rowSelection = null
}) => {
    const propCount = data && data.length > DEFAULT_ZERO_VALUE 
                    ? Object.keys(data[DEFAULT_ZERO_VALUE]).length 
                    : DEFAULT_ZERO_VALUE;
    const columns = data 
                 && data.length > DEFAULT_ZERO_VALUE
                 && Object.keys(data[DEFAULT_ZERO_VALUE])
                          .map(key => {
                                const columnType = columnTypes.find(x => x.name.toLowerCase() === key.toLowerCase());
                              
                                return {
                                    key,
                                    dataIndex: key,
                                    sorter: columnType ? columnType.sortable : true,
                                    showSorterTooltip: false,
                                    width: `${MAX_PERCENTAGE / propCount}%`,
                                    title: <Tooltip title={getTooltipBody(key)}><div className='text-truncate text-uppercase'>{key}</div></Tooltip>,
                                    render: text => <Tooltip title={getTooltipBody(text, columnType)}><div className='text-truncate'>{renderText(text, columnType)}</div></Tooltip>
                                }
                        });

    return ( // todo: Add resources
        <div className='d-flex flex-column admin-page w-100'>
            <LoadingOverlay className="w-100 h-100" active={!isLoaded} spinner>
                {
                    errorMsg && errorMsg.length > DEFAULT_ZERO_VALUE && (
                        <div className="d-flex">
                            <Collapse className='w-100 mb-3 admin-validation' defaultActiveKey={['1']}>
                                <Panel header={<div className='d-flex align-items-center justify-content-between'>Import Validation Messages <CloseOutlined onClick={cleanErrorMsg} /></div>} key="1">
                                    { errorMsg.map((msg, idx) => (<div className='d-flex align-items-center error-item' key={`validation_message_${idx}`}><ExclamationCircleOutlined />{msg}</div>)) }
                                </Panel>
                            </Collapse>
                        </div>
                    )
                }
                {
                    specialHeader && (
                        <div className="d-flex w-100">{specialHeader}</div>
                    )
                }
                <div className="d-flex">
                    {
                        showImport && (
                            <Upload beforeUpload={beforeUpload(setImportedData)}
                                customRequest={handleImportData}
                                showUploadList={false}>
                                <button className='export-import'>Import</button>
                            </Upload>
                        )
                    }
                    { showExport && <button onClick={handleExportData} className='export-import'>Export</button> }
                    { showTemplate && <button className='template' onClick={downloadTemplate}><FileExcelOutlined />Download Template</button> }
                    { showAdd && <button onClick={handleAddData} className='export-import'>{addTitle}</button> }
                </div>
                <div className="d-flex admin-table pt-3 w-100">
                    {
                        data && (
                            <Table rowKey={specialRowKey}
                                   className='w-100'
                                   onChange={sortData}
                                   columns={specialColumns || columns}
                                   dataSource={data}
                                   pagination={false}
                                   rowSelection={rowSelection} />
                        )
                    }
                </div>
                {
                    pagination && (
                        <div className="d-flex pt-3">
                            <Pagination current={pagination.offset}
                                        pageSize={pagination.limit}
                                        total={pagination.total}
                                        onChange={handlePageChange}
                                        onShowSizeChange={handlePageSizeChange}
                                        showSizeChanger={true}
                                        pageSizeOptions={['5', '10', '25', '50']} />
                        </div>
                    )
                }
            </LoadingOverlay>
        </div>
    );
}