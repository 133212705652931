import React from 'react';
import { connect } from 'react-redux';
import { withAdminLayout } from '../../common/admin-layout';
import { ADMIN_PAGES } from '../../assets/constants/global';
import { adminActions } from './admin-reducer';
import { BaseAdmin } from './base';
import { PROP_KEYS, ACTION_LOAD_TYPES, TEMPLATE_TYPES } from './admin-constants';

class AdminNotFoundRoutes extends React.Component {
    componentDidMount() {
        const { loadData } = this.props;

        loadData();
    }

    render() {
        const { 
            notFoundRoutes, handleDataPageChange, handleDataPageSizeChange,
            notFoundRoutesPagination, isLoadedNotFoundRoutes, exportData,
            downloadTemplate, cleanDataErrorMsg, handleSort,
            notFoundRouteColumnTypes
        } = this.props;
        const baseProps = {
            data: notFoundRoutes,
            isLoaded: isLoadedNotFoundRoutes,
            handleExportData: exportData,
            downloadTemplate: downloadTemplate,
            pagination: notFoundRoutesPagination,
            handlePageChange: handleDataPageChange,
            handlePageSizeChange: handleDataPageSizeChange,
            cleanErrorMsg: cleanDataErrorMsg,
            sortData: handleSort,
            columnTypes: notFoundRouteColumnTypes,
            showImport: false,
            showTemplate: false
        };

        return (
            <BaseAdmin {...baseProps} />
        );
    }
}

const mapStateToProps = (state) => {
    const { admin } = state;
    const { notFoundRoutes, notFoundRoutesPagination, isLoadedNotFoundRoutes, notFoundRouteColumnTypes } = admin;

    return {
        notFoundRoutes, isLoadedNotFoundRoutes, notFoundRoutesPagination, notFoundRouteColumnTypes
    };
}

const { action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey } = {
    action: ACTION_LOAD_TYPES.notFoundRoute,
    isLoadedKey: PROP_KEYS.isLoadedNotFoundRoutes,
    paginationKey: PROP_KEYS.notFoundRoutesPagination,
    dataKey: PROP_KEYS.notFoundRoutes,
    fileKey: TEMPLATE_TYPES.NotFoundRoute,
    columnTypesKey: PROP_KEYS.notFoundRouteColumnTypes
}

const mapDispatchToProps = {
    loadData: () => adminActions.load({ 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    exportData: () => adminActions.exportData({ 
        action, isLoadedKey, fileKey
    }),
    downloadTemplate: () => adminActions.downloadTemplate({
        isLoadedKey, fileKey
    }),
    handleDataPageChange: (page, pageSize) => adminActions.handlePaginationChange(page, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleDataPageSizeChange: (_page, pageSize) => adminActions.handlePaginationChange(1, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleSort: (_pagination, _filters, sorter) => adminActions.handleSort(1, sorter, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    })
}

const connectedAdminNotFoundRoutes = connect(mapStateToProps, mapDispatchToProps)(withAdminLayout(AdminNotFoundRoutes, ADMIN_PAGES.AdminNotFoundRoutes));
export { connectedAdminNotFoundRoutes as AdminNotFoundRoutes };