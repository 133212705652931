import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withAdminLayout } from '../../common/admin-layout';
import { ADMIN_PAGES } from '../../assets/constants/global';
import { adminActions } from './admin-reducer';
import { userActions } from '../user/user-reducer';
import { BaseAdmin } from './base';
import { PROP_KEYS, ACTION_LOAD_TYPES, TEMPLATE_TYPES } from './admin-constants';
import {
    UserAddOutlined
} from '@ant-design/icons';
import I18n from '../../i18n';
import { Signin } from '../../global/signin';
import { Link } from 'react-router-i18n';

class AdminAccounts extends React.Component {
    componentDidMount() {
        const { loadData, signin } = this.props;

        if (!signin) {
            loadData();
        }
    }

    render() {
        const { 
            accounts, handleDataPageChange, handleDataPageSizeChange, signin,
            accountsPagination, isLoadedAccounts, exportData, handleSort, companySignin,
            personalSignin, isLoadedSignin, accountColumnTypes
        } = this.props;
        const baseProps = {
            data: accounts,
            isLoaded: isLoadedAccounts,
            handleExportData: exportData,
            pagination: accountsPagination,
            handlePageChange: handleDataPageChange,
            handlePageSizeChange: handleDataPageSizeChange,
            sortData: handleSort,
            showImport: false,
            showTemplate: false,
            showAdd: true,
            addTitle: (
                <div className='d-flex justify-content-between align-items-center'>
                    <UserAddOutlined />
                    <Link to='/signin'>
                        <I18n t='addUserTitle' />
                    </Link>
                </div>
            ),
            columnTypes: accountColumnTypes
        };

        return (
            <Fragment>
                { signin && (<Signin handlePersonalSignIn={personalSignin} handleCompanySignIn={companySignin} isLoadedSignin={isLoadedSignin} />) }
                <BaseAdmin {...baseProps} />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { admin, authentication: { isLoadedSignin } } = state;
    const { accounts, accountsPagination, isLoadedAccounts, accountColumnTypes } = admin;

    return {
        accounts, isLoadedAccounts, accountsPagination, isLoadedSignin, accountColumnTypes
    };
}

const { action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey } = {
    action: ACTION_LOAD_TYPES.account,
    isLoadedKey: PROP_KEYS.isLoadedAccounts,
    paginationKey: PROP_KEYS.accountsPagination,
    dataKey: PROP_KEYS.accounts,
    fileKey: TEMPLATE_TYPES.Account,
    columnTypesKey: PROP_KEYS.accountColumnTypes
}

const mapDispatchToProps = {
    loadData: () => adminActions.load({ 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    exportData: () => adminActions.exportData({ 
        action, isLoadedKey, fileKey
    }),
    handleDataPageChange: (page, pageSize) => adminActions.handlePaginationChange(page, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleDataPageSizeChange: (_page, pageSize) => adminActions.handlePaginationChange(1, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleSort: (_pagination, _filters, sorter) => adminActions.handleSort(1, sorter, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    companySignin: (companyInfo) => userActions.companySignin(companyInfo),
    personalSignin: (userInfo) => userActions.personalSignin(userInfo),
}

const connectedAdminAccounts = connect(mapStateToProps, mapDispatchToProps)(withAdminLayout(AdminAccounts, ADMIN_PAGES.AdminAccounts));
export { connectedAdminAccounts as AdminAccounts };