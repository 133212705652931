import React from "react";
import { Button } from 'antd';
import facebook from '../assets/icons/facebook.svg';
import youtube from '../assets/icons/youtube.svg';
import twitter from '../assets/icons/twitter.svg';
import linkedin from '../assets/icons/linkedin.svg';
import instagram from '../assets/icons/instagram.svg';

export const SocialLinks = (props) => {
    const { facebookLink, twitterLink, instagramLink, linkedinLink, youtubeLink } = props;

    return (
        <div className="d-flex w-100 align-items-center justify-content-between social-links pt-2 pr-5 pl-5">
            <Button size='large' href={facebookLink} type="link" shape="circle" icon={<img src={facebook} alt="facebook" />} />
            <Button size='large' href={youtubeLink} type="link" shape="circle" icon={<img src={youtube} alt="youtube" />} />
            <Button size='large' href={twitterLink} type="link" shape="circle" icon={<img src={twitter} alt="twitter" />} />
            <Button size='large' href={linkedinLink} type="link" shape="circle" icon={<img src={linkedin} alt="linkedin" />} />
            <Button size='large' href={instagramLink} type="link" shape="circle" icon={<img src={instagram} alt="instagram" />} />
        </div>
    )
}