import React from 'react';
import { connect } from 'react-redux';
import { withAdminLayout } from '../../common/admin-layout';
import { ADMIN_PAGES } from '../../assets/constants/global';
import { adminActions } from './admin-reducer';
import { BaseAdmin } from './base';
import { PROP_KEYS, ACTION_LOAD_TYPES, TEMPLATE_TYPES, ACTION_IMPORT_TYPES } from './admin-constants';

class AdminVip extends React.Component {
    componentDidMount() {
        const { loadData } = this.props;

        loadData();
    }

    render() {
        const { 
            additionalOptionsPerVip, additionalOptionsPerVipErrorMsg, handleDataPageChange, handleDataPageSizeChange,
            additionalOptionsPerVipPagination, isLoadedAdditionalOptionsPerVip, importData, exportData,
            setImportedData, downloadTemplate, cleanDataErrorMsg, handleSort, additionalOptionsPerVipColumnTypes
        } = this.props;
        const baseProps = {
            data: additionalOptionsPerVip,
            isLoaded: isLoadedAdditionalOptionsPerVip,
            errorMsg: additionalOptionsPerVipErrorMsg,
            handleImportData: importData,
            handleExportData: exportData,
            setImportedData: setImportedData,
            downloadTemplate: downloadTemplate,
            pagination: additionalOptionsPerVipPagination,
            handlePageChange: handleDataPageChange,
            handlePageSizeChange: handleDataPageSizeChange,
            cleanErrorMsg: cleanDataErrorMsg,
            sortData: handleSort,
            columnTypes: additionalOptionsPerVipColumnTypes
        };

        return (
            <BaseAdmin {...baseProps} />
        );
    }
}

const mapStateToProps = (state) => {
    const { admin } = state;
    const { additionalOptionsPerVip, additionalOptionsPerVipPagination, isLoadedAdditionalOptionsPerVip, additionalOptionsPerVipErrorMsg, additionalOptionsPerVipColumnTypes } = admin;

    return {
        additionalOptionsPerVip, isLoadedAdditionalOptionsPerVip, additionalOptionsPerVipPagination, additionalOptionsPerVipErrorMsg, additionalOptionsPerVipColumnTypes
    };
}

const { action, isLoadedKey, paginationKey, dataKey, importAction, importedKey, errorMsgKey, fileKey, columnTypesKey } = {
    action: ACTION_LOAD_TYPES.additionalOptionPerVip,
    isLoadedKey: PROP_KEYS.isLoadedAdditionalOptionsPerVip,
    paginationKey: PROP_KEYS.additionalOptionsPerVipPagination,
    dataKey: PROP_KEYS.additionalOptionsPerVip,
    importAction: ACTION_IMPORT_TYPES.additionalOptionPerVip,
    importedKey: PROP_KEYS.importedAdditionalOptionsPerVip,
    errorMsgKey: PROP_KEYS.additionalOptionsPerVipErrorMsg,
    fileKey: TEMPLATE_TYPES.AdditionalOptionPerVip,
    columnTypesKey: PROP_KEYS.additionalOptionsPerVipColumnTypes
}

const mapDispatchToProps = {
    loadData: () => adminActions.load({ 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    importData: () => adminActions.importData({
        importAction, action, isLoadedKey, importedKey, errorMsgKey, paginationKey, dataKey
    }),
    exportData: () => adminActions.exportData({ 
        action, isLoadedKey, fileKey
    }),
    setImportedData: (file) => adminActions.setValue(importedKey, file),
    cleanDataErrorMsg: () => adminActions.setValue(errorMsgKey, []),
    downloadTemplate: () => adminActions.downloadTemplate({
        isLoadedKey, fileKey
    }),
    handleDataPageChange: (page, pageSize) => adminActions.handlePaginationChange(page, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleDataPageSizeChange: (_page, pageSize) => adminActions.handlePaginationChange(1, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleSort: (_pagination, _filters, sorter) => adminActions.handleSort(1, sorter, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    })
}

const connectedAdminVip = connect(mapStateToProps, mapDispatchToProps)(withAdminLayout(AdminVip, ADMIN_PAGES.AdminVip));
export { connectedAdminVip as AdminVip };