import React from "react";
import { I18n as Translater } from 'react-redux-i18n';
import { USER_INPUT_TYPES, STRING_EMPTY } from '../assets/constants/global';
import { UserInput } from './user-input';
import { Modal } from 'antd';
import I18n from '../i18n';
import { SubmitButton } from './submit-button';
import { Link } from 'react-router-i18n';
import close from '../assets/icons/close.svg';

export class Driver extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            driverFullName: STRING_EMPTY,
            driverPhone: STRING_EMPTY
        }
    }

    setValue = (key, value) => {
        this.setState({ [key]: value })
    }

    render() {
        const { handleSubmit, handleSubmitWithoutDetails, isLoaded, token, isAgent = false } = this.props;
        const { driverFullName, driverPhone } = this.state;
        const disabledSubmit = (driverFullName === STRING_EMPTY && driverPhone !== STRING_EMPTY) || (driverFullName !== STRING_EMPTY && driverPhone === STRING_EMPTY);

        return (
            <div id='driver'>
                <Modal title="Driver Modal"
                       visible={true}
                       centered={true}
                       footer={null}
                       maskClosable={false}
                       closeIcon={<Link to='/'><img src={close} alt='close' /></Link>}
                       getContainer={() => document.getElementById('driver')}>
                    <div className="d-flex flex-column align-items-center">
                        <span className='payment-title'>{ isAgent ? <I18n t='agentInfoTitle' /> : <I18n t='driverInfTitle' /> }</span>
                        <div className="d-flex flex-column w-100">
                            <UserInput optional={false}
                                       placeholder={isAgent ? Translater.t('agentFullNameTitle') : Translater.t('driverFullNameTitle')}
                                       containerClassName='mr-3'
                                       type={USER_INPUT_TYPES.string}
                                       onChange={(value) => this.setValue('driverFullName', value)} />
                            <UserInput optional={false} 
                                       placeholder={isAgent ? Translater.t('agentPhoneTitle') : Translater.t('driverPhoneTitle')}
                                       containerClassName='mr-3'
                                       type={USER_INPUT_TYPES.phone}
                                       onChange={(value) => this.setValue('driverPhone', value)} />
                            <SubmitButton label={Translater.t('submitTitle')}
                                          loading={!isLoaded}
                                          disabled={disabledSubmit}
                                          onClick={() => driverFullName === STRING_EMPTY && driverPhone === STRING_EMPTY 
                                                       ? handleSubmitWithoutDetails(token)
                                                       : handleSubmit({
                                                             token,
                                                             driver: {
                                                               fullName: driverFullName,
                                                               phone: driverPhone
                                                             }
                                                         }
                                           )} />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
    