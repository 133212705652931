import React from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import { UserInput } from '../../global/user-input';
import { I18n as Translater } from 'react-redux-i18n';
import { userActions } from './user-reducer';
import { PROP_KEYS } from './user-constants';
import { USER_INPUT_TYPES } from '../../assets/constants/global';
import LoadingOverlay from 'react-loading-overlay';

class UserProfileDetails extends React.Component {
    constructor(props) {
        super(props);

        this.childInputOldPassword = React.createRef();
        this.childInputNewPassword = React.createRef();
        this.childInputRepeatNewPassword = React.createRef();
    }

    submitChangePasswordWithClearInput(passwordObj) {
        this.childInputOldPassword.current.clearInput();
        this.childInputNewPassword.current.clearInput();
        this.childInputRepeatNewPassword.current.clearInput();

        this.props.submitChangePassword(passwordObj);
    }

    render() {
        const { user, updateProfileDetails, setOldPassword, setNewPassword, isLoadedChangePassword, 
            setRepeatNewPassword, oldPassword, newPassword, repeatNewPassword, setFullName, setPhone } = this.props;

        return (
            <LoadingOverlay className="w-100" active={!isLoadedChangePassword} spinner>
                <div className="row profile-details pt-5">
                    <div className="col-lg-6 col-12 mb-3">
                        <Card className="h-100 profile-card">
                            <div className="row profile-header pb-3">
                                <div className="col-lg-5 col-12">
                                    Basic Info
                                </div>
                            </div>
                            <div className="row profile-info">
                                <div className="col">
                                    <UserInput disabled={true} placeholder={Translater.t('emailTitle')} containerClassName='mr-2' onChange={() => { }} value={user.email} />
                                    <UserInput optional={false} required={true} placeholder={Translater.t('fullNameTitle')} containerClassName='mr-2' type={USER_INPUT_TYPES.string} onChange={setFullName} value={user.fullName} />
                                    <UserInput optional={false} required={true} placeholder={Translater.t('phoneTitle')} containerClassName='mr-2' type={USER_INPUT_TYPES.phone} onChange={setPhone} value={user.phone} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-5 col-12">
                                    <button className="change-button" disabled={(!(user.fullName && user.phone))} onClick={() => updateProfileDetails()}>{Translater.t('saveTitle')}</button>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-lg-6 col-12 mb-3">
                        <Card className="h-100 profile-card">
                            <div className="row profile-header pb-3">
                                <div className="col-lg-5 col-12">
                                    Change password
                                </div>
                            </div>
                            <div className="row profile-info">
                                <div className="col">
                                    <UserInput ref={this.childInputOldPassword} optional={false} required={true} placeholder={Translater.t('oldPassword')} containerClassName='mr-2' type={USER_INPUT_TYPES.password} onChange={setOldPassword} value={oldPassword} />
                                    <UserInput ref={this.childInputNewPassword} optional={false} required={true} placeholder={Translater.t('newPassword')} containerClassName='mr-2' type={USER_INPUT_TYPES.password} onChange={setNewPassword} value={newPassword} />
                                    <UserInput ref={this.childInputRepeatNewPassword} optional={false} required={true} placeholder={Translater.t('repeatNewPassword')} passwordToMatch={newPassword} containerClassName='mr-2' type={USER_INPUT_TYPES.password} onChange={setRepeatNewPassword} value={repeatNewPassword} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-5 col-12">
                                    <button className="change-button" disabled={(!(oldPassword && newPassword && repeatNewPassword))} onClick={() => this.submitChangePasswordWithClearInput({oldPassword, newPassword, repeatNewPassword})}>{Translater.t('changeTitle')}</button>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </LoadingOverlay>
        )
    }
}

const mapDispatchToProps = {
    setOldPassword: (value) => userActions.setUserValue(PROP_KEYS.oldPassword, value),
    setNewPassword: (value) => userActions.setUserValue(PROP_KEYS.newPassword, value),
    setRepeatNewPassword: (value) => userActions.setUserValue(PROP_KEYS.repeatNewPassword, value),
    setFullName: (value) => userActions.setSubValue(PROP_KEYS.user, 'fullName', value),
    setPhone: (value) => userActions.setSubValue(PROP_KEYS.user, 'phone', value),
    submitChangePassword: (passwordObj) => userActions.changePassword(passwordObj),
    updateProfileDetails: () => userActions.updateProfileDetails()
}

const mapStateToProps = (state) => {
    const { authentication} = state;

    return {
        ...authentication
    };
}

const connectedUserProfileDetails = connect(mapStateToProps, mapDispatchToProps)(UserProfileDetails);
export { connectedUserProfileDetails as UserProfileDetails };