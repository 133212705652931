import React from 'react';
import { connect } from 'react-redux';
import { withAdminLayout } from '../../common/admin-layout';
import { ADMIN_PAGES, STRING_EMPTY, DEFAULT_ZERO_VALUE, UNFINALIZED_TRIP_SEARCH_DEFAULT_VALUE } from '../../assets/constants/global';
import { adminActions } from './admin-reducer';
import { BaseAdmin } from './base';
import { ClearOutlined } from '@ant-design/icons';
import { PROP_KEYS, ACTION_LOAD_TYPES, ACTION_REMOVE_TYPES } from './admin-constants';
import { Tooltip, Select, Input, Button, Modal, DatePicker } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

class AdminUnfinalizedTrips extends React.Component {
    componentDidMount() {
        const { loadData } = this.props;

        loadData();
    }

    stringsToIds = (data) => {
        return data && data.map(x => {
            const val = x.split('_');
            if (val && val[0] && val[1]) {
    
                return val[0].concat(', ');
            };
        });
    }

    onSelectChange = selectedRowKeys => {
        const { markAsChecked } = this.props;
        markAsChecked(selectedRowKeys);
    };

    confirmDeleteDialog = () => {
        const { setChecked } = this.props;
        const ids = this.stringsToIds(this.props.unfinalizedTripsChecked);
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: <div>Following enitites with these ids will be deleted (and all related vip records with same id): {ids} </div>,
            okText: 'Remove',
            cancelText: 'Cancel',
            onOk: setChecked,
            centered: true
          });
    }

    render() {
        const {
            unfinalizedTrips, unfinalizedTripsChecked, handleDataPageChange, handleDataPageSizeChange, unfinalizedTripsPagination, isLoadedUnfinalizedTrips, setChecked, handleSort, unfinalizedTripsColumnTypes,
            setSearchBy, setSearchText, setSearchToday, setSearchYesterday, setSearchRange, clearSearch
        } = this.props;
        
        const rowSelection = {
            selectedRowKeys: unfinalizedTripsChecked,
            onChange: this.onSelectChange,
        };

        const title = (text) => <Tooltip title={text}><div className='text-truncate text-uppercase title-header'>{text}</div></Tooltip>;
        const columns = [{
            key: 'id',
            dataIndex: 'id',
            width: `5%`,
            title: title('Id'),
            render: (text, { isVip }) => (
                <div className="d-flex flex-column">
                    <div className='text-truncate'>{text}</div>
                    { isVip && <span className='vip-label mt-2'>VIP</span> }
                </div>
            )
        }, {
            key: 'date',
            dataIndex: 'date',
            width: '10%',
            title: title('Date, Time'),
            render: (text) => {
                const date = moment(text);
                const dayName = moment().isSame(date, "day") ? 'today'
                    : moment().add(-1, 'day').isSame(date, "day") ? 'yesterday'
                        : date.format('DD MMM');

                return (
                    <div className="d-flex flex-column">
                        <div>{dayName}</div>
                        <div>{date.format('HH:mm')}</div>
                    </div>
                )
            }
        }, {
            key: 'contact',
            dataIndex: 'contact',
            width: '15%',
            title: title('contact'),
            render: ({ fullName, email }) => {
                return (
                    <div className="d-flex flex-column">
                        <div className='text-truncate'><Tooltip title={email}>{email}</Tooltip></div>
                        <div className='text-truncate'><Tooltip title={fullName}>{fullName}</Tooltip></div>
                    </div>
                )
            }
        }, {
            key: 'departure',
            dataIndex: 'departure',
            width: '18%',
            title: title('Departure, Appointment'),
            render: (_text, { pickupLocationAddress, dropoffLocationAddress }) => (
                <div className="d-flex flex-column">
                    <div className='text-truncate'><Tooltip title={pickupLocationAddress}>{pickupLocationAddress}</Tooltip></div>
                    <div className='text-truncate'><Tooltip title={dropoffLocationAddress}>{dropoffLocationAddress}</Tooltip></div>
                </div>
            )
        }, {
            key: 'companyName',
            dataIndex: 'companyName',
            width: '10%',
            title: title('Company'),
            render: (text) => (
                <div className="d-flex flex-column">
                    {text || 'Private'}
                </div>
            )
        }, {
            key: 'comments',
            dataIndex: 'comments',
            width: '10%',
            title: title('Comment'),
            render: (text) => (
                <div className="d-flex flex-column">
                    {text || STRING_EMPTY}
                </div>
            )
        }, {
            key: 'tripAdditionalOptions',
            dataIndex: 'tripAdditionalOptions',
            width: '15%',
            title: title('Additional Options'),
            render: (tripAdditionalOptions, { affiliate }) => (
                <div className="d-flex flex-column">
                    {
                        tripAdditionalOptions && tripAdditionalOptions.map(({ additionOption, clientPrice }, idx) => (
                            <div key={`tripAdditionalOptions_${idx}`} className="d-flex justify-content-between w-100">
                                <Tooltip title={additionOption.description}>{additionOption.name}</Tooltip>
                                <div className="lighter">{ clientPrice === DEFAULT_ZERO_VALUE ? 'Free' : `${clientPrice}${affiliate && affiliate.currency && getSymbolFromCurrency(affiliate.currency)}`}</div>
                            </div>
                        ))
                    }
                </div>
            )
        }, {
            key: 'affiliate',
            dataIndex: 'affiliate',
            width: '12%',
            title: title('Affiliate Company'),
            render: ({ name, phone }) => (
                <div className="d-flex flex-column">
                    <div style={{ maxWidth: '140px' }} className='text-truncate'><Tooltip title={name}>{name}</Tooltip></div>
                    <div style={{ maxWidth: '140px' }} className='text-truncate'><Tooltip title={phone}>{phone}</Tooltip></div>
                </div>
            )
        }];

        const header = (
            <div className='d-flex dispatch-header'>
                <div className='d-flex justify-content-between w-100 main-filters'>
                    <div className="d-flex">
                        <Select defaultValue={UNFINALIZED_TRIP_SEARCH_DEFAULT_VALUE} value={unfinalizedTripsPagination.searchBy} style={{ width: 120 }} onChange={setSearchBy}>
                            <Option value="Id">Id</Option>
                            <Option value="Contact">Contact</Option>
                            <Option value="PickupLocationAddress">Pickup Location Address</Option>
                            <Option value="DropoffLocationAddress">Dropoff Location Address</Option>
                            <Option value="CompanyName">Company Name</Option>
                            <Option value="Comments">Comments</Option>
                            <Option value="Affiliate">Affiliate</Option>
                        </Select>
                        <Search
                            placeholder="Input Search Text"
                            onSearch={setSearchText}
                            style={{ width: 200 }}
                        />
                    </div>
                    <div className="d-flex">
                        <Button className="date-button" onClick={setSearchToday}>Today</Button>
                        <Button className="date-button" onClick={setSearchYesterday}>Yesterday</Button>
                        <RangePicker className="date-button" onChange={setSearchRange} />
                        <Button style={{ minWidth: '40px', padding: '0'}} className="date-button" onClick={() => clearSearch(UNFINALIZED_TRIP_SEARCH_DEFAULT_VALUE)}><ClearOutlined /></Button>
                        <Button className="date-button" onClick={this.confirmDeleteDialog}>Remove</Button>
                    </div>
                </div>
            </div>
        );
        const baseProps = {
            data: unfinalizedTrips,
            isLoaded: isLoadedUnfinalizedTrips,
            pagination: unfinalizedTripsPagination,
            handlePageChange: handleDataPageChange,
            handlePageSizeChange: handleDataPageSizeChange,
            sortData: handleSort,
            columnTypes: unfinalizedTripsColumnTypes,
            showImport: false,
            showTemplate: false,
            showExport: false,
            specialColumns: columns,
            specialHeader: header,
            specialRowKey: x => `${x.id}_${x.isVip}`,
            rowSelection: rowSelection,
        };

        return (
            <BaseAdmin {...baseProps} />
        );
    }
}

const mapStateToProps = (state) => {
    const { admin } = state;
    const { unfinalizedTrips, unfinalizedTripsPagination, isLoadedUnfinalizedTrips, unfinalizedTripsColumnTypes, unfinalizedTripsChecked } = admin;

    return {
        unfinalizedTrips, isLoadedUnfinalizedTrips, unfinalizedTripsPagination, unfinalizedTripsColumnTypes, unfinalizedTripsChecked
    };
}

const { action, isLoadedKey, paginationKey, dataKey, columnTypesKey, checkedDataKey, updateCheckedDataAction, hasVip, passOnlyId } = {
    action: ACTION_LOAD_TYPES.unfinalizedTrips,
    updateCheckedDataAction: ACTION_REMOVE_TYPES.unfinalizedTripsChecked,
    isLoadedKey: PROP_KEYS.isLoadedUnfinalizedTrips,
    paginationKey: PROP_KEYS.unfinalizedTripsPagination,
    dataKey: PROP_KEYS.unfinalizedTrips,
    columnTypesKey: PROP_KEYS.unfinalizedTripsColumnTypes,
    checkedDataKey: PROP_KEYS.unfinalizedTripsChecked,
    hasVip: true,
    passOnlyId: true
}

const mapDispatchToProps = {
    loadData: () => adminActions.load({
        action, isLoadedKey, paginationKey, dataKey, columnTypesKey
    }),
    handleDataPageChange: (page, pageSize) => adminActions.handlePaginationChange(page, pageSize, {
        action, isLoadedKey, paginationKey, dataKey, columnTypesKey
    }),
    handleDataPageSizeChange: (_page, pageSize) => adminActions.handlePaginationChange(1, pageSize, {
        action, isLoadedKey, paginationKey, dataKey, columnTypesKey
    }),
    handleSort: (_pagination, _filters, sorter) => adminActions.handleSort(1, sorter, {
        action, isLoadedKey, paginationKey, dataKey, columnTypesKey
    }),
    setSearchBy: (text) => adminActions.setSubValue(paginationKey, 'searchBy', text),
    setSearchText: (text) => adminActions.setSearchText(text, { action, isLoadedKey, paginationKey, dataKey, columnTypesKey }),
    setSearchToday: () => adminActions.setSearchDate(moment.utc().startOf('day'), { action, isLoadedKey, paginationKey, dataKey, columnTypesKey }),
    setSearchYesterday: () => adminActions.setSearchDate(moment.utc().add(-1, 'day').startOf('day'), { action, isLoadedKey, paginationKey, dataKey, columnTypesKey }),
    setSearchRange: (dates) => adminActions.setSearchRange(dates, { action, isLoadedKey, paginationKey, dataKey, columnTypesKey }),
    clearSearch: (defaultValue) => adminActions.clearSearch(defaultValue, { action, isLoadedKey, paginationKey, dataKey, columnTypesKey }),
    markAsChecked: (selectedKeys) => adminActions.markAsChecked(selectedKeys, {
        action, isLoadedKey, paginationKey, dataKey, columnTypesKey, checkedDataKey
    }),
    setChecked: () => adminActions.setChecked({
        action, updateCheckedDataAction, isLoadedKey, paginationKey, dataKey, columnTypesKey, checkedDataKey, hasVip, passOnlyId
    }),
}

const connectedAdminUnfinalizedTrips = connect(mapStateToProps, mapDispatchToProps)(withAdminLayout(AdminUnfinalizedTrips, ADMIN_PAGES.AdminUnfinalizedTrips));
export { connectedAdminUnfinalizedTrips as AdminUnfinalizedTrips };