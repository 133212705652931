import React, { Fragment } from "react";
import { Layout, Menu } from 'antd';
import I18n from '../i18n';
import windowSize from 'react-window-size';
import { Link } from 'react-router-i18n';
import default_user from '../assets/icons/default_user.png';
import default_company from '../assets/icons/default_company.png';
import logo from '../assets/icons/logo.svg';
import { STRING_EMPTY, USER_ROLES } from "../assets/constants/global";
import Desktop from "./desktop-mode";
import Tablet from "./tablet-mode";
import Mobile from "./mobile-mode";
import HamburgerMenu from 'react-hamburger-menu';
import { Link as ScrollLink } from 'react-scroll';
import { userService } from '../pages/user/user-service';
import Cookies from 'js-cookie';
import { Element } from 'react-scroll';

const { Header, Content } = Layout;
const { Item } = Menu;

export const withLayout = (Component, linkTo = STRING_EMPTY) => {
    class BaseLayout extends React.Component {
        constructor() {
            super();

            this.state = {
                menuOpened: false
            }
        }

        logout = () => {
            userService.logout();
        }

        render() {
            const user = Cookies.get('user') && JSON.parse(Cookies.get('user'));
            const name = user && (user.companyName || `${user.fullName }`);

            return (
                <Layout className={`main-layout ${this.props.withoutBackgroundImage ? 'without-background-image' : linkTo !== STRING_EMPTY ? 'd-flex flex-column' : STRING_EMPTY}`}
                        style={
                            this.props.withoutBackgroundImage || linkTo !== STRING_EMPTY ? {} : {
                                width: this.props.windowWidth,
                                height: this.props.windowHeight
                            }
                        }>
                    <Header>
                        <Desktop>
                            <div className="d-flex justify-content-between">
                                <Link to="/home"><img src={logo} alt="Logo" /></Link>
                                <div className="d-flex justify-content-end">
                                    <Menu theme="dark" mode="horizontal">
                                        { user && user.role && user.role.includes(USER_ROLES.Admin) && <Item key="0"><Link to="/admin"><I18n t="adminPanelTitle" /></Link></Item> }
                                        <Item key="1"><Link to="/about"><I18n t="pageTitles.about" /></Link></Item>
                                        <Item key="2"><Link to="/faq"><I18n t="pageTitles.faq" /></Link></Item>
                                        <Item key="3"><Link to="/partners"><I18n t="pageTitles.partners" /></Link></Item>
                                        <Item key="4"><Link to="/privacy-and-terms"><I18n t="pageTitles.privacyAndTerms" /></Link></Item>
                                    </Menu>
                                    {
                                        Cookies.get('token') && user ? (
                                            <Fragment>
                                                <Link to="/user">
                                                     <div className="user-logo d-flex align-items-center">
                                                         <div className="user-logo-image ml-5">
                                                             <img src={user.companyName ? default_company : default_user} alt="User logo" />
                                                             <span>{user.companyName || user.fullName}</span>
                                                         </div>
                                                    </div>
                                                </Link>
                                                <div className="user-login d-flex align-items-center">
                                                    <button onClick={this.logout}><span><I18n t="logoutTitle" /></span></button>
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <div className="user-login d-flex align-items-center">
                                                <Link to="/login">
                                                    <button><span><I18n t="loginTitle" /></span></button>
                                                </Link>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </Desktop>
                        <Tablet>
                            <div className="d-flex justify-content-between">
                                <Link to="/home"><img src={logo} alt="Logo" /></Link>
                                <div className="d-flex justify-content-end">
                                    <Menu theme="dark" mode="horizontal">
                                        { user && user.role && user.role.includes(USER_ROLES.Admin) && <Item key="0"><Link to="/admin"><I18n t="adminPanelTitle" /></Link></Item> }
                                        <Item key="1"><Link to="/about"><I18n t="pageTitles.about" /></Link></Item>
                                        <Item key="2"><Link to="/faq"><I18n t="pageTitles.faq" /></Link></Item>
                                        <Item key="3"><Link to="/partners"><I18n t="pageTitles.partners" /></Link></Item>
                                        <Item key="4"><Link to="/privacy-and-terms"><I18n t="pageTitles.privacyAndTerms" /></Link></Item>
                                    </Menu>
                                    {
                                        Cookies.get('token') && user ? (
                                            <Fragment>
                                                <Link to="/user">
                                                     <div className="user-logo d-flex align-items-center">
                                                         <div className="user-logo-image ml-5">
                                                             <img src={user.companyName ? default_company : default_user} alt="User logo" />
                                                             <span>{user.fullName}</span>
                                                         </div>
                                                    </div>
                                                </Link>
                                                <div className="user-login d-flex align-items-center">
                                                    <button onClick={this.logout}><span><I18n t="logoutTitle" /></span></button>
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <div className="user-login d-flex align-items-center">
                                                <Link to="/login">
                                                    <button><span><I18n t="loginTitle" /></span></button>
                                                </Link>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </Tablet>
                        <Mobile>
                            <Element name='header_mobile'>
                            <div className="d-flex justify-content-between align-items-center hamburger-menu">
                                <div className="d-flex align-items-center">
                                    {
                                        linkTo !== STRING_EMPTY && (
                                            <div className="back-to">
                                                <ScrollLink to={linkTo} spy={true} smooth={true}>
                                                    <div className="d-flex align-items-center">
                                                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6 1L2 5L6 9" stroke="#959CAC" strokeWidth="2"/>
                                                        </svg>
                                                    </div>
                                                </ScrollLink>
                                            </div>
                                        )
                                    }
                                    <Link to="/home"><img src={logo} alt="Logo" /></Link>
                                </div>
                                {
                                    Cookies.get('token') && user ? (
                                        <Fragment>
                                            <Link to="/user" className="w-50">
                                                 <div className="user-logo d-flex align-items-center">
                                                     <div className="user-logo-image mobile-user-name ml-5">
                                                         <img src={user.companyName ? default_company : default_user} alt="User logo" />
                                                         <span>{name}</span>
                                                     </div>
                                                </div>
                                            </Link>
                                        </Fragment>
                                    ) : (
                                        <div className="user-login d-flex align-items-center">
                                            <Link to="/login">
                                                <button><span><I18n t="loginTitle" /></span></button>
                                            </Link>
                                        </div>
                                    )
                                }
                                {
                                    linkTo === STRING_EMPTY && (
                                        <HamburgerMenu
                            	            isOpen={this.state.menuOpened}
                            	            menuClicked={() => this.setState(prevState => ({ menuOpened: !prevState.menuOpened }))}
                            	            width={18}
                            	            height={15}
                            	            strokeWidth={2}
                            	            rotate={0}
                            	            color='#393644'
                            	            borderRadius={0}
                                        />
                                    )
                                }
                            </div>
                            {
                                this.state.menuOpened && (
                                    <div style={{
                                        width: this.props.windowWidth,
                                        height: this.props.windowHeight
                                    }} className="d-flex flex-column justify-content-center align-items-start opened-menu">
                                        { user && user.role && user.role.includes(USER_ROLES.Admin) && <Link to="/admin"><I18n t="adminPanelTitle" /></Link> }
                                        <Link to="/home"><I18n t="pageTitles.home" /></Link>
                                        <Link to="/about"><I18n t="pageTitles.about" /></Link>
                                        <Link to="/faq"><I18n t="pageTitles.faq" /></Link>
                                        <Link to="/partners"><I18n t="pageTitles.partners" /></Link>
                                        <Link to="/privacy-and-terms"><I18n t="pageTitles.privacyAndTerms" /></Link>
                                        <div className='mobile-logout d-flex w-100 justify-content-center'>
                                            {
                                                Cookies.get('token') && user && (
                                                    <div className="user-login d-flex align-items-center">
                                                        <button onClick={this.logout}><span><I18n t="logoutTitle" /></span></button>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            </Element>
                        </Mobile>
                    </Header>
                    <Content>
                        <div className="background-full-height">
                            <div className="content-padding">
                                <Desktop><Component {...this.props} /></Desktop>
                                <Tablet><Component {...this.props} /></Tablet>
                                <Mobile>{ !this.state.menuOpened && <Component {...this.props} /> }</Mobile>
                            </div>                            
                        </div>                        
                    </Content>
                </Layout>
            );
        }
    };

    return windowSize(BaseLayout);
}