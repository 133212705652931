import { config } from '../../config';
import moment from 'moment';
import { TIME_TYPE } from './user-constants';
import { history } from '../../helpers/history';
import Cookies from 'js-cookie';

const companyLogin = (userName, password) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userName, password })
    };

    return fetch(`${config.apiUrl}/api/auth/login`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            const { token, user } = data;
            Cookies.set('token', token.access_token);
            Cookies.set('refreshToken', token.refresh_token);
            Cookies.set('user', JSON.stringify(user));

            return { token: token.access_token, refreshToken: token.refresh_token, user };
        });
}

const personalLogin = (userName, bookingNumber) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userName, bookingNumber })
    };

    return fetch(`${config.apiUrl}/api/auth/client-login`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            const { token, user } = data;
            Cookies.set('token', token.access_token);
            Cookies.set('refreshToken', token.refresh_token);
            Cookies.set('user', JSON.stringify(user));

            return { token: token.access_token, refreshToken: token.refresh_token, user };
        });
}

const companySignin = (companyInfo) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(companyInfo)
    };

    return fetch(`${config.apiUrl}/api/auth/register-company`, requestOptions)
    .then(handleSignIn);
}

const personalSignin = (userInfo) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userInfo)
    };

    return fetch(`${config.apiUrl}/api/auth/client-register`, requestOptions)
    .then(handleSignIn);
}

const logout = () => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return fetch(`${config.apiUrl}/api/auth/logout`, requestOptions)
    .finally(() => {
        Cookies.remove('token');
        Cookies.remove('refreshToken');
        Cookies.remove('user');
        history.push(`/`);
        window.location.reload();
    })
}

const resetPassword = (info) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info)
    };

    return fetch(`${config.apiUrl}/api/auth/reset-password`, requestOptions)
    .then(handleResponse);
}

const forgotPassword = (email) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };

    return fetch(`${config.apiUrl}/api/auth/forgot-password`, requestOptions)
    .then(handleResponse)
}

const changePassword = (info) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` },
        body: JSON.stringify(info)
    };

    return fetch(`${config.apiUrl}/api/auth/change-password`, requestOptions)
    .then(handleResponse);
}

const updateProfileDetails = (info) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` },
        body: JSON.stringify(info)
    };

    return fetch(`${config.apiUrl}/api/profileDetails/update-profile`, requestOptions)
    .then(handleResponse);
}

const loadMyOrders = (currentPage, itemsPerPage) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${Cookies.get('token')}`
        }
    };

    return fetch(`${config.apiUrl}/api/trip/get?offset=${currentPage}&limit=${itemsPerPage}`, requestOptions)
        .then(handleResponse)
        .then(({ data = [], pagination}) => {
            return { myOrders: mapWithTimePeriod(data), pagination }
        });
}

const deleteTrip = (tripId) => {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${Cookies.get('token')}`
        }
    };

    return fetch(`${config.apiUrl}/api/trip/delete?tripId=${tripId}`, requestOptions)
        .then(handleResponse);
}

const editTrip = (tripId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${Cookies.get('token')}`
        }
    };

    return fetch(`${config.apiUrl}/api/trip/get-edited-trip?tripId=${tripId}`, requestOptions)
        .then(handleResponse);
}

const refreshToken = () => {
    const refreshToken = Cookies.get('refreshToken');
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : {};;
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrer: 'no-referrer',
        headers: {
            'Device': 'device',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('token')}`
        },
        body: JSON.stringify({ refreshToken, userName: user.email })
    };

    return fetch(`${config.apiUrl}/api/auth/refresh-token`, requestOptions)
        .then(res => {
            if (!res.ok) {
                logout();
            }

            return res.text();
        })
        .then(text => {
            const data = text && JSON.parse(text);
            const { access_token, refresh_token } = data;
            Cookies.set('token', access_token);
            Cookies.set('refreshToken', refresh_token);

            return { token: access_token, refreshToken: refresh_token };
        })
        .finally(() => window.location.reload(true));
}

const handleResponse = (response) => {
    return response ? response.text().then(text => {
        if (response.status === 400 || response.status === 404) {
            return Promise.reject(text);
        }

        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                return refreshToken();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    }) : Promise.reject('error');
}

const handleSignIn = (response) => {
    return response ? response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                return refreshToken();
            }

            const error = (data && data.errors && Object.values(data.errors).map(e => e.errors && e.errors[0].errorMessage)) || response.statusText;
            return Promise.reject(error);
        }

        return { ok: data };
    }) : Promise.reject('error');
}

const mapWithTimePeriod = (myOrders) => {
    return myOrders.map(order => ({
        ...order,
        timeType: moment(order.startTripDatetime).isBefore(moment()) ? TIME_TYPE.Past : TIME_TYPE.Upcoming
    })).sort((a, b) => moment(b.startTripDatetime).diff(a.startTripDatetime))
}

export const userService = {
    companyLogin,
    personalLogin,
    companySignin,
    personalSignin,
    logout,
    resetPassword,
    changePassword,
    updateProfileDetails,
    forgotPassword,
    loadMyOrders,
    deleteTrip,
    editTrip,
    refreshToken
};