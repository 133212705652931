import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { userActions } from './user-reducer';
import LoadingOverlay from 'react-loading-overlay';
import { Table, Popconfirm, Tooltip, Modal } from 'antd';
import moment from 'moment';
import { I18n as Translater } from 'react-redux-i18n';
import { TIME_TYPE, CARD_TYPES } from './user-constants';
import { STRING_EMPTY, RESOLUTION_TYPES } from '../../assets/constants/global';
import getSymbolFromCurrency from 'currency-symbol-map';
import printJS from 'print-js';
import { Link } from 'react-router-i18n';
import { recordToRawHtml } from './user-formatters';

import paypal from '../../assets/icons/paypal.png';
import users from '../../assets/icons/users.svg';
import baggages from '../../assets/icons/baggages.svg';
import user from '../../assets/icons/user.svg';
import car from '../../assets/icons/car.svg';
import phone from '../../assets/icons/phone.svg';
import print from '../../assets/icons/print.svg';
import edit from '../../assets/icons/edit.svg';
import blocked from '../../assets/icons/blocked.svg';
import remove from '../../assets/icons/remove.svg';
import showDetails from '../../assets/icons/show_details.svg';
import cardLast4NumbImg from '../../assets/icons/cardLast4Numb.svg';
import { IssuesCloseOutlined } from '@ant-design/icons';

import usersPast from '../../assets/icons/users-past.svg';
import baggagesPast from '../../assets/icons/baggages-past.svg';
import userPast from '../../assets/icons/user-past.svg';
import carPast from '../../assets/icons/car-past.svg';
import phonePast from '../../assets/icons/phone-past.svg';
import printPast from '../../assets/icons/print-past.svg';
import showDetailsPast from '../../assets/icons/show_details_past.svg';
import cardLast4NumbImgPast from '../../assets/icons/cardLast4NumbPast.svg';

import mastercard from '../../assets/icons/mastercard.svg';
import visa from '../../assets/icons/visa.svg';

class MyOrders extends React.Component {
    componentDidMount() {
        const { loadMyOrders } = this.props;

        loadMyOrders();
    }

    confirmDelete = (record) => {
        const { deleteTrip } = this.props;

        Modal.confirm({
            icon: <IssuesCloseOutlined style={{ color: '#ff4d4f' }} />,
            title: 'Confirm delete',
            content: `Your trip is due to begin in less than ${record.minFreeCancelationTime} hours. But refund might not be given since it’s a late cancel! ${Translater.t('deleteTripMsg')}`,
            okText: 'Delete',
            cancelText: 'Cancel',
            centered: true,
            onOk: () => deleteTrip(record.id)
        });
    }

    getColumns = (mode) => {
        const { deleteTrip, editTrip } = this.props;

        return mode === RESOLUTION_TYPES.mobile ? [{
            title: <span className='mobile-date'>{Translater.t('dateTitle')}</span>,
            key: 'date',
            dataIndex: 'date',
            width: '30%',
            render: (_text, { startTripDatetime, timeType }) => (
                <div className="d-flex flex-column mobile-date">
                    <span className='time-label'>
                    {
                        timeType === TIME_TYPE.Upcoming ? Translater.t('upcomingTitle') : Translater.t('pastTitle')
                    }
                    </span>
                    <span className='date mt-1'>{moment(startTripDatetime).format('D MMM')}</span>
                    <span className='date-label mt-3'>{Translater.t('timeTitle')}</span>
                    <span className='date time mt-1'>{moment(startTripDatetime).format('HH:mm')}</span>
                </div>
            )
        }, {
            title: Translater.t('fromAtoBTitle'),
            key: 'destination',
            dataIndex: 'destination',
            width: '80%',
            render: (_text, { pickupLocation, dropoffLocation }) => (
                <div className="d-flex flex-column">
                    <span className='location-label'>{Translater.t('pickupLocationTitle')}</span>
                    <span className='order-value mt-1'>{pickupLocation.name}</span>
                    <span className='location-label mt-3'>{Translater.t('destinationTitle')}</span>
                    <span className='order-value mt-1'>{dropoffLocation.name}</span>
                </div>
            )
        }] : [{
            title: Translater.t('dateTitle'),
            key: 'date',
            dataIndex: 'date',
            width: '10%',
            render: (_text, { startTripDatetime, timeType }) => (
                <div className="d-flex flex-column">
                    <span className='time-label'>
                    {
                        timeType === TIME_TYPE.Upcoming ? Translater.t('upcomingTitle') : Translater.t('pastTitle')
                    }
                    </span>
                    <span className='date mt-1'>{moment(startTripDatetime).format('D MMM')}</span> 
                    <span className='date-label mt-3'>{Translater.t('timeTitle')}</span>
                    <span className='date time mt-1'>{moment(startTripDatetime).format('HH:mm')}</span>
                </div>
            )
        }, {
            title: Translater.t('destinationTitle'),
            key: 'destination',
            dataIndex: 'destination',
            width: '25%',
            render: (_text, { pickupLocation, dropoffLocation }) => (
                <div className="d-flex flex-column">
                    <span className='location-label'>{Translater.t('pickupLocationTitle')}</span>
                    <span className='order-value mt-1'>{pickupLocation.name}</span>
                    <span className='location-label mt-3'>{Translater.t('destinationTitle')}</span>
                    <span className='order-value mt-1'>{dropoffLocation.name}</span>
                </div>
            )
        }, {
            title: Translater.t('driverInfoTitle'),
            key: 'driver',
            dataIndex: 'driver',
            width: '20%',
            render: (_text, { driver, agent, timeType }) => (
                <div className="d-flex flex-column">
                    <span className='location-label mt-3'>{Translater.t('driverTitle')}</span>
                    <span className='order-value mt-3'><img src={timeType === TIME_TYPE.Upcoming ? user : userPast} alt="driverName" />{driver ? driver.fullName : '-'}</span>
                    <span className='order-value mt-3'><img src={timeType === TIME_TYPE.Upcoming ? phone : phonePast} alt="driverPhone" />{driver ? driver.phone : '-'}</span>
                    <span className='location-label mt-3'>{Translater.t('agentTitle')}</span>
                    <span className='order-value mt-3'><img src={timeType === TIME_TYPE.Upcoming ? user : userPast} alt="driverName" />{agent ? agent.fullName : '-'}</span>
                    <span className='order-value mt-3'><img src={timeType === TIME_TYPE.Upcoming ? phone : phonePast} alt="driverPhone" />{agent ? agent.phone : '-'}</span>
                </div>
            )
        }, {
            title: Translater.t('carDetailsTitle'),
            key: 'car',
            dataIndex: 'car',
            width: '20%',
            render: (_text, { carTitle, baggageCount, passengersCount, timeType }) => (
                <div className="d-flex flex-column">
                    <span className='order-value mt-3'><img src={timeType === TIME_TYPE.Upcoming ? car : carPast} alt="carTitle" />{carTitle}</span>
                    <span className='order-value mt-3'><img src={timeType === TIME_TYPE.Upcoming ? users : usersPast} alt="passengersCount" />{passengersCount}</span>
                    <span className='order-value mt-3'><img src={timeType === TIME_TYPE.Upcoming ? baggages : baggagesPast} alt="baggageCount" />{baggageCount}</span>
                </div>
            )
        }, {
            title: Translater.t('paymentTitle'),
            key: 'payment',
            dataIndex: 'payment',
            width: '20%',
            render: (_text, { total, currency, cardLast4Numb, cardBrand, timeType, includedVipService }) => (
                <div className="d-flex flex-column payment">
                    { includedVipService && <span className='vip-user-label mb-3'>VIP Service Included</span> }
                    { cardBrand && cardBrand !== STRING_EMPTY && <img className='card' src={cardBrand === CARD_TYPES.Visa ? visa : cardBrand === CARD_TYPES.MasterCard ? mastercard : paypal} alt="cardBrand" /> }
                    <div className="d-flex align-items-baseline mt-2">
                        <span className="dollar">{getSymbolFromCurrency(currency)}</span>
                        <span className="price">
                            {Math.trunc(total)}.<span className="decimal">{(total % 1).toFixed(2) * 100}</span>
                        </span>
                    </div>
                    { cardLast4Numb && <span className='location-label mt-1'><img className='mr-2' src={timeType === TIME_TYPE.Upcoming ? cardLast4NumbImg : cardLast4NumbImgPast} alt="cardLast4NumbImgPast" />{cardLast4Numb}</span> }
                </div>
            )
        }, {
            key: 'actions',
            dataIndex: 'actions',
            width: '5%',
            render: (_text, record) => (
                <div className="d-flex flex-column actions">
                    <img className='mr-2' 
                        src={record.timeType === TIME_TYPE.Upcoming ? print : printPast}
                        alt="print"
                        onClick={() => printJS({ printable: recordToRawHtml(record), type: 'raw-html' })} />
                    { 
                        record.timeType === TIME_TYPE.Upcoming && (
                            record.minFreeCancelationTime && 
                            moment(new Date()).isAfter(moment(record.startTripDatetime).add(record.minFreeCancelationTime * (-1), 'hour')) ? (
                                <Tooltip getPopupContainer={trigger => trigger.parentNode} trigger={[ 'hover', 'click' ]} className='disabled' title={`Your trip is due to begin in less than ${record.minFreeCancelationTime} hours, please contact the SUPPLIER PHONE directly for any last minute changes.`}>
                                    <img className='mr-2' src={edit} alt="edit" />
                                </Tooltip>
                            ) : (
                                <Popconfirm title={Translater.t('editTripMsg')}
                                            onConfirm={() => editTrip(record.id)}
                                            onCancel={() => ({})}
                                            okText={Translater.t('yesTitle')}
                                            cancelText={Translater.t('noTitle')}>
                                       <img className='mr-2' src={edit} alt="edit" />
                                </Popconfirm>
                            )
                        )
                    }
                    { 
                        record.timeType === TIME_TYPE.Past 
                            ? (<img className='mr-2 disabled' src={blocked} alt="blocked" />)
                            : (
                                record.minFreeCancelationTime && 
                                moment(new Date()).isAfter(moment(record.startTripDatetime).add(record.minFreeCancelationTime * (-1), 'hour')) ? (
                                    <img className='mr-2' src={remove} alt="remove" onClick={() => this.confirmDelete(record)} />
                                ) : (
                                    <Popconfirm title={Translater.t('deleteTripMsg')}
                                                onConfirm={() => deleteTrip(record.id)}
                                                onCancel={() => ({})}
                                                okText={Translater.t('yesTitle')}
                                                cancelText={Translater.t('noTitle')}>
                                        <img className='mr-2' src={remove} alt="remove" />
                                    </Popconfirm>
                                )
                            )
                        }
                </div>
            )
        }];
    }

    render() {
        const { myOrders, isLoadedMyOrders, myOrdersCurrentPage, myOrdersItemsPerPage, myOrdersTotal, setCurrentPage, setItemsPerPage, mode } = this.props;
        const expandedRowKeys = myOrders && myOrders.map(x => `${moment(x.startTripDatetime).format()}_${x.pickupLocation.title}`);
       
        return (
            <Fragment>
                {
                    <LoadingOverlay className="my-orders w-100" active={!isLoadedMyOrders} spinner>
                        <div className="d-flex h-50">
                            {
                                myOrders && (
                                    <Table rowKey={(record) => `${moment(record.startTripDatetime).format()}_${record.pickupLocation.title}`}
                                           dataSource={myOrders}
                                           expandable={mode === RESOLUTION_TYPES.mobile ? {
                                                defaultExpandAllRows: true,
                                                expandIcon: () => null,
                                                expandedRowKeys,
                                                expandRowByClick: true,
                                                expandedRowRender: record => (
                                                    <Link to={`/trip-details/${record.id}`}>
                                                        <div className='d-flex justify-content-end align-items-center'>
                                                            <span className='show-details'>{Translater.t('showDetailsTitle')}</span>
                                                            <img src={record.timeType === TIME_TYPE.Upcoming ? showDetails : showDetailsPast} alt="showDetails" />
                                                        </div>
                                                    </Link>
                                                )
                                           } : {}}
                                           pagination={{
                                            current: myOrdersCurrentPage,
                                            pageSize: myOrdersItemsPerPage,
                                            total: myOrdersTotal,
                                            pageSizeOptions: ['5', '10', '15', '20'],
                                            showSizeChanger: true,
                                            onChange: setCurrentPage,
                                            onShowSizeChange: setItemsPerPage
                                           }}
                                           columns={this.getColumns(mode)}
                                           rowClassName={({ timeType }) => timeType === TIME_TYPE.Upcoming ? 'upcoming' : STRING_EMPTY}
                                           bordered={true} />
                                )
                            }
                        </div>
                    </LoadingOverlay>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { authentication } = state;

    return {
        ...authentication
    };
}

const mapDispatchToProps = {
    loadMyOrders: () => userActions.loadMyOrders(),
    setCurrentPage: (page, pageSize) => userActions.setCurrentPage(page, pageSize),
    setItemsPerPage: (current, size) => userActions.setItemsPerPage(current, size),
    deleteTrip: (tripId) => userActions.deleteTrip(tripId),
    editTrip: (tripId) => userActions.editTrip(tripId)
}

const connectedMyOrders = connect(mapStateToProps, mapDispatchToProps)(MyOrders);
export { connectedMyOrders as MyOrders };