export const USER_INPUT_TYPES = {
    email: 'email',
    phone: 'phone',
    password: 'password',
    string: 'string',
    promo: 'promo',
    number: 'number',
    flightNumber: 'flightNumber'
}

export const RESOLUTION_TYPES = {
    mobile: 'mobile',
    tablet: 'tablet',
    desktop: 'desktop'
}

export const USER_ROLES = {
    Admin: "Admin",
    CompanyAccount: "CompanyAccount",
    User: "User"
}

export const ADMIN_PAGES = {
    AdminDiscounts: 'AdminDiscounts',
    AdminLocations: 'AdminLocations',
    AdminAdditionalOptions: 'AdminAdditionalOptions',
    AdminVip: 'AdminVip',
    AdminAffiliates: 'AdminAffiliates',
    AdminAffiliateSettlements: 'AdminAffiliateSettlements',
    AdminVehicles: 'AdminVehicles',
    AdminExtraPrices: 'AdminExtraPrices',
    AdminPaymentRules: 'AdminPaymentRules',
    AdminTrips: 'AdminTrips',
    AdminAccounts: 'AdminAccounts',
    AdminRoutes: 'AdminRoutes',
    AdminDispatch: 'AdminDispatch',
    AdminNotFoundRoutes: 'AdminNotFoundRoutes',
    AdminTripSettlements: 'AdminTripSettlements',
    AdminUnfinalizedTrips: 'AdminUnfinalizedTrips'
}

export const DEFAULT_ZERO_VALUE = 0;
export const STRING_EMPTY = '';
export const MAX_PERCENTAGE = 100;
export const FREE_COST = 'Free';
export const INVOICED_COLUMN_TITLE = 'INVOICED';
export const DISPATCH_SEARCH_DEFAULT_VALUE = 'Passenger';
export const TRIP_SETTLEMENT_SEARCH_DEFAULT_VALUE = 'OrderNumber';
export const AFFILIATE_SETTLEMENT_SEARCH_DEFAULT_VALUE = 'Id';
export const UNFINALIZED_TRIP_SEARCH_DEFAULT_VALUE = 'Id';
