import React from "react";
import { I18n as Translater } from 'react-redux-i18n';
import { USER_INPUT_TYPES, STRING_EMPTY } from '../assets/constants/global';
import { UserInput } from './user-input';
import { Modal, Tabs } from 'antd';
import I18n from '../i18n';
import { SubmitButton } from './submit-button';
import { Link } from 'react-router-i18n';
import close from '../assets/icons/close.svg';

const { TabPane } = Tabs;

export class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            companyEmail: STRING_EMPTY,
            bookingNumber: STRING_EMPTY,
            userEmail: STRING_EMPTY,
            userPassword: STRING_EMPTY
        }
    }

    setValue = (key, value) => {
        this.setState({ [key]: value })
    }

    render() {
        const { handleLoginByBookingNumber, handleLogin, isLoadedLogin, handleForgotPassword } = this.props;
        const { companyEmail, bookingNumber, userEmail, userPassword } = this.state;
        const disabledSubmit = companyEmail === STRING_EMPTY || bookingNumber === STRING_EMPTY;
        const disabledCompanySubmit = userEmail === STRING_EMPTY || userPassword === STRING_EMPTY;

        return (
            <div id='login'>
                <Modal title="Login Modal"
                       visible={true}
                       centered={true}
                       footer={null}
                       maskClosable={false}
                       closeIcon={<Link to='/'><img src={close} alt='close' /></Link>}
                       getContainer={() => document.getElementById('login')}>
                    <div className="d-flex flex-column align-items-center">
                        <span className='payment-title'><I18n t='loginTitle' /></span>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={Translater.t('byBookingNumberTitle')} key="byBookingNumber">
                                <div className="d-flex flex-column w-100">
                                    <UserInput optional={false}
                                               placeholder={Translater.t('emailTitle')}
                                               containerClassName='mr-3'
                                               type={USER_INPUT_TYPES.email}
                                               onChange={(value) => this.setValue('companyEmail', value)} />
                                    <UserInput optional={false} 
                                               placeholder={
                                                <div className='d-flex justify-content-between'>
                                                    {Translater.t('bookingNumberPlaceholder')}
                                                </div>
                                               }
                                               containerClassName='mr-3'
                                               type={USER_INPUT_TYPES.number}
                                               onChange={(value) => this.setValue('bookingNumber', value)} />
                                    <SubmitButton label={Translater.t('loginTitle')}
                                                  disabled={disabledSubmit}
                                                  loading={!isLoadedLogin}
                                                  onClick={() => handleLoginByBookingNumber(companyEmail, bookingNumber)} />
                                    {/* <div className='do-not-have-account'>
                                        <span><I18n t='doNotHaveAccountTitle' /></span>
                                        <Link to='/signin'><I18n t='getFreeHereTitle' /></Link>
                                    </div> */}
                                </div>
                            </TabPane>
                            <TabPane tab={Translater.t('byPasswordTitle')} key="accountHolders">
                                <div className="d-flex flex-column w-100">
                                    <UserInput optional={false}
                                               placeholder={Translater.t('emailTitle')}
                                               containerClassName='mr-3'
                                               type={USER_INPUT_TYPES.email}
                                               onChange={(value) => this.setValue('userEmail', value)} />
                                    <UserInput optional={false} 
                                               placeholder={
                                                <div className='d-flex justify-content-between w-100 align-items-center'>
                                                    <span className='password'>{Translater.t('passwordTitle')}</span>
                                                    {
                                                        userEmail && !userPassword && (
                                                            <span onClick={() => handleForgotPassword(userEmail)} className='forgot'>{Translater.t('forgotTitle')}</span>
                                                        )
                                                    }
                                                </div>
                                               }
                                               containerClassName='mr-3'
                                               type={USER_INPUT_TYPES.password}
                                               onChange={(value) => this.setValue('userPassword', value)} />
                                    <SubmitButton label={Translater.t('loginTitle')}
                                                  disabled={disabledCompanySubmit}
                                                  loading={!isLoadedLogin}
                                                  onClick={() => handleLogin(userEmail, userPassword)} />
                                    {/* <div className='do-not-have-account'>
                                        <span><I18n t='doNotHaveAccountTitle' /></span>
                                        <Link to='/signin'><I18n t='getFreeHereTitle' /></Link>
                                    </div> */}
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </Modal>
            </div>
        )
    }
}
    