import React from 'react';
import { connect } from 'react-redux';
import { withLayout } from '../../common/layout';

import { PROP_KEYS } from '../home/home-constants';
import { homeActions } from '../home/home-reducer';

class PrivacyAndTerms extends React.Component {
    
    componentDidMount() {
        document.getElementById("initialTripAdvisorWidget").className = "tripAdvisorWidget-content";
        this.props.getPrivacyAndTermsPageHtml();
    }
    
    componentWillUpdate() {
        document.getElementById("initialTripAdvisorWidget").className = "tripAdvisorWidget-content";
    }
    
    componentWillUnmount() {
        document.getElementById("initialTripAdvisorWidget").className = "display-none";
    }

    render() {
        const html = {__html: this.props.privacyAndTermsPage};

        return (
            <div dangerouslySetInnerHTML={html}></div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, location: { privacyAndTermsPage } } = state;
    const { user } = authentication;
    return {
        user,
        privacyAndTermsPage
    };
}

const mapDispatchToProps = {
    getPrivacyAndTermsPageHtml: () => homeActions.loadHtml(PROP_KEYS.privacyAndTermsPage)
}

const connectedPrivacyAndTerms = connect(mapStateToProps, mapDispatchToProps)(withLayout(PrivacyAndTerms));
export { connectedPrivacyAndTerms as PrivacyAndTerms };