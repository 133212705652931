import React from "react";
import { Progress  } from 'antd';
import { I18n as Translater } from 'react-redux-i18n';
import { STRING_EMPTY } from '../assets/constants/global';

export const ProgressPages = ({ currentPage }) => {
    const steps = [ 'rideDetails', 'results', 'checkout' ];
    const currentStepId = steps.findIndex(x => x === currentPage);
    const progressPercent = 100 / steps.length * (currentStepId + 1);

    return (
        <div className="d-flex flex-column progress-pages">
            <div className="d-flex steps">
            {
                steps.map((step, index) => {
                    const className = index < currentStepId ? 'prev-step' 
                                    : index === currentStepId ? STRING_EMPTY : 'next-step';

                    return (<span key={`page_step_${index}`} className={`step ${className}`}>{Translater.t(`pageTitles.${step}`)}</span>)
                })
            }
            </div>
            <Progress percent={progressPercent} showInfo={false} />
        </div>
    )
}