import React, { Fragment } from "react";
import default_car from '../assets/icons/default_car.png';
import passengersUnrecommended from '../assets/icons/passengers-unrecommended.svg';
import baggageUnrecommended from '../assets/icons/baggage-unrecommended.svg';
import passengers from '../assets/icons/passengers.svg';
import baggage from '../assets/icons/baggage.svg';
import I18n from '../i18n';
import { Button } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';

export const CarTile = (props) => {
    const {
        title,
        type,
        price,
        image,
        currency,
        baggageCount,
        passengersCount,
        features,
        recommended,
        extra_client_payments,
        return_transfer_extra_client_payments,
        onSelect,
        isMobile = false
    } = props;

    return (
        <div className={`d-flex car-tile ${recommended && 'recommended'}`}>
            {
                isMobile ? (
                    <Fragment>
                        <div className="col-7 d-flex flex-column justify-content-center">
                            <img className="car" src={image || default_car} alt="Car" />
                            <span className="title">{title}</span>
                            <div className="d-flex baggage-passengers">
                                <img src={recommended ? passengers : passengersUnrecommended} alt="passengers" />
                                <span>{passengersCount}</span>
                                <img src={recommended ? baggage : baggageUnrecommended} alt="baggage" />
                                <span>{baggageCount}</span>
                            </div>
                            <ul className="options mt-2">
                                {
                                    features.map(name => (<li key={`add_option_${name}`}>{name}</li>))
                                }
                            </ul>
                        </div>
                        <div className="col-5 mt-4 d-flex flex-column justify-content-around align-items-end">
                            { recommended && <div className="d-flex results-recommended"><I18n t="recommendedTitle" /></div> }
                            <span className="car-type">{type}</span>
                            <div className="d-flex justify-content-end align-items-baseline">
                                <span className="dollar">{getSymbolFromCurrency(currency)}</span>
                                <span className="price">
                                    {price.toFixed(0)}.<span className="decimal">{(price % 1).toFixed(2) * 100}</span>
                                </span>
                            </div>
                            <Button onClick={() => onSelect()}><I18n t="selectTitle" /></Button>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="col-4 d-flex flex-column justify-content-center">
                            { recommended && <div className="d-flex results-recommended"><I18n t="recommendedTitle" /></div> }
                            <img className="car" src={image || default_car} alt="Car" />
                            <span className="car-type">{type}</span>
                        </div>
                        <div className="col-4 mt-3 d-flex flex-column">
                            <span className="title">{title}</span>
                            <div className="d-flex baggage-passengers">
                                <img src={recommended ? passengers : passengersUnrecommended} alt="passengers" />
                                <span>{passengersCount}</span>
                                <img src={recommended ? baggage : baggageUnrecommended} alt="baggage" />
                                <span>{baggageCount}</span>
                            </div>
                            <ul className="options mt-2">
                                {
                                    features.map(name => (<li key={`add_option_${name}`}>{name}</li>))
                                }
                            </ul>
                        </div>
                        <div className="col-4 mt-4 d-flex flex-column justify-content-around align-items-end">
                            {
                                extra_client_payments && (
                                    extra_client_payments.map((payment, index) => (
                                        <div key={`extra_price_${index}`} className='extra-price'>{payment.surchargeTypeName} (+{payment.clientExtraPrice} {payment.clientTypeOfRule === 'Percentage' ? '%' : getSymbolFromCurrency(currency)})</div>
                                    ))
                                )
                            }
                            {
                                return_transfer_extra_client_payments && (
                                    return_transfer_extra_client_payments.map((payment, index) => (
                                        <div key={`return_extra_price_${index}`} className='extra-price'>⤺ {payment.surchargeTypeName} (+{payment.clientExtraPrice} {payment.clientTypeOfRule === 'Percentage' ? '%' : getSymbolFromCurrency(currency)})</div>
                                    ))
                                )
                            }
                            <div className="d-flex justify-content-end align-items-baseline">
                                <span className="dollar">{getSymbolFromCurrency(currency)}</span>
                                <span className="price">
                                    {price.toFixed(0)}.<span className="decimal">{(price % 1).toFixed(2) * 100}</span>
                                </span>
                            </div>
                            <Button onClick={() => onSelect()}><I18n t="selectTitle" /></Button>
                        </div>
                    </Fragment>
                )
            }
        </div>
    )
}