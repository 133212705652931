export const CARD_TYPES = {
    Visa: 'visa',
    MasterCard: 'masterCard'
}

export const PROP_KEYS = {
    myOrders: 'myOrders',
    myOrdersCurrentPage: 'myOrdersCurrentPage',
    myOrdersItemsPerPage: 'myOrdersItemsPerPage',
    myOrdersTotal: 'myOrdersTotal',
    isLoadedMyOrders: 'isLoadedMyOrders',
    token: 'token',
    user: 'user',
    isLoadedLogin: 'isLoadedLogin',
    isLoadedSignin: 'isLoadedSignin',
    isLoadedChangePassword: 'isLoadedChangePassword',
    pagination: 'pagination',
    oldPassword: 'oldPassword',
    newPassword: 'newPassword',
    repeatNewPassword: 'repeatNewPassword'
}

export const TIME_TYPE = {
    Past: 'Past',
    Upcoming: 'Upcoming'
}