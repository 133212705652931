export const supportedLocales = {
    en: "English",
    he: "עִבְרִית",
};

export const locales = [ 'en', 'he' ];
  
export const defaultLocale = "en";
  
export const fallbackLocale = "en";

export const baseUrl = '/:locale(en|he)?';