export const PROP_KEYS = {
    // locations
    locations: 'locations',
    isLoadedLocations: 'isLoadedLocations',
    importedLocations: 'importedLocations',
    locationsPagination: 'locationsPagination',
    locationsErrorMsg: 'locationsErrorMsg',
    locationColumnTypes: 'locationColumnTypes',
    // affiliates
    affiliates: 'affiliates',
    isLoadedAffiliates: 'isLoadedAffiliates',
    importedAffiliates: 'importedAffiliates',
    affiliatesPagination: 'affiliatesPagination',
    affiliatesErrorMsg: 'affiliatesErrorMsg',
    affiliateColumnTypes: 'affiliateColumnTypes',
    // affiliateSettlements
    isLoadedaffiliateSettlements: 'isLoadedaffiliateSettlements',
    affiliateSettlementsPagination: 'affiliateSettlementsPagination',
    affiliateSettlements: 'affiliateSettlements',
    affiliateSettlementsColumnTypes: 'affiliateSettlementsColumnTypes',
    affiliateSettlementsInvoiced: 'affiliateSettlementsInvoiced',
    // vehicles
    vehicles: 'vehicles',
    isLoadedVehicles: 'isLoadedVehicles',
    importedVehicles: 'importedVehicles',
    vehiclesPagination: 'vehiclesPagination',
    vehiclesErrorMsg: 'vehiclesErrorMsg',
    vehicleColumnTypes: 'vehicleColumnTypes',
    // additionalOptions
    additionalOptions: 'additionalOptions',
    isLoadedAdditionalOptions: 'isLoadedAdditionalOptions',
    importedAdditionalOptions: 'importedAdditionalOptions',
    additionalOptionsPagination: 'additionalOptionsPagination',
    additionalOptionsErrorMsg: 'additionalOptionsErrorMsg',
    additionalOptionColumnTypes: 'additionalOptionColumnTypes',
    // additionalOptionsPerVip
    additionalOptionsPerVip: 'additionalOptionsPerVip',
    isLoadedAdditionalOptionsPerVip: 'isLoadedAdditionalOptionsPerVip',
    importedAdditionalOptionsPerVip: 'importedAdditionalOptionsPerVip',
    additionalOptionsPerVipPagination: 'additionalOptionsPerVipPagination',
    additionalOptionsPerVipErrorMsg: 'additionalOptionsPerVipErrorMsg',
    additionalOptionsPerVipColumnTypes: 'additionalOptionsPerVipColumnTypes',
    // extraPrices
    extraPrices: 'extraPrices',
    isLoadedExtraPrices: 'isLoadedExtraPrices',
    importedExtraPrices: 'importedExtraPrices',
    extraPricesPagination: 'extraPricesPagination',
    extraPricesErrorMsg: 'extraPricesErrorMsg',
    extraPriceColumnTypes: 'extraPriceColumnTypes',
    // routes
    routes: 'routes',
    isLoadedRoutes: 'isLoadedRoutes',
    importedRoutes: 'importedRoutes',
    routesPagination: 'routesPagination',
    routesErrorMsg: 'routesErrorMsg',
    routeColumnTypes: 'routeColumnTypes',
    // paymentRules
    paymentRules: 'paymentRules',
    isLoadedPaymentRules: 'isLoadedPaymentRules',
    importedPaymentRules: 'importedPaymentRules',
    paymentRulesPagination: 'paymentRulesPagination',
    paymentRulesErrorMsg: 'paymentRulesErrorMsg',
    paymentRuleColumnTypes: 'paymentRuleColumnTypes',
    // discounts
    discounts: 'discounts',
    isLoadedDiscounts: 'isLoadedDiscounts',
    importedDiscounts: 'importedDiscounts',
    discountsPagination: 'discountsPagination',
    discountsErrorMsg: 'discountsErrorMsg',
    discountColumnTypes: 'discountColumnTypes',
    // trips
    isLoadedTrips: 'isLoadedTrips',
    tripsPagination: 'tripsPagination',
    trips: 'trips',
    tripColumnTypes: 'tripColumnTypes',
    // tripSettlements
    isLoadedtripSettlements: 'isLoadedtripSettlements',
    tripSettlementsPagination: 'tripSettlementsPagination',
    tripSettlements: 'tripSettlements',
    tripSettlementsColumnTypes: 'tripSettlementsColumnTypes',
    tripSettlementsInvoiced: 'tripSettlementsInvoiced',
    // unfinalizedTrips
    unfinalizedTrips: 'unfinalizedTrips',
    isLoadedUnfinalizedTrips: 'isLoadedUnfinalizedTrips',
    unfinalizedTripsPagination: 'unfinalizedTripsPagination',
    unfinalizedTripsColumnTypes: 'unfinalizedTripsColumnTypes',
    unfinalizedTripsChecked: 'unfinalizedTripsChecked',
    // accounts
    isLoadedAccounts: 'isLoadedAccounts',
    accountsPagination: 'accountsPagination',
    accounts: 'accounts',
    accountColumnTypes: 'accountColumnTypes',
    // dispatch
    dispatches: 'dispatches',
    isLoadedDispatch: 'isLoadedDispatch',
    dispatchPagination: 'dispatchPagination',
    dispatchColumnTypes: 'dispatchColumnTypes',
    doneTrips: 'doneTrips',
    // notFoundRoutes
    notFoundRoutes: 'notFoundRoutes',
    notFoundRoutesPagination: 'notFoundRoutesPagination',
    isLoadedNotFoundRoutes: 'isLoadedNotFoundRoutes',
    notFoundRouteColumnTypes: 'notFoundRouteColumnTypes'
};

export const TEMPLATE_TYPES = {
    Discount: 'PromoCode',
    Location: 'Location',
    Affiliate: 'Affiliate',
    AffiliateSettlement: 'AffiliateSettlement',
    AdditionalOption: 'AdditionalOptionType',
    AdditionalOptionPerVip: 'AdditionalOptionPerVip',
    ExtraPrice: 'SurchargeType',
    AffiliatePaymentRule: 'AffiliatePaymentRule',
    Vehicle: 'VehicleType',
    Route: 'Route',
    Trip: 'Trip',
    TripSettlement: 'TripSettlement',
    UnfinalizedTrip: 'UnfinalizedTrip',
    Account: 'Account',
    Dispatch: 'Dispatch',
    NotFoundRoute: 'NotFoundRoute'
};

export const ACTION_LOAD_TYPES = {
    discount: 'promocode/get',
    location: 'location/get',
    affiliate: 'affiliate/get',
    affiliateSettlements: 'settlement/get-affiliate-setlement',
    additionalOptionType: 'addition-option/get',
    additionalOptionPerVip: 'addition-option/get-vip',
    extraPrice: 'extra-price/get',
    paymentRule: 'extra-price/get-payment-rules',
    vehicle: 'vehicle/get',
    route: 'route/get-routes',
    trip: 'trip/get-all',
    tripSettlements: 'settlement/get-trip-setlement',
    unfinalizedTrips: 'unfinalized-trip/get',
    account: 'user/get',
    dispatch: 'trip/get-dispatch',
    notFoundRoute: 'route/get-not-found'
};

export const ACTION_UPDATE_TYPES = {
    tripSettlementsInvoiced: 'settlement/update-trip-settlements-invoiced',
    affiliateSettlementsInvoiced: 'settlement/update-affiliate-settlements-invoiced',
};

export const ACTION_REMOVE_TYPES = {
    unfinalizedTripsChecked: 'unfinalized-trip/remove'
};

export const ACTION_IMPORT_TYPES = {
    discount: 'import/promocode',
    location: 'import/location',
    affiliate: 'import/affiliate',
    additionalOptionType: 'import/addition-option',
    additionalOptionPerVip: 'import/addition-option-vip',
    extraPrice: 'import/extra-price',
    paymentRule: 'import/extra-price-payment-rules',
    vehicle: 'import/vehicle',
    route: 'import/route'
};

export const PAYMENT_STATUSES = {
    PAYMENT_CONFIRMED_WITHOUT_DETAILS: 'Confirmed without details',
    PAYMENT_DONE: 'Done',
    PAYMENT_CONFIRMED: 'Confirmed',
    PAYMENT_UNCONFIRMED: 'Un-confirmed'
};