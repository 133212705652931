import React from "react";
import { I18n as Translater } from 'react-redux-i18n';
import { USER_INPUT_TYPES, STRING_EMPTY } from '../assets/constants/global';
import { UserInput } from './user-input';
import { Modal } from 'antd';
import I18n from '../i18n';
import { SubmitButton } from './submit-button';
import { Link } from 'react-router-i18n';
import close from '../assets/icons/close.svg';

export class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: STRING_EMPTY,
            confirmPassword: STRING_EMPTY
        }
    }

    setValue = (key, value) => {
        this.setState({ [key]: value })
    }

    render() {
        const { handleResetPassword, userId, code } = this.props;
        const { password, confirmPassword } = this.state;
        const disabledSubmit = password === STRING_EMPTY 
                            || confirmPassword === STRING_EMPTY
                            || password !== confirmPassword;

        return (
            <div id='signin'>
                <Modal title="SignIn Modal"
                       visible={true}
                       centered={true}
                       footer={null}
                       maskClosable={false}
                       closeIcon={<Link to='/'><img src={close} alt='close' /></Link>}
                       getContainer={() => document.getElementById('signin')}>
                    <div className="d-flex flex-column align-items-center">
                        <span className='payment-title'><I18n t='resetPasswordTitle' /></span>
                        <div className="d-flex flex-column w-100">
                            <UserInput optional={false} placeholder={Translater.t('passwordTitle')} type={USER_INPUT_TYPES.password} onChange={(value) => this.setValue('password', value)} />
                            <UserInput optional={false} placeholder={Translater.t('passwordConfirmTitle')} type={USER_INPUT_TYPES.password} onChange={(value) => this.setValue('confirmPassword', value)} />
                            <SubmitButton label={Translater.t('resetTitle')}
                                          disabled={disabledSubmit}
                                          onClick={() => handleResetPassword({
                                            userId,
                                            password,
                                            confirmPassword,
                                            code
                                          })} />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
    