import { adminService } from './admin-service';
import { PROP_KEYS } from './admin-constants';
import { notification } from 'antd';
import xlsx from 'json-as-xlsx';
import moment from 'moment';
import { DEFAULT_ZERO_VALUE, STRING_EMPTY } from '../../assets/constants/global';

// types
const TYPES = {
    SET_VALUE: 'SET_VALUE',
    SET_SUB_VALUE: 'SET_SUB_VALUE'
};

// actions
const setValue = (key, value) =>  ({ type: TYPES.SET_VALUE, key, value })
const setSubValue = (key, subKey, value) => ({ type: TYPES.SET_SUB_VALUE, key, subKey, value })
const load = ({ action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey }) => {
    return (dispatch, getState) => {
        dispatch(setValue(isLoadedKey, false));
        const { limit, offset, sortBy, sortAsc, searchBy, searchText, searchRange } = getState().admin[paginationKey];

        // with pagination
        adminService.load(action, { limit, offset, sortBy, sortAsc, searchBy, searchText, searchRange })
                    .then(({ data, pagination }) => {
                        dispatch(setValue(dataKey, data));
                        dispatch(setValue(paginationKey, { ...pagination, searchBy, searchText, searchRange }));
                        
                        if (fileKey) {
                            dispatch(loadColumnTypes({ fileKey, isLoadedKey, columnTypesKey }));
                        }
                    })
                    .finally(() => dispatch(setValue(isLoadedKey, true)));
    };
}

const importData = ({ action, importAction, isLoadedKey, paginationKey, dataKey, importedKey, errorMsgKey }) => {
    return (dispatch, getState) => {
        dispatch(setValue(isLoadedKey, false));
        dispatch(setValue(errorMsgKey, []));
        const admin = getState().admin;

        adminService.import(importAction, admin[importedKey])
                    .then( 
                        ({ errorMsg, isSuccess, data }) => {
                            if (isSuccess) {
                                notification.success({
                                    message: `Data imported successfully! (${data.length} items)`
                                });
                                dispatch(load({ action, isLoadedKey, paginationKey, dataKey }));
                            } else {
                                dispatch(setValue(errorMsgKey, errorMsg));
                            }
                        }
                    )
                    .catch(
                        error => {
                            dispatch(setValue(errorMsgKey, error));
                        }
                    )
                    .finally(() => {
                        dispatch(setValue(isLoadedKey, true));
                        dispatch(setValue(importedKey, []));
                    });
    };
}

const exportData = ({ action, isLoadedKey, fileKey }) => {
    return (dispatch) => {
        dispatch(setValue(isLoadedKey, false));

        adminService.load(action, {})
                    .then(({ data }) => {
                        if (data && data.length > DEFAULT_ZERO_VALUE) {
                            const columns = Object.keys(data[DEFAULT_ZERO_VALUE]).map(key => ({ label: key, value: key }));
                            const settings = {
                                sheetName: `${moment().format('LL')}`,
                                fileName: `${fileKey} (${moment().format('L')})`,
                            };
                            const formattedData = formatData(data);

                            xlsx(columns, formattedData, settings);
                        }
                    })
                    .finally(() => dispatch(setValue(isLoadedKey, true)));
    };
}

const loadColumnTypes = ({ fileKey, isLoadedKey, columnTypesKey }) => {
    return (dispatch) => {
        dispatch(setValue(isLoadedKey, false));

        adminService.loadTemplate(fileKey)
                    .then(({ data }) => {
                        if (data) {
                            dispatch(setValue(columnTypesKey, data));
                        }
                    })
                    .finally(() => dispatch(setValue(isLoadedKey, true)));
    };
}

const downloadTemplate = ({ isLoadedKey, fileKey }) => {
    return (dispatch) => {
        dispatch(setValue(isLoadedKey, false));

        adminService.loadTemplate(fileKey)
                    .then(({ data }) => {
                        if (data && data.length > DEFAULT_ZERO_VALUE) {
                            const columns = data.map(key => (key && key.name ? { label: key.name, value: key.name } : {}));
                            const settings = {
                                sheetName: `${moment().format('LL')}`,
                                fileName: `${fileKey} Template`,
                            };
                            const content = data.map(key => ({ [key]: STRING_EMPTY }))

                            xlsx(columns, content, settings);
                        }
                    })
                    .finally(() => dispatch(setValue(isLoadedKey, true)));
    };
}

const handlePaginationChange = (page, pageSize, actionProps) => {
    return (dispatch) => {
        dispatch(setSubValue(actionProps.paginationKey, 'offset', page));
        dispatch(setSubValue(actionProps.paginationKey, 'limit', pageSize));
        dispatch(load(actionProps));
    }
}

const handleSort = (page, sorter, actionProps) => {
    return (dispatch) => {
        dispatch(setSubValue(actionProps.paginationKey, 'offset', page));
        dispatch(setSubValue(actionProps.paginationKey, 'sortBy', sorter.field));
        dispatch(setSubValue(actionProps.paginationKey, 'sortAsc', sorter.order === 'ascend'));
        dispatch(load(actionProps));
    }
}

const setSearchText = (value, loadOptions) => {
    return (dispatch) => {
        dispatch(setSubValue(loadOptions.paginationKey, 'searchText', value));
        dispatch(setSubValue(loadOptions.paginationKey, 'searchRange', null));
        dispatch(setSubValue(loadOptions.paginationKey, 'offset', 1));
        dispatch(load(loadOptions));
    }
}

const setSearchDate = (value, loadOptions) => {
    return (dispatch) => {
        dispatch(setSubValue(loadOptions.paginationKey, 'searchBy', 'Date'));
        dispatch(setSubValue(loadOptions.paginationKey, 'searchRange', { dateFrom: value }));
        dispatch(load(loadOptions));
    }
}

const setSearchRange = (dates, loadOptions) => {
    return (dispatch) => {
        dispatch(setSubValue(loadOptions.paginationKey, 'searchBy', 'Date'));
        dispatch(setSubValue(loadOptions.paginationKey, 'searchRange', { dateFrom: moment(dates[0]).utc().startOf('day'), dateTo: moment(dates[1]).utc().startOf('day') }));
        dispatch(load(loadOptions));
    }
}

const clearSearch = (defaultValue, loadOptions) => {
    return (dispatch) => {
        dispatch(setSubValue(loadOptions.paginationKey, 'searchBy', defaultValue));
        dispatch(setSubValue(loadOptions.paginationKey, 'searchText', null));
        dispatch(setSubValue(loadOptions.paginationKey, 'searchRange', null));
        dispatch(load(loadOptions));
    }
}

const markAsChecked = (selectedKeys, loadOptions) => {
    return (dispatch) => {
        dispatch(setValue(loadOptions.checkedDataKey, selectedKeys));
    }
}

const setChecked = (loadOptions) => {
    return (dispatch, getState) => {
        dispatch(setValue(loadOptions.isLoadedKey, false));

        const admin = getState().admin;
        const data = loadOptions.hasVip ? loadOptions.passOnlyId ? mapStringCheckedToId(admin[loadOptions.checkedDataKey]) 
                                                                 : mapStringCheckedToObj(admin[loadOptions.checkedDataKey])
                                        : admin[loadOptions.checkedDataKey];

        adminService.load(loadOptions.updateCheckedDataAction, data)
                    .then(() => {
                        dispatch(markAsChecked([], loadOptions));
                        dispatch(load(loadOptions));
                    })
                    .finally(() => dispatch(setValue(loadOptions.isLoadedKey, true)));
    };
}

const markAsDone = (loadOptions) => {
    return (dispatch, getState) => {
        dispatch(setValue(PROP_KEYS.isLoadedDispatch, false));
        const admin = getState().admin;
        const doneTrips = admin.doneTrips;
        const mapped = mapStringCheckedToObj(doneTrips);
        const dispatches = [];
        mapped.forEach(x => {
            const dispatchItem = admin.dispatches.find(y => y.id == x.id && y.isVip == x.isVip);
            if (dispatchItem) {
                dispatches.push(dispatchItem);
            }
        })
        
        adminService.markAsDone(dispatches)
                    .then(data => {
                        if (data) {
                            notification.success({
                                message: `Trips marked as done successfully! (${dispatches.length} items)`
                            });
                            dispatch(setValue(PROP_KEYS.doneTrips, []));
                            dispatch(load(loadOptions));
                        }
                    })
                    .catch(error => {
                        notification.error({
                            message: error.toString()
                        });
                    })
                    .finally(() => dispatch(setValue(PROP_KEYS.isLoadedDispatch, true)));
    };
}

export const adminActions = {
    setValue,
    setSubValue,
    load,
    importData,
    exportData,
    handleSort,
    clearSearch,
    setSearchText,
    setSearchDate,
    setSearchRange,
    downloadTemplate,
    handlePaginationChange,
    markAsChecked,
    setChecked,
    markAsDone
};

// reducer
const initialState = {
    // locations
    [PROP_KEYS.locations]: [],
    [PROP_KEYS.isLoadedLocations]: true,
    [PROP_KEYS.importedLocations]: [],
    [PROP_KEYS.locationsPagination]: {
        offset: 1,
        limit: 10,
        sortBy: null,
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE
    },
    [PROP_KEYS.locationColumnTypes]: [],
    // affiliates
    [PROP_KEYS.affiliates]: [],
    [PROP_KEYS.isLoadedAffiliates]: true,
    [PROP_KEYS.importedAffiliates]: [],
    [PROP_KEYS.affiliatesPagination]: {
        offset: 1,
        limit: 10,
        sortBy: null,
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE
    },
    [PROP_KEYS.affiliateColumnTypes]: [],
    // affiliate settlement
    [PROP_KEYS.affiliateSettlements]: [],
    [PROP_KEYS.isLoadedAffiliateSettlements]: true,
    [PROP_KEYS.affiliateSettlementsPagination]: {
        offset: 1,
        limit: 10,
        searchBy: 'Id',
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE,
        searchText: null,
        searchRange: null
    },
    [PROP_KEYS.affiliateSettlementsColumnTypes]: [],
    [PROP_KEYS.affiliateSettlementsInvoiced]: [],
    // vehicles
    [PROP_KEYS.vehicles]: [],
    [PROP_KEYS.isLoadedVehicles]: true,
    [PROP_KEYS.importedVehicles]: [],
    [PROP_KEYS.vehiclesPagination]: {
        offset: 1,
        limit: 10,
        sortBy: null,
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE
    },
    [PROP_KEYS.vehicleColumnTypes]: [],
    // additionalOptions
    [PROP_KEYS.additionalOptions]: [],
    [PROP_KEYS.isLoadedAdditionalOptions]: true,
    [PROP_KEYS.importedAdditionalOptions]: [],
    [PROP_KEYS.additionalOptionsPagination]: {
        offset: 1,
        limit: 10,
        sortBy: null,
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE
    },
    [PROP_KEYS.additionalOptionColumnTypes]: [],
    // additionalOptionsPerVip
    [PROP_KEYS.additionalOptionsPerVip]: [],
    [PROP_KEYS.isLoadedAdditionalOptionsPerVip]: true,
    [PROP_KEYS.importedAdditionalOptionsPerVip]: [],
    [PROP_KEYS.additionalOptionsPerVipPagination]: {
        offset: 1,
        limit: 10,
        sortBy: null,
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE
    },
    [PROP_KEYS.additionalOptionsPerVipColumnTypes]: [],
    // extraPrices
    [PROP_KEYS.extraPrices]: [],
    [PROP_KEYS.isLoadedExtraPrices]: true,
    [PROP_KEYS.importedExtraPrices]: [],
    [PROP_KEYS.extraPricesPagination]: {
        offset: 1,
        limit: 10,
        sortBy: null,
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE
    },
    [PROP_KEYS.extraPriceColumnTypes]: [],
    // routes
    [PROP_KEYS.routes]: [],
    [PROP_KEYS.isLoadedRoutes]: true,
    [PROP_KEYS.importedRoutes]: [],
    [PROP_KEYS.routesPagination]: {
        offset: 1,
        limit: 10,
        sortBy: null,
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE
    },
    [PROP_KEYS.routeColumnTypes]: [],
    // paymentRules
    [PROP_KEYS.paymentRules]: [],
    [PROP_KEYS.isLoadedPaymentRules]: true,
    [PROP_KEYS.importedPaymentRules]: [],
    [PROP_KEYS.paymentRulesPagination]: {
        offset: 1,
        limit: 10,
        sortBy: null,
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE
    },
    [PROP_KEYS.paymentRuleColumnTypes]: [],
    // discounts
    [PROP_KEYS.discounts]: [],
    [PROP_KEYS.isLoadedDiscounts]: true,
    [PROP_KEYS.importedDiscounts]: [],
    [PROP_KEYS.discountsPagination]: {
        offset: 1,
        limit: 10,
        sortBy: 'id',
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE
    },
    [PROP_KEYS.discountColumnTypes]: [],
    // trips
    [PROP_KEYS.trips]: [],
    [PROP_KEYS.isLoadedTrips]: true,
    [PROP_KEYS.tripsPagination]: {
        offset: 1,
        limit: 10,
        sortBy: 'id',
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE
    },
    [PROP_KEYS.tripColumnTypes]: [],
    // trip settlement
    [PROP_KEYS.tripSettlements]: [],
    [PROP_KEYS.isLoadedTripSettlements]: true,
    [PROP_KEYS.tripSettlementsPagination]: {
        offset: 1,
        limit: 10,
        searchBy: 'OrderNumber',
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE,
        searchText: null,
        searchRange: null
    },
    [PROP_KEYS.tripSettlementsColumnTypes]: [],
    [PROP_KEYS.tripSettlementsInvoiced]: [],
    // unfinalizedTrips
    [PROP_KEYS.unfinalizedTrips]: [],
    [PROP_KEYS.isLoadedUnfinalizedTrips]: true,
    [PROP_KEYS.unfinalizedTripsPagination]: {
        offset: 1,
        limit: 10,
        searchBy: 'Id',
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE,
        searchText: null,
        searchRange: null
    },
    [PROP_KEYS.unfinalizedTripsColumnTypes]: [],
    // accounts
    [PROP_KEYS.accounts]: [],
    [PROP_KEYS.isLoadedAccounts]: true,
    [PROP_KEYS.accountsPagination]: {
        offset: 1,
        limit: 10,
        sortBy: null,
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE
    },
    [PROP_KEYS.accountColumnTypes]: [],
    // dispatch
    [PROP_KEYS.dispatches]: [],
    [PROP_KEYS.doneTrips]: [],
    [PROP_KEYS.isLoadedDispatch]: true,
    [PROP_KEYS.dispatchPagination]: {
        offset: 1,
        limit: 5,
        sortBy: 'id',
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE,
        searchBy: 'Passenger',
        searchText: null,
        searchRange: null
        /**
         * {
            dateFrom: null,
            dateTo: null
        }
         */
    },
    [PROP_KEYS.dispatchColumnTypes]: [],
    // notFoundRoutes
    [PROP_KEYS.notFoundRoutes]: [],
    [PROP_KEYS.notFoundRoutesPagination]: {
        offset: 1,
        limit: 10,
        sortBy: 'id',
        sortAsc: false,
        total: DEFAULT_ZERO_VALUE
    },
    [PROP_KEYS.isLoadedNotFoundRoutes]: true,
    [PROP_KEYS.notFoundRouteColumnTypes]: []
};

export const admin = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.SET_VALUE: {
            const { key, value } = action;

            return {
                ...state,
                [key]: value
            };
        }
        case TYPES.SET_SUB_VALUE : {
            const { key, subKey, value } = action;

            return {
                ...state,
                [key]: {
                    ...state[key],
                    [subKey]: value
                }
            };
        }
        default:
            return state;
    }
}

const formatData = (data) => {
    return data.map(x => {
        Object.keys(x).forEach(k => {
            if (!x[k]) {
                x[k] = STRING_EMPTY;
            }
        })

        return x;
    })
}

const mapStringCheckedToObj = (data) => {
    return data && data.map(x => {
        const val = x.split('_');
        if (val && val[0] && val[1]) {
            var isTrueSet = (val[1] == 'true');

            return { id: parseInt(val[0]), isVip: isTrueSet };
        }
    });
}

const mapStringCheckedToId = (data) => {
    return data && data.map(x => {
        const val = x.split('_');
        if (val && val[0] && val[1]) {

            return parseInt(val[0]);
        }
    });
}