import React, { Fragment } from 'react';
import Script from 'react-load-script'
import { connect } from 'react-redux';
import { withLayout } from '../../common/layout';
import { homeActions } from './home-reducer';
import { HomeResults } from './home-results';
import { HomeResultsMobile } from './home-results-mobile';
import { HomeCheckout } from './home-checkout';
import { HomeCheckoutMobile } from './home-checkout-mobile';
import { Element } from 'react-scroll';
import { PROP_KEYS } from './home-constants';
import { MapComponent } from '../../direction-maps';
import { config } from '../../config';
import { RouteNotFound } from '../../global/route-not-found';
import { Login } from '../../global/login';
import { Signin } from '../../global/signin';
import { ForgotPassword } from '../../global/forgot-password';
import { HomeTransfer } from './home-transfer';
import Desktop from "../../common/desktop-mode";
import Tablet from "../../common/tablet-mode";
import Mobile from "../../common/mobile-mode";
import { userActions } from '../user/user-reducer';
import { Driver } from '../../global/driver';
import CookieConsent from "react-cookie-consent";
import { history } from '../../helpers/history';

import './home.scss';

class HomePage extends React.Component {
    constructor(props) {
        super(props)
        this.handleUnload = this.handleUnload.bind(this);
    }

    componentDidMount() {
        const { home } = this.props;

        if (home) {
            history.push('/');
            window.location.reload();
        }

        window.addEventListener('beforeunload', this.handleUnload);
        window.addEventListener('hashchange', this.handleUnload);

        document.getElementById("initialTripAdvisorWidget").className = "tripAdvisorWidget";
    }

    handleUnload(e) {
        const { logUnfinalized } = this.props;
        e.preventDefault();
        return logUnfinalized();
    }

    routeNotFoundSubmit = () => {
        const { routeNotFoundSubmit } = this.props;

        routeNotFoundSubmit();
    }

    componentWillUpdate() {
        document.getElementById("initialTripAdvisorWidget").className = "tripAdvisorWidget";
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleUnload);
        window.removeEventListener('hashchange', this.handleUnload);

        const { logUnfinalized } = this.props;
        logUnfinalized();

        document.getElementById("initialTripAdvisorWidget").className = "display-none";
    }

    render() {
        const {
            windowHeight,
            windowWidth,
            pickupLocation,
            destination,
            isOpenedResults,
            locale,
            isOpenedCheckout,
            setDistance,
            setDuration,
            routeNotFound,
            setNotFoudName,
            setNotFoudEmail,
            notFoundEmail,
            notFoundName,
            setRouteNotFound,
            login,
            signin,
            edit,
            driver,
            agent,
            match,
            forgotPassword,
            companyLogin,
            personalLogin,
            isLoadedLogin,
            companySignin,
            personalSignin,
            isLoadedSignin,
            resetPassword,
            handleForgotPassword,
            setDriverInfo,
            setDriverWithoutDetailsInfo,
            setAgentInfo,
            setAgentWithoutDetailsInfo,
            isLoadedDriverModal,
            isLoadedAgentModal,
            notFoundIsLoaded,
            distance,
            duration
        } = this.props;
        const params = this.props.location && new URLSearchParams(this.props.location.search);
        const userId = params && params.get('userId');
        const code = params && params.get('codeId');

        return (
            <Fragment>
            <Script 
                url={`https://maps.googleapis.com/maps/api/js?key=${config.googleMapKey}&libraries=places&language=${locale}`}
                onError={_ => { error => console.error('failed to load google maps:', error.stack)}}
                
                >
                
            </Script>
            <Fragment>
            <CookieConsent location="bottom"
                buttonText="ACCEPT"
                cookieName="wazUpCookie"
                hideOnAccept={true}
                acceptOnScroll={true}
                style={{ background: "#393644", color: "#fff", fontSize: "15px" }}
                buttonStyle={{ background: '#4E31B5', color: "#fff", fontSize: "13px" }}>
                This website uses cookies to improve your experience. We'll assume you're ok with this, but you can opt-out if you wish.
            </CookieConsent>
            {
                routeNotFound && (
                    <RouteNotFound onCancel={() => setRouteNotFound(false)}
                        setNotFoudName={setNotFoudName}
                        setNotFoudEmail={setNotFoudEmail}
                        routeNotFoundSubmit={this.routeNotFoundSubmit}
                        notFoundEmail={notFoundEmail}
                        notFoundName={notFoundName}
                        isLoaded={notFoundIsLoaded} />
                )
            }
            {login && (<Login handleLoginByBookingNumber={personalLogin} handleLogin={companyLogin} isLoadedLogin={isLoadedLogin} handleForgotPassword={handleForgotPassword} />)}
            {signin && (<Signin handlePersonalSignIn={personalSignin} handleCompanySignIn={companySignin} isLoadedSignin={isLoadedSignin} />)}
            {driver && match && match.params && match.params.token && (<Driver handleSubmit={setDriverInfo} handleSubmitWithoutDetails={setDriverWithoutDetailsInfo} isLoaded={isLoadedDriverModal} token={match.params.token} />)}
            {agent && match && match.params && match.params.token && (<Driver isAgent={true} handleSubmit={setAgentInfo} handleSubmitWithoutDetails={setAgentWithoutDetailsInfo} isLoaded={isLoadedAgentModal} token={match.params.token} />)}
            {forgotPassword && (<ForgotPassword handleResetPassword={resetPassword} {...{ userId, code }} />)}
            
            <Element name="ride_details_page" className="element ride-details-page">
                <Desktop>
                    <div style={{ height: windowHeight }} className="transfer-component d-flex justify-content-center flex-column">
                        <HomeTransfer edit={edit} />
                    </div>
                </Desktop>
                <Tablet>
                    <div style={{ height: windowHeight }} className="transfer-component d-flex justify-content-center flex-column">
                        <HomeTransfer edit={edit} />
                    </div>
                </Tablet>
                <Mobile>
                    <div className="transfer-component d-flex justify-content-center flex-column">
                        <HomeTransfer edit={edit} />
                    </div>
                </Mobile>
                {
                    (pickupLocation || destination) && (
                        <MapComponent
                            center={pickupLocation || destination}
                            pickupLocation={pickupLocation}
                            destination={destination}
                            distance={distance}
                            duration={duration}
                            setDistance={setDistance}
                            setDuration={setDuration}
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.googleMapKey}&libraries=geometry,drawing,places&language=${locale}`}
                            loadingElement={<div style={{ height: windowHeight }} />}
                            containerElement={<div className="map-background" style={{ height: 0 }} />}
                            mapElement={<div className="map-element" style={{ height: 0, maxHeight: windowHeight, maxWidth: windowWidth }} />}
                        />
                    )
                }
                
            </Element>
            <Element style={{ display: isOpenedResults ? 'flex' : 'none' }}
                name="results_page"
                className="element">
                <Desktop><HomeResults /></Desktop>
                <Tablet><HomeResults /></Tablet>
                <Mobile><HomeResultsMobile /></Mobile>
            </Element>
            <Element style={{ display: isOpenedCheckout ? 'flex' : 'none', minHeight: windowHeight, height: '100%' }}
                name="checkout_page"
                className="element">
                <Desktop><HomeCheckout edit={edit} /></Desktop>
                <Tablet><HomeCheckout edit={edit} /></Tablet>
                <Mobile><HomeCheckoutMobile edit={edit} /></Mobile>
            </Element>
        </Fragment>
        </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { location, i18n: { locale }, authentication: { isLoadedLogin, isLoadedSignin } } = state;

    return {
        ...location,
        locale,
        isLoadedLogin,
        isLoadedSignin
    };
}

const mapDispatchToProps = {
    setLoadedResults: (value) => homeActions.setValue(PROP_KEYS.isLoadedResults, value),
    setDistance: (value) => homeActions.setValue(PROP_KEYS.distance, value),
    setDuration: (value) => homeActions.setValue(PROP_KEYS.duration, value),
    setNotFoudName: (value) => homeActions.setValue(PROP_KEYS.notFoundName, value),
    setNotFoudEmail: (value) => homeActions.setValue(PROP_KEYS.notFoundEmail, value),
    routeNotFoundSubmit: () => homeActions.routeNotFoundSubmit(),
    setRouteNotFound: (value) => homeActions.setValue(PROP_KEYS.routeNotFound, value),
    companyLogin: (userName, password) => userActions.companyLogin(userName, password),
    personalLogin: (userName, bookingNumber) => userActions.personalLogin(userName, bookingNumber),
    companySignin: (companyInfo) => userActions.companySignin(companyInfo),
    personalSignin: (userInfo) => userActions.personalSignin(userInfo),
    resetPassword: (info) => userActions.resetPassword(info),
    handleForgotPassword: (email) => userActions.forgotPassword(email),
    setDriverInfo: (driverInfo) => homeActions.setDriverInfo(driverInfo),
    setDriverWithoutDetailsInfo: (token) => homeActions.setDriverWithoutDetailsInfo(token),
    setAgentInfo: (driverInfo) => homeActions.setDriverInfo(driverInfo, true),
    setAgentWithoutDetailsInfo: (token) => homeActions.setDriverWithoutDetailsInfo(token, true),
    loadLocations: () => homeActions.loadLocations(),
    clear: () => homeActions.clear(),
    logUnfinalized: () => homeActions.logUnfinalized()
}

const connectedHomePage = connect(mapStateToProps, mapDispatchToProps)(withLayout(HomePage));
export { connectedHomePage as HomePage };