import React from 'react';
import { connect } from 'react-redux';
import { withAdminLayout } from '../../common/admin-layout';
import { ADMIN_PAGES } from '../../assets/constants/global';
import { adminActions } from './admin-reducer';
import { BaseAdmin } from './base';
import { PROP_KEYS, ACTION_LOAD_TYPES, TEMPLATE_TYPES, ACTION_IMPORT_TYPES } from './admin-constants';

class AdminRoutes extends React.Component {
    componentDidMount() {
        const { loadData } = this.props;

        loadData();
    }

    render() {
        const { 
            routes, routesErrorMsg, handleDataPageChange, handleDataPageSizeChange,
            routesPagination, isLoadedRoutes, importData, exportData,
            setImportedData, downloadTemplate, cleanDataErrorMsg, handleSort,
            routeColumnTypes
        } = this.props;
        const baseProps = {
            data: routes,
            isLoaded: isLoadedRoutes,
            errorMsg: routesErrorMsg,
            handleImportData: importData,
            handleExportData: exportData,
            setImportedData: setImportedData,
            downloadTemplate: downloadTemplate,
            pagination: routesPagination,
            handlePageChange: handleDataPageChange,
            handlePageSizeChange: handleDataPageSizeChange,
            cleanErrorMsg: cleanDataErrorMsg,
            sortData: handleSort,
            columnTypes: routeColumnTypes
        };

        return (
            <BaseAdmin {...baseProps} />
        );
    }
}

const mapStateToProps = (state) => {
    const { admin } = state;
    const { routes, routesPagination, isLoadedRoutes, routesErrorMsg, routeColumnTypes } = admin;

    return {
        routes, isLoadedRoutes, routesPagination, routesErrorMsg, routeColumnTypes
    };
}

const { action, isLoadedKey, paginationKey, dataKey, importAction, importedKey, errorMsgKey, fileKey, columnTypesKey } = {
    action: ACTION_LOAD_TYPES.route,
    isLoadedKey: PROP_KEYS.isLoadedRoutes,
    paginationKey: PROP_KEYS.routesPagination,
    dataKey: PROP_KEYS.routes,
    importAction: ACTION_IMPORT_TYPES.route,
    importedKey: PROP_KEYS.importedRoutes,
    errorMsgKey: PROP_KEYS.routesErrorMsg,
    fileKey: TEMPLATE_TYPES.Route,
    columnTypesKey: PROP_KEYS.routeColumnTypes
}

const mapDispatchToProps = {
    loadData: () => adminActions.load({ 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    importData: () => adminActions.importData({
        importAction, action, isLoadedKey, importedKey, errorMsgKey, paginationKey, dataKey
    }),
    exportData: () => adminActions.exportData({ 
        action, isLoadedKey, fileKey
    }),
    setImportedData: (file) => adminActions.setValue(importedKey, file),
    cleanDataErrorMsg: () => adminActions.setValue(errorMsgKey, []),
    downloadTemplate: () => adminActions.downloadTemplate({
        isLoadedKey, fileKey
    }),
    handleDataPageChange: (page, pageSize) => adminActions.handlePaginationChange(page, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleDataPageSizeChange: (_page, pageSize) => adminActions.handlePaginationChange(1, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleSort: (_pagination, _filters, sorter) => adminActions.handleSort(1, sorter, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    })
}

const connectedAdminRoutes = connect(mapStateToProps, mapDispatchToProps)(withAdminLayout(AdminRoutes, ADMIN_PAGES.AdminRoutes));
export { connectedAdminRoutes as AdminRoutes };