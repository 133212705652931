import React, { Fragment } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import marker from '../assets/icons/marker.png';
import destinationMarker from '../assets/icons/destination-marker.svg';
import { MapDirection } from './direction';

import './direction-maps.scss';

const styles = require('./direction-maps.json');

export const MapComponent = withScriptjs(withGoogleMap((props) => {
    const { pickupLocation, destination, center: defaultCenter, setDistance = () => ({}), setDuration = () => ({}), isTripDetails = false } = props;
    const center = (pickupLocation && destination) 
                 ? {
                        lat: (pickupLocation.lat + destination.lat) / 2,
                        lng: (pickupLocation.lng + destination.lng) / 2
                   } 
                 : pickupLocation ? pickupLocation : destination;
    const dist = pickupLocation && 
                 destination &&
                 Math.sqrt(
                     Math.abs(pickupLocation.lat - destination.lat) + 
                     Math.abs(pickupLocation.lng - destination.lng)
                 );
    const zoom = calculateZoom(dist);
    const tripZoom = isTripDetails ? zoom * 0.75 : zoom;

    return (
        <Fragment>
            <GoogleMap defaultZoom={12}
                       defaultCenter={defaultCenter}
                       center={center}
                       zoom={tripZoom}
                       defaultOptions={{
                           disableDefaultUI: true,
                           draggable: false,
                           keyboardShortcuts: false,
                           scaleControl: false,
                           scrollwheel: false,
                           styles: styles
                         }}>
                {
                    pickupLocation && <Marker position={pickupLocation} icon={{
                        url: marker,
                        scaledSize: { width: 36, height: 36 },
                        anchor: { x: 18, y: 18 },
                    }} />
                }
                {
                    destination && <Marker position={destination} icon={{
                        url: destinationMarker,
                        scaledSize: { width: 56, height: 56 },
                        anchor: { x: 24, y: 37 },
                    }} />
                }
                {
                    pickupLocation && destination && (
                        <MapDirection {...{ origin: pickupLocation, destination, setDistance, setDuration }} />
                    )
                }
            </GoogleMap>
            <div className="distance-duration-label" style={{ display: props.distance && props.duration ? 'block' : 'none' }}>
                <div className="distance-duration-label-content">
                    <span>{props.distance}, </span>
                    <span className="duration-text">{props.duration}</span>
                </div>
            </div>
        </Fragment>
    );
}));

const calculateZoom = (distance) => {
    if(!distance) return 10;
    if(distance > 8) return 3;
    if(distance > 5.5) return 4;
    if(distance > 3.5) return 5;
    if(distance > 2.5) return 6;
    if(distance > 1.5) return 7;
    if(distance > 1) return 9;
    if(distance > 0.5) return 10;
    return 12;
}