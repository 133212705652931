import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Dropdown, Menu } from 'antd';
import close_icon from '../assets/icons/close.svg';
import { STRING_EMPTY } from '../assets/constants/global';

const { Item } = Menu;

const InputState = {
    empty: 'empty',
    active: 'active',
    filled: 'filled'
}

export class LocationInput extends React.Component {
    constructor(props) {
        super(props);
        const { value, stringValue } = props;

        this.state = {
            stringValue,
            value: value || STRING_EMPTY,
            inputState: value ?  InputState.filled : InputState.empty
        }
    }

    setInputState = (newState) => {
        this.setState({ 
            inputState: newState 
        });
    }

    setValue = (newValue, callback) => {
        this.setState({
            stringValue: newValue,
            value: newValue
        }, callback);
    }

    clearValue = () => {
        const { onChange } = this.props;

        this.setValue(STRING_EMPTY, () => { this.setInputState(InputState.empty); onChange(null); });
    }

    handleChange = stringValue => {
        if (stringValue) {
            this.setState({ stringValue });
        } else {
            this.clearValue();
        }
    };
    
    handleSelect = address => {
        const { onChange, onChangeLocationObject, onChangeAddress } = this.props;

        this.setState({
            stringValue: address
        }, () => {
            onChangeAddress(address);
            geocodeByAddress(address)
                      .then(results => {
                          onChangeLocationObject(results[0]);
                          
                          return getLatLng(results[0]);
                      })
                      .then(latLng => onChange(latLng))
                      .catch(error => console.error('Error', error))
                      .finally(() => this.setInputState(address ? InputState.filled : InputState.empty));
        });
    };

    renderBasedOnInputState =_=> {
        const { inputState, value, stringValue } = this.state;
        const { placeholder } = this.props;

        return {
            [InputState.empty]: (
                <div className={inputState}>
                    <span className="placeholderValue" onClick={() => this.setInputState(InputState.active)}>{stringValue || placeholder}</span>
                </div>
            ),
            [InputState.active]: (
                <div className={`${inputState} d-flex flex-column`}>
                    <PlacesAutocomplete value={stringValue || value}
                                        onChange={this.handleChange}
                                        onSelect={this.handleSelect} >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                            const overlayItems = loading ? [{ description: 'Loading...' }]
                                               : suggestions ? suggestions : [];

                            const overlayDropdown = (
                                <Menu>
                                    {
                                        overlayItems.map((suggestion, index) => (
                                            <Item {...getSuggestionItemProps(suggestion)} key={`suggestion_${index}`}>
                                                {suggestion.description}
                                            </Item>
                                      ))
                                    }
                                </Menu>
                            );

                            return (
                                <Dropdown overlay={overlayDropdown}
                                          getPopupContainer={trigger => trigger.parentNode}
                                          placement="bottomCenter"
                                          visible={overlayItems.length > 0}>
                                    <div>
                                        <span>{placeholder}</span>
                                        <input {...getInputProps()} autoFocus value={stringValue || value} 
                                                onBlur={() => this.setInputState(value ? InputState.filled : InputState.empty)} />
                                    </div>
                                </Dropdown>
                            )
                        }}
                    </PlacesAutocomplete>
                </div>
            ),
            [InputState.filled]: (
                <div className={`${inputState} d-flex w-100`}>
                    <div className="d-flex flex-column">
                        <span>{placeholder}</span>
                        <span className="filledValue" onClick={() => this.setInputState(InputState.active)}>{stringValue || placeholder}</span>
                    </div>
                    <img src={close_icon}
                         alt="Delete input value"
                         onClick={this.clearValue}/>
                </div>
            )
        }[inputState];
    }
 
    render() {
        return (
            <div className="w-100 location-input">
                {this.renderBasedOnInputState()}
            </div>
        )
    }
}