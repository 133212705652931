import React from 'react';
import { connect } from 'react-redux';
import { withAdminLayout } from '../../common/admin-layout';
import { ADMIN_PAGES } from '../../assets/constants/global';
import { adminActions } from './admin-reducer';
import { BaseAdmin } from './base';
import { PROP_KEYS, ACTION_LOAD_TYPES, TEMPLATE_TYPES, ACTION_IMPORT_TYPES } from './admin-constants';

class AdminVehicles extends React.Component {
    componentDidMount() {
        const { loadData } = this.props;

        loadData();
    }

    render() {
        const { 
            vehicles, vehiclesErrorMsg, handleDataPageChange, handleDataPageSizeChange,
            vehiclesPagination, isLoadedVehicles, importData, exportData,
            setImportedData, downloadTemplate, cleanDataErrorMsg, handleSort,
            vehicleColumnTypes
        } = this.props;
        const baseProps = {
            data: vehicles,
            isLoaded: isLoadedVehicles,
            errorMsg: vehiclesErrorMsg,
            handleImportData: importData,
            handleExportData: exportData,
            setImportedData: setImportedData,
            downloadTemplate: downloadTemplate,
            pagination: vehiclesPagination,
            handlePageChange: handleDataPageChange,
            handlePageSizeChange: handleDataPageSizeChange,
            cleanErrorMsg: cleanDataErrorMsg,
            sortData: handleSort,
            columnTypes: vehicleColumnTypes
        };

        return (
            <BaseAdmin {...baseProps} />
        );
    }
}

const mapStateToProps = (state) => {
    const { admin } = state;
    const { vehicles, vehiclesPagination, isLoadedVehicles, vehiclesErrorMsg, vehicleColumnTypes } = admin;

    return {
        vehicles, isLoadedVehicles, vehiclesPagination, vehiclesErrorMsg, vehicleColumnTypes
    };
}

const { action, isLoadedKey, paginationKey, dataKey, importAction, importedKey, errorMsgKey, fileKey, columnTypesKey } = {
    action: ACTION_LOAD_TYPES.vehicle,
    isLoadedKey: PROP_KEYS.isLoadedVehicles,
    paginationKey: PROP_KEYS.vehiclesPagination,
    dataKey: PROP_KEYS.vehicles,
    importAction: ACTION_IMPORT_TYPES.vehicle,
    importedKey: PROP_KEYS.importedVehicles,
    errorMsgKey: PROP_KEYS.vehiclesErrorMsg,
    fileKey: TEMPLATE_TYPES.Vehicle,
    columnTypesKey: PROP_KEYS.vehicleColumnTypes
}

const mapDispatchToProps = {
    loadData: () => adminActions.load({ 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    importData: () => adminActions.importData({
        importAction, action, isLoadedKey, importedKey, errorMsgKey, paginationKey, dataKey
    }),
    exportData: () => adminActions.exportData({ 
        action, isLoadedKey, fileKey
    }),
    setImportedData: (file) => adminActions.setValue(importedKey, file),
    cleanDataErrorMsg: () => adminActions.setValue(errorMsgKey, []),
    downloadTemplate: () => adminActions.downloadTemplate({
        isLoadedKey, fileKey
    }),
    handleDataPageChange: (page, pageSize) => adminActions.handlePaginationChange(page, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleDataPageSizeChange: (_page, pageSize) => adminActions.handlePaginationChange(1, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleSort: (_pagination, _filters, sorter) => adminActions.handleSort(1, sorter, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    })
}

const connectedAdminVehicles = connect(mapStateToProps, mapDispatchToProps)(withAdminLayout(AdminVehicles, ADMIN_PAGES.AdminVehicles));
export { connectedAdminVehicles as AdminVehicles };