import React from "react";
import checked from '../assets/icons/checked.svg';
import { I18n as Translater } from 'react-redux-i18n';
import validator from 'validator';
import { USER_INPUT_TYPES, STRING_EMPTY } from '../assets/constants/global';

export class UserInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || STRING_EMPTY,
            validationMessage: STRING_EMPTY,
        }
    }

    getValidationMessage = (value) => {
        const { type } = this.props;
        const phoneRegexp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/;

        return {
            [USER_INPUT_TYPES.email]: !validator.isEmail(value) ? Translater.t('invalidEmailMsg') : STRING_EMPTY,
            [USER_INPUT_TYPES.phone]: !(validator.matches(value, phoneRegexp) && validator.isLength(value, { max: 20 })) ?  Translater.t('invalidPhoneMsg') : STRING_EMPTY,
            [USER_INPUT_TYPES.number]: !(validator.isNumeric(value) && validator.isLength(value, { min: 1 })) ?  Translater.t('invalidNumberMsg') : STRING_EMPTY,
            [USER_INPUT_TYPES.string]: !validator.isLength(value, { min: 2 }) ? Translater.t('invalidStringMsg') : STRING_EMPTY,
            [USER_INPUT_TYPES.promo]: !validator.isLength(value, { min: 5, max: 10 }) ? Translater.t('invalidPromoCodeMsg') : STRING_EMPTY,
            [USER_INPUT_TYPES.flightNumber]: !validator.isLength(value, { min: 4 }) ? Translater.t('invalidFlightNumberMsg') : STRING_EMPTY,
            [USER_INPUT_TYPES.password]: !validator.isLength(value, { min: 1 }) ? Translater.t('requiredPasswordMsg') : STRING_EMPTY,
            [USER_INPUT_TYPES.password]: this.props.passwordToMatch && !validator.equals(value, this.props.passwordToMatch) ? Translater.t('passwordShouldMatchhMsg') : STRING_EMPTY,
        }[type];
    }

    setValue = (newValue) => {
        const { validate = true, onChange } = this.props;
        const validationMsg = this.getValidationMessage(newValue);

        this.setState({
            value: newValue,
            validationMessage: validate ? validationMsg : STRING_EMPTY
        }, () => onChange(validationMsg === STRING_EMPTY ? newValue : STRING_EMPTY)); 
    }

    clearInput = () => {
        this.setState({
            value: STRING_EMPTY
          });
    }

    saveValue = () => {
        const { onChange } = this.props;
        const { value, validationMessage } = this.state;

        if (!validationMessage) {
            onChange(value);
        }
    }

    renderBasedOnInputState =_=> {
        const { value, validationMessage } = this.state;
        const { placeholder, required = false, optional = required, type, autoFocus = false, disabled = false } = this.props;
        const placeholderValue = optional ? `${placeholder} (${Translater.t('optionalTitle')})` : placeholder;

        return (
            <div className={`active d-flex flex-column`}>
                <div className="d-flex justify-content-between">
                    <div className='d-flex w-100'>{placeholderValue}</div>
                    { !validationMessage && value && (<img src={checked} alt="Added value" />) }
                </div>
                <input autoFocus={autoFocus}
                       type={type}
                       value={value}
                       onBlur={this.saveValue}
                       onChange={(event) => this.setValue(event.target.value)}
                       onKeyDown={(event) => event.key === 'Enter' && this.saveValue()} 
                       disabled={disabled} />
                {
                    validationMessage && (<span style={{color: 'red'}}>{validationMessage}</span>)
                }
            </div>
        )
    }
 
    render() {
        const { containerClassName } = this.props;

        return (
            <div className={`w-100 user-input ${containerClassName}`}>
                {this.renderBasedOnInputState()}
            </div>
        )
    }
}