import React from 'react';
import { withLayout } from '../../common/layout';
import { connect } from 'react-redux';
import I18n from '../../i18n';
import { Modal } from 'antd';
import cancelImg from '../../assets/icons/cancel_payment.svg';
import { Link } from 'react-router-i18n';
import { homeActions } from '../home/home-reducer';

class CancelPayment extends React.Component {
    render() {
        const { match, returnToCheckout, returnToEditCheckout, edit } = this.props;

        return (
            <div id='cancel-modal'>
                <Modal title="Basic Modal"
                       visible={true}
                       centered={true}
                       footer={null}
                       maskClosable={false}
                       getContainer={() => document.getElementById('cancel-modal')}>
                    <div className="d-flex flex-column align-items-center">
                        <img className="m-3" src={cancelImg} alt='cancel-modal' />
                        <span className="payment-title mt-3 mb-3"><I18n t="paymentCancelMsg" /></span>
                        <div className="d-flex">
                            <Link to="/">
                                <button className="payment-button mt-3">
                                    <I18n t="closeTitle" />
                                </button>
                            </Link>
                            {
                                match && match.params && match.params.sessionId && (
                                    <button onClick={() => edit ? returnToEditCheckout(match.params.sessionId) : returnToCheckout(match.params.sessionId)} className="payment-button return mt-3 ml-3">
                                        <I18n t="returnToCheckoutTitle" />
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = {
    returnToCheckout: (value) => homeActions.returnToCheckout(value),
    returnToEditCheckout: (value) => homeActions.returnToCheckout(value, true)
};

const connectedCancelPayment = connect(() => ({}), mapDispatchToProps)(withLayout(CancelPayment));
export { connectedCancelPayment as CancelPayment };