import React from 'react';
import I18n from '../i18n';
import { I18n as Translater } from 'react-redux-i18n';
import { USER_INPUT_TYPES } from '../assets/constants/global';
import { UserInput } from './user-input';

export const Invoice = (props) => {
    const { userEmail, userPassword, handleSubmit, setEmail, setPassword } = props;

    return (
        <div className='invoice-form'>
            <div className="row">
                <div className="col-md-6 col-12">
                    <UserInput optional={false} placeholder={Translater.t('emailTitle')} containerClassName='mr-2' required={true} type={USER_INPUT_TYPES.email} onChange={setEmail} value={userEmail} />
                </div>
                <div className="col-md-6 col-12">
                    <UserInput optional={false} placeholder={Translater.t('passwordTitle')} required={true} type={USER_INPUT_TYPES.password} onChange={setPassword} value={userPassword} />
                </div>
            </div>
            <button onClick={handleSubmit} disabled={!(userEmail && userPassword)}><I18n t="continueToPaymentTitle" /></button>
        </div>
    );
}