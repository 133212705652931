const translations = {
    en: {
        helloTitle: 'Hello',
        logoutTitle: 'Log out',
        loginTitle: 'Login',
        usernameTitle: 'User name',
        usernameErrorMsg: 'Username is required',
        passwordTitle: 'Password',
        passwordErrorMsg: 'Password is required',
        pageTitles: {
            about: 'About',
            faq: 'FAQ',
            faqFullTitle: 'Frequently Asked Questions',
            partners: 'Partners',
            privacyAndTerms: 'Privacy & Terms',
            rideDetails: 'Ride details',
            results: 'Results',
            checkout: 'Checkout',
            home: 'Home'
        },
        bookTransferTitle: 'Book a transfer now',
        pickupLocationPlaceholder: 'Enter pickup location',
        destinationPlaceholder: 'Enter destination',
        returnTransfer: 'Return transfer',
        hourlyBooking: 'Hourly booking',
        searchTitle: 'Search',
        timeTitle: 'Time',
        hourTitle: 'Hour',
        hoursTitle: 'Hours',
        backToAddressTitle: 'Back to Address',
        backToCarsTitle: 'Back to Cars',
        freeCancellation: 'Free cancellation',
        freeWaitingMinutes: 'min. of free-waiting',
        meetingWithATablet: 'Meeting with a tablet',
        meetingWithAFood: 'Meeting with a food',
        recommendedTitle: 'Recommended',
        selectTitle: 'Select',
        selectACarTitle: 'Okay, let’s select a car',
        fillDetailsTitle: 'Fill in your details',
        additionalOptionsTitle: 'Additional options',
        freeTitle: 'Free',
        addTitle: 'Add',
        getTitle: 'Get',
        addedTitle: 'Added',
        removeTitle: 'Remove',
        maxCountMsg: 'You added max count of this option',
        minCountMsg: 'You added min count of this option',
        emailTitle: 'E-mail',
        phoneTitle: 'Phone',
        nameTitle: 'Name',
        fullNameTitle: 'Full name',
        commentTitle: 'Comment',
        optionalTitle: 'Optional',
        detailsAndSummaryTitle: 'Details and summary',
        invalidEmailMsg: 'Your value is not a valid email',
        invalidPhoneMsg: 'Your value is not a valid phone number',
        invalidNumberMsg: 'Your value is not a valid number',
        invalidStringMsg: 'Your value must be greater than 2',
        requiredPasswordMsg: 'Password is required',
        passwordShouldMatchhMsg: 'Passwords should match',
        pickupLocationTitle: 'Pickup location',
        destinationTitle: 'Destination',
        distanceTitle: 'Distance',
        dateTitle: 'Date',
        carTitle: 'Car',
        classTitle: 'Class',
        passengersTitle: 'Passengers',
        baggageTitle: 'Baggage',
        passageTitle: 'Passage',
        totalTitle: 'Total',
        maxTitle: 'Max',
        returnDateTitle: 'Return date',
        returnTimeTitle: 'Return time',
        choosePaymentMethodTitle: 'Choose payment method',
        iHavePromoCodeTitle: 'I have a promo code',
        enterYourCodeTitle: 'Enter your code',
        invalidPromoCodeMsg: 'Promo code is not valid',
        creditCardTitle: 'Credit card',
        invoiceTitle: 'Invoice',
        onlyRegisteredUsersMsg: 'only for registered users',
        cardNumberTitle: 'Card number',
        expirationDateTitle: 'Expiration date',
        cvcTitle: 'CVC',
        continueToPaymentTitle: 'Continue to payment',
        paymentSuccessMsg: 'Congratulations, we are pleased to confirm your reservation.',
        paymentSuccessSubMsg: 'Please check your email for your booking instructions. Book your return trip now using code WEB10 and get a 10% discount.',
        paymentCancelMsg: 'Sorry, your payment failed. Please check your details.',
        closeTitle: 'Close',
        routeNotFoundTitle: 'Route Not Found',
        routeNotFoundMsg: 'We are sorry, we could not find the route you requested. Please pass your name and email. We will contact you soon.',
        routeNotFoundForLoginedUserMsg: 'We are sorry, we could not find the route you requested. We will contact you soon.',
        submitTitle: 'Submit',
        returnToCheckoutTitle: 'Return to Checkout',
        promoDiscountTitle: 'Promocode discount',
        discountsTitle: 'Discounts',
        myOrdersTitle: 'My Orders',
        profileDetailsTitle: 'Profile Details',
        transactionHistoryTitle: 'Transaction History',
        contactDetailsTitle: 'Contact details',
        changePasswordTitle: 'Change Password',
        driverInfoTitle: 'Driver / Agent Info',
        agentInfoTitle: 'Agent Info',
        driverInfTitle: 'Driver Info',
        carDetailsTitle: 'Car Details',
        paymentTitle: 'Payment',
        upcomingTitle: 'Upcoming',
        pastTitle: 'Past',
        fromAtoBTitle: 'From a to b',
        showDetailsTitle: 'Show details',
        driverTitle: 'Driver',
        agentTitle: 'Agent',
        passengerTitle: 'Passenger',
        deleteTripMsg: 'Are you sure cancel this trip?',
        editTripMsg: 'Are you sure edit this trip?',
        yesTitle: 'Yes',
        noTitle: 'No',
        byBookingNumberTitle: 'By booking number',
        byPasswordTitle: 'By password',
        accountHoldersTitle: 'Account holders',
        bookingNumberPlaceholder: 'Booking number (Check e-mail)',
        doNotHaveAccountTitle: 'Do not have account?',
        getFreeHereTitle: 'GET FREE here',
        forgotTitle: 'Forgot?',
        createAnAccountTitle: 'Create an account',
        personalAccountTitle: 'Personal account',
        companyTitle: 'Company',
        alreadyHaveAnAccountTitle: 'Already have an account?',
        createTitle: 'Create',
        companyNameTitle: 'Company Name',
        passwordConfirmTitle: 'Password Confirm',
        successSignInMsg: 'You have signed up successfully.',
        successSignInSubMsg: 'Please check your email for activation',
        resetPasswordTitle: 'Reset your password',
        oldPassword: 'Old password',
        newPassword: 'New password',
        repeatNewPassword: 'Repeat new password',
        changeTitle: 'Change',
        saveTitle: 'Save',
        resetTitle: 'Reset',
        thankForRegistering: 'Thank you for your registering!',
        thankForResendPass: 'Thanks, check your email for instructions to reset your password.',
        thankForResendPassSub: 'Did not get the email? Check your spam folder or resend.',
        locationsTitle: 'Locations',
        vipTitle: 'Vip',
        affiliatesTitle: 'Affiliates',
        affiliateSettlementsTitle: 'Affiliate Settlement',
        vehiclesTitle: 'Vehicles',
        extraPricesTitle: 'Extra prices',
        routesTitle: 'Routes',
        notFoundRoutesTitle: 'Not Found Routes',
        adminPanelTitle: 'Admin Panel',
        paymentRulesTitle: 'Payment Rules',
        tripsTitle: 'Trips',
        tripSettlements: 'Trip Settlement',
        unfinalizedTripsTitle: 'Unfinalized Trips',
        accountsTitle: 'Accounts',
        addUserTitle: 'Add new one',
        dispatchTitle: 'Dispatch',
        driverFullNameTitle: 'Driver Name',
        agentFullNameTitle: 'Agent Name',
        driverPhoneTitle: 'Driver Phone',
        agentPhoneTitle: 'Agent Phone',
        invalidFlightNumberMsg: 'Flight number is not valid',
        flightNumberTitle: 'Flight Number',
        returnFlightNumberTitle: 'Return Flight Number',
    },
    he: {
        helloTitle: 'שלום',
        logoutTitle: 'להתנתק',
        loginTitle: 'התחברות',
        usernameTitle: 'שם משתמש',
        usernameErrorMsg: 'נדרש שם משתמש',
        passwordTitle: 'סיסמה',
        passwordErrorMsg: 'דרושה סיסמא',
        pageTitles: {
            about: 'על אודות',
            faq: 'שאלות נפוצות',
            faqFullTitle: 'שאלות נפוצות',
            partners: 'שותפים',
            privacyAndTerms: 'פרטיות ותנאים',
            rideDetails: 'לרכוב על הפרטים',
            results: 'תוצאות',
            checkout: 'לבדוק',
            home: 'בית'
        },
        bookTransferTitle: 'הזמן העברה עכשיו',
        pickupLocationPlaceholder: 'הזן מיקום איסוף',
        destinationPlaceholder: 'הזן יעד',
        returnTransfer: 'העברה חזרה',
        hourlyBooking: 'הזמנה לפי שעה',
        searchTitle: 'לחפש',
        timeTitle: 'זמן',
        hourTitle: 'שעה',
        hoursTitle: 'שעה (ות',
        backToAddressTitle: 'חזרה לכתובת',
        backToCarsTitle: 'חזרה למכוניות',
        freeCancellation: 'ביטול חופשי',
        freeWaitingMinutes: 'דקות של המתנה חופשית',
        meetingWithATablet: 'מפגש עם טאבלט',
        meetingWithAFood: 'מפגש עם אוכל',
        recommendedTitle: 'מומלץ',
        selectTitle: 'בחר',
        selectACarTitle: 'אוקיי, בואו נבחר מכונית',
        fillDetailsTitle: 'מלא את פרטיך',
        additionalOptionsTitle: 'אפשרויות נוספות',
        freeTitle: 'חינם',
        addTitle: 'הוסף',
        getTitle: 'לקבל',
        addedTitle: 'נוסף',
        removeTitle: 'הסר',
        maxCountMsg: 'הוספת ספירה מרבית של אפשרות זו',
        minCountMsg: 'הוספת מספר דקות של אפשרות זו',
        emailTitle: 'אימייל',
        phoneTitle: 'טלפון',
        nameTitle: 'שם',
        fullNameTitle: 'שם',
        commentTitle: 'תגובה',
        optionalTitle: 'אפשרויותl',
        detailsAndSummaryTitle: 'פרטים וסיכום',
        invalidEmailMsg: 'הערך שלך אינו דוא"ל חוקי',
        invalidPhoneMsg: 'הערך שלך אינו מספר טלפון חוקי',
        invalidNumberMsg: 'הערך שלך אינו מספר חוקי',
        invalidStringMsg: 'הערך שלך חייב להיות גדול מ- 2',
        requiredPasswordMsg: 'דרושה סיסמא',
        passwordShouldMatchhMsg: 'סיסמאות צריכות להתאים',
        pickupLocationTitle: 'בחר מיקום',
        destinationTitle: 'יעד',
        distanceTitle: 'מרחק',
        dateTitle: 'תאריך',
        carTitle: 'אוטו',
        classTitle: 'מעמד',
        passengersTitle: 'נוסעים',
        baggageTitle: 'כבודה',
        passageTitle: 'מעבר',
        totalTitle: 'סך הכל',
        maxTitle: 'מקסימום',
        returnDateTitle: 'תאריך חזרה',
        returnTimeTitle: 'זמן החזרה',
        choosePaymentMethodTitle: 'בחר אמצעי תשלום',
        iHavePromoCodeTitle: 'יש לי קוד פרומו',
        enterYourCodeTitle: 'הכנס את הקוד שלך',
        invalidPromoCodeMsg: 'קוד הפרומו אינו תקף',
        creditCardTitle: 'כרטיס אשראי',
        invoiceTitle: 'חשבונית מס',
        onlyRegisteredUsersMsg: 'רק למשתמשים רשומים',
        cardNumberTitle: 'מספר כרטיס',
        expirationDateTitle: 'תאריך תפוגה',
        cvcTitle: 'CVC',
        continueToPaymentTitle: 'המשך לתשלום',
        paymentSuccessMsg: 'מזל טוב, אנו שמחים לאשר את הזמנתך.',
        paymentSuccessSubMsg: 'אנא בדוק בדוא"ל שלך את הוראות ההזמנה שלך. הזמן את נסיעתך חזרה באמצעות קוד WEB10 וקבל הנחה של 10%.',
        paymentCancelMsg: 'מצטערים, התשלום שלך נכשל. אנא בדוק את הפרטים שלך.',
        closeTitle: 'סגור',
        routeNotFoundTitle: 'מסלול לא נמצא',
        routeNotFoundMsg: 'אנו מצטערים, לא מצאנו את המסלול שביקשת. אנא העבירו את שמכם ודוא"לכם אנו ניצור איתך קשר בקרוב.',
        routeNotFoundForLoginedUserMsg: 'אנו מצטערים, לא מצאנו את המסלול שביקשת. אנו ניצור איתך קשר בקרוב.',
        submitTitle: 'שלח',
        returnToCheckoutTitle: 'חזור לקופה',
        promoDiscountTitle: 'קדם-הנחה',
        discountsTitle: 'הנחות',
        myOrdersTitle: 'ההזמנות שלי',
        profileDetailsTitle: 'פרטי פרופיל',
        transactionHistoryTitle: 'היסטוריית עסקאות',
        contactDetailsTitle: 'פרטי התקשרות',
        changePasswordTitle: 'שנה סיסמא',
        driverInfoTitle: 'פרטי הנהג',
        agentInfoTitle: 'פרטי סוכן',
        driverInfTitle: 'פרטי הנהג',
        carDetailsTitle: 'פרטי רכב',
        paymentTitle: 'תשלום',
        upcomingTitle: 'מתקרב',
        pastTitle: 'עבר',
        fromAtoBTitle: 'מאי ל ב',
        showDetailsTitle: 'הראה פרטים',
        driverTitle: 'נהג',
        agentTitle: 'סוֹכֵן',
        passengerTitle: 'נוסע',
        deleteTripMsg: 'האם אתה בטוח לבטל את הטיול הזה?',
        editTripMsg: 'האם אתה בטוח לערוך טיול זה?',
        yesTitle: 'כן',
        noTitle: 'לא',
        byBookingNumberTitle: 'לפי מספר ההזמנה',
        byPasswordTitle: 'לפי סיסמא',
        accountHoldersTitle: 'בעלי חשבונות',
        bookingNumberPlaceholder: 'מספר הזמנה (בדוק דואר אלקטרוני)',
        doNotHaveAccountTitle: 'אין לך חשבון?',
        getFreeHereTitle: 'קבל בחינם כאן',
        forgotTitle: 'שכח?',
        createAnAccountTitle: 'צור חשבון',
        personalAccountTitle: 'חשבון אישי',
        companyTitle: 'החברה',
        alreadyHaveAnAccountTitle: 'כבר יש לך חשבון?',
        createTitle: 'צור',
        companyNameTitle: 'שם החברה',
        passwordConfirmTitle: 'אישור סיסמה',
        successSignInMsg: 'נרשמת בהצלחה',
        successSignInSubMsg: 'אנא בדוק את ההפעלה בדוא"ל שלך',
        resetPasswordTitle: 'לאפס את הסיסמה שלך',
        oldPassword: 'סיסמה ישנה',
        newPassword: 'סיסמה חדשה',
        repeatNewPassword: 'חזור על סיסמה חדשה',
        changeTitle: 'שינוי',
        saveTitle: 'שמור',
        resetTitle: 'אפס',
        thankForRegistering: 'תודה על הרשמתך!',
        thankForResendPass: 'תודה, עיין בדוא"ל שלך לקבלת הוראות לאיפוס הסיסמה שלך.',
        thankForResendPassSub: 'לא קיבלת את המייל? בדוק את תיקיית הזבל שלך או שלח שוב.',
        locationsTitle: 'מיקומים',
        vipTitle: 'Vip',
        affiliatesTitle: 'כלולות',
        affiliateSettlementsTitle: 'הסדר שותפים',
        vehiclesTitle: 'כלי רכב',
        extraPricesTitle: 'מחירים נוספים',
        routesTitle: 'נתיבים',
        notFoundRoutesTitle: 'נתיבים לא נמצאו',
        adminPanelTitle: 'פאנל הניהול',
        paymentRulesTitle: 'כללי תשלום',
        tripsTitle: 'טיולים',
        tripSettlements: 'טיולים הֶסדֵר',
        unfinalizedTripsTitle: 'טיולים לא מוגמרים',
        accountsTitle: 'חשבונות',
        addUserTitle: 'הוסף אחד חדש',
        dispatchTitle: 'שיגור',
        driverFullNameTitle: 'שם הנהג',
        agentFullNameTitle: 'שם סוכן',
        driverPhoneTitle: 'טלפון הנהג',
        agentPhoneTitle: 'טלפון סוכן',
        invalidFlightNumberMsg: 'מספר הטיסה אינו תקף',
        flightNumberTitle: 'מספר טיסה',
        returnFlightNumberTitle: 'החזר מספר טיסה',
    },
};
  
export default translations;