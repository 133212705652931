import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { animateScroll as scroll, scroller } from 'react-scroll';
import I18n from '../../i18n';
import LoadingOverlay from 'react-loading-overlay';
import { CarTile } from '../../global/car-tile';
import { PROP_KEYS } from './home-constants';
import { homeActions } from './home-reducer';
import { withLayout } from '../../common/layout';
import { DEFAULT_ZERO_VALUE } from '../../assets/constants/global';

class HomeResultsMobile extends React.Component {
    onSelectCar = (car) => {
        const { setSelectedCar, setLoadedCheckout } = this.props;

        setSelectedCar(car);

        const header_mobile = document.getElementsByName("header_mobile")[0];
        const ride_details_page = scroller.get("ride_details_page");
        const results_page = scroller.get("results_page");
        let height = DEFAULT_ZERO_VALUE;

        if (header_mobile && header_mobile.clientHeight) {
            height += header_mobile.clientHeight;
        }

        if (ride_details_page && ride_details_page.clientHeight) {
            height += ride_details_page.clientHeight;
        }
        
        if (results_page && results_page.clientHeight) {
            height += results_page.clientHeight;
        }
        // console.log('HomeResultsMobile scrollTo ->', height);
        scroll.scrollTo(height);

        setTimeout(() => {
            setLoadedCheckout(true);
        }, 200);
    }

    render() {
        const { isOpenedResults, isLoadedResults, results } = this.props;

        return (
            <Fragment>
                {
                    isOpenedResults && (
                        <LoadingOverlay className="results w-100" active={!isLoadedResults} spinner>
                            <div className="d-flex results-title"><I18n t="selectACarTitle" /></div>
                            <div className="d-flex flex-column mt-2">
                                {
                                    results && results.map((car, index) => (
                                        <div key={`car_${index}`} className={`p-2 m-2`}>
                                            <CarTile isMobile={true} {...{...car, onSelect: () => this.onSelectCar(car) }} />
                                        </div>
                                    ))
                                }
                            </div>
                        </LoadingOverlay>
                    )
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { location, i18n: { locale } } = state;

    return {
        ...location,
        locale
    };
}

const mapDispatchToProps = {
    setSelectedCar: (value) => homeActions.setSelectedCar(value),
    setLoadedCheckout: (value) => homeActions.setValue(PROP_KEYS.isLoadedCheckout, value)
}

const progressHomeResults = withRouter(connect(mapStateToProps, mapDispatchToProps)(withLayout(HomeResultsMobile, 'ride_details_page')));
export { progressHomeResults as HomeResultsMobile };