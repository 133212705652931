import { homeService } from './home-service';
import { config } from '../../config';
import { history } from '../../helpers/history';
import moment from "moment";
import { notification } from 'antd';
import { PROP_KEYS, PAYMENT_METHODS } from './home-constants';
import { DEFAULT_ZERO_VALUE, STRING_EMPTY } from '../../assets/constants/global';
import Cookies from 'js-cookie';
import _ from 'lodash';

// types
const TYPES = {
    SET_VALUE: 'SET_VALUE',
    ADD_OPTION: 'ADD_OPTION',
    REMOVE_OPTION: 'REMOVE_OPTION',
    CLEAR: 'CLEAR',
    SET_ALL: 'SET_ALL'
};

// actions
const setValue = (key, value) =>  ({ type: TYPES.SET_VALUE, key, value })
const setSelectedCar = (value) => {
    return (dispatch, getState) => {
        const { minFreeBoardingTime } = value;
        const { pickupLocationDatetime } = getState().location;

        if (minFreeBoardingTime && moment(new Date()).isAfter(moment(pickupLocationDatetime).add(minFreeBoardingTime * (-1), 'hour'))) {
            notification.warning({
                duration: 10,
                message: `Your requested pickup time falls below our free sale window. Please continue booking and final confirmation will be sent based on availability. Thank You.`
            })
        } 
        
        dispatch(setValue(PROP_KEYS.selectedCar, value));
        dispatch(setValue(PROP_KEYS.isOpenedCheckout, true));
    }
}
const setTripValue = (key, value) => {
    return (dispatch) => {        
        dispatch(setValue(key, value));
        dispatch(setValue(PROP_KEYS.isOpenedResults, false));
        dispatch(setValue(PROP_KEYS.isOpenedCheckout, false));
    };
};
const setLocationValue = (key, value) => {
    return (dispatch) => {
        if(value === null) {
            dispatch(setValue(PROP_KEYS.distance, null));
            dispatch(setValue(PROP_KEYS.duration, null));
        }
        
        dispatch(setTripValue(key, value));
    };
};
const setAll = (value) =>  ({ type: TYPES.SET_ALL, value })
const addOption = (value, parent) => ({ type: TYPES.ADD_OPTION, value, parent })
const removeOption = (value, parent, all = false) => ({ type: TYPES.REMOVE_OPTION, value, parent, all })
const clear = () => {
    return (dispatch) => {
        dispatch({ type: TYPES.CLEAR });
        history.push('/');
    }
}
const loadResults = () => {
    return (dispatch, getState) => {
        dispatch(setValue(PROP_KEYS.isLoadedResults, false));
        const {  
            pickupLocationDatetime, destinationDatetime,
            returnTransfer, hourlyBooking, hourlyBookingHours,
            pickupLocationObj, destinationObj, pickupLocationString, destinationString
        } = getState().location;

        const routeRequest = {
            pickupLocation: pickupLocationObj,
            dropoffLocation: destinationObj,
            startTripDatetime: pickupLocationDatetime,
            returnTransferTripDatetime: returnTransfer ? destinationDatetime : null,
            isReturnTransfer: returnTransfer,
            isHourlyBooking: hourlyBooking,
            bookingHours: hourlyBooking ? hourlyBookingHours : DEFAULT_ZERO_VALUE
        };

        return homeService.loadResults(routeRequest, pickupLocationString, destinationString)
                   .then(data => {
                        const { routes, location, destination, destinationObj, pickupLocationObj } = data;
                        
                        if (location) {
                            dispatch(setValue(PROP_KEYS.pickupLocationIsAirport, location.isAirport));
                        }
                        
                        if (destination) {
                            dispatch(setValue(PROP_KEYS.dropoffLocationIsAirport, destination.isAirport));
                        }
                        
                        if (routes) {
                            const filteredRoutes = routes.map(r => {
                                let { additionalOptions } = r;
                                if (additionalOptions) {
                                    const filteredAdditionalOptions = _.uniqBy(additionalOptions, 'name')
                                    r.additionalOptions = [ ...filteredAdditionalOptions ];
                                }

                                r.image = `${config.apiUrl}/${r.image}`;

                                return r;
                            })
                            dispatch(setValue(PROP_KEYS.results, filteredRoutes));
                        }
                        
                        if (destinationObj) {
                            dispatch(setValue(PROP_KEYS.destinationObj, destinationObj));
                        }

                        if (pickupLocationObj) {
                            dispatch(setValue(PROP_KEYS.pickupLocationObj, pickupLocationObj));
                        }
                   })
                   .catch(error => {
                       if (error.status === 404) {
                            dispatch(setValue(PROP_KEYS.routeNotFound, true));
                            dispatch(setValue(PROP_KEYS.notFoundIsLoaded, true));
                       } else {
                           notification.error({
                               message: error.toString()
                           })
                       }
                        dispatch(setValue(PROP_KEYS.isOpenedResults, false));
                   })
                   .catch(() => {
                       dispatch(setValue(PROP_KEYS.routeNotFound, true));
                       dispatch(setValue(PROP_KEYS.isOpenedResults, false));
                   })
                   .finally(() => dispatch(setValue(PROP_KEYS.isLoadedResults, true)));;
    };
}

const loadLocations = () => {
    return (dispatch, getState) => {
        const {  
            pickupLocationString, destinationString
        } = getState().location;

        if (pickupLocationString && destinationString) {
            return homeService.loadLocations(pickupLocationString, destinationString)
                .then(({ pickupLocation, dropoffLocation }) => {
                    dispatch(setValue(PROP_KEYS.pickupLocationObj, pickupLocation));
                    dispatch(setValue(PROP_KEYS.destinationObj, dropoffLocation));
                })
        }
    }
}

const logUnfinalized = () => {
    return (_dispatch, getState) => {
        const location = getState().location;
        const tripRequest = mapToTripRequest(location);
        const userRequest = mapToUserRequest(location);
        
        return userRequest.email && tripRequest.routeId && homeService.logUnfinalized({ trip: tripRequest, user: userRequest, userName: userRequest.email });
    }
}

const routeNotFoundSubmit = () => {
    return (dispatch, getState) => {
        dispatch(setValue(PROP_KEYS.notFoundIsLoaded, false));
        const location = getState().location;
        const tripRequest = mapToTripRequest(location);
        const user = Cookies.get('user') && JSON.parse(Cookies.get('user'));
        const userRequest = user ? {
            userName: user.fullName,
            email: user.email
        } : {
            userName: location.notFoundName,
            email: location.notFoundEmail
        };

        homeService.routeNotFoundSubmit(tripRequest, userRequest, location.pickupLocationString, location.destinationString)
                   .then(data => {
                        notification.success({
                            message: 'Submitted successfully!'
                        })
                   })
                   .catch(error => {
                        notification.error({
                            message: error.toString()
                        });
                   })
                   .finally(() => {
                       dispatch(setValue(PROP_KEYS.notFoundIsLoaded, true));
                       dispatch(setValue(PROP_KEYS.routeNotFound, false));
                   });
    };
}

const beginCheckout = (edit, userName) => {
    return (dispatch, getState) => {
        dispatch(setValue(PROP_KEYS.isLoadedCheckout, false));
        const locale = getState().i18n.locale;
        const location = getState().location;
        const tripRequest = mapToTripRequest(location);
        const userRequest = mapToUserRequest(location);
        const userEditRequest = mapToUserEditRequest(location);
        const tripJson = JSON.stringify(location);
        const total = homeService.calculateTotal(location.addedOptions, location.selectedCar, false);
        const discount = location.promoDiscount && homeService.calculateDiscount(location.selectedCar, location.promoDiscount.value, location.promoDiscount.typeOfRule, location.returnTransfer);
        const editTripRequest = {
            id: location.tripId,
            pickupLocation: location.pickupLocationObj,
            dropoffLocation: location.destinationObj,
            flightNumber: location.flightNumber,
            startTripDatetime: location.pickupLocationDatetime,
            isHourlyBooking: location.hourlyBooking,
            bookingHours: location.hourlyBooking ? location.hourlyBookingHours : DEFAULT_ZERO_VALUE,
            comment: location.userComment,
            routeId: location.selectedCar && location.selectedCar.routeId,
            image: location.selectedCar && location.selectedCar.image,
            promoCodeId:  location.promoDiscount && location.promoDiscount.id,
            currency: location.selectedCar && location.selectedCar.currency,
            total: total - discount,
            tripAdditionalOptions: Object.keys(location.addedOptions).map(x => {
                let option = location.selectedCar.additionalOptions && location.selectedCar.additionalOptions.find(y => y.name === x);
                let subAdditionalOptionId = null;

                if (option) {
                    if (location.addedOptions[x] > DEFAULT_ZERO_VALUE && !(option.variants && option.variants.length > DEFAULT_ZERO_VALUE)) {
                        return {
                            id: option.id,
                            additionalOptionId: option.additionalOptionTypeId,
                            subAdditionalOptionId,
                            quantity: location.addedOptions[x],
                            type: option.type
                        }
                    }
    
                    return null;
                } else {
                    option = location.selectedCar.additionalOptions
                          && location.selectedCar.additionalOptions.find(y => y.variants && y.variants.find(y => y.name === x));
                    const variant = option && option.variants && option.variants.find(y => y.name === x);
                    subAdditionalOptionId = variant && variant.id;
    
                    if (option && location.addedOptions[x] > DEFAULT_ZERO_VALUE) {
                        return {
                            id: option.id,
                            additionalOptionId: option.additionalOptionTypeId,
                            subAdditionalOptionId,
                            quantity: location.addedOptions[x],
                            type: option.type
                        }
                    }
    
                    return null;
                }
            }).filter(x => x),
            tripJson
        }

        if (edit) {
            return homeService.editCheckout(editTripRequest, userEditRequest, location.tripId)
                       .then(
                           ({ ok, edited, orderId, tripIds }) => {
                                dispatch(clear());

                                if (edited) {
                                    history.push(`/${locale}/user`);
                                } else if (orderId) {
                                    dispatch(setValue(PROP_KEYS.paypalOrderId, orderId));

                                    return orderId;
                                } else if (ok) {
                                    history.push(`/success-payment`);
                                } else {
                                    history.push(`/cancel-payment`);
                                }
                           })
                           .catch(error => {
                                notification.error({
                                    message: error.toString()
                                });
                                history.push(`/${locale}/user`);
                        })
                       .finally(() => dispatch(setValue(PROP_KEYS.isLoadedCheckout, true)));
        } else {
            return homeService.beginCheckout(tripRequest, userRequest, tripJson, userName)
                       .then(checkout => {
                            dispatch(setValue(PROP_KEYS.checkout, checkout));
                       })
                       .finally(() => dispatch(setValue(PROP_KEYS.isLoadedCheckout, true)));
        }
    };
}

const editCheckoutPaypal = () => {
    return (dispatch, getState) => {
        dispatch(setValue(PROP_KEYS.isLoadedCheckout, false));
        const location = getState().location;
        const tripJson = JSON.stringify(location);
        const total = homeService.calculateTotal(location.addedOptions, location.selectedCar, false);
        const discount = location.promoDiscount && homeService.calculateDiscount(location.selectedCar, location.promoDiscount.value, location.promoDiscount.typeOfRule, location.returnTransfer);
        const editTripRequest = {
            id: location.tripId,
            pickupLocation: location.pickupLocationObj,
            dropoffLocation: location.destinationObj,
            flightNumber: location.flightNumber,
            startTripDatetime: location.pickupLocationDatetime,
            isHourlyBooking: location.hourlyBooking,
            bookingHours: location.hourlyBooking ? location.hourlyBookingHours : DEFAULT_ZERO_VALUE,
            comment: location.userComment,
            routeId: location.selectedCar && location.selectedCar.routeId,
            image: location.selectedCar && location.selectedCar.image,
            promoCodeId:  location.promoDiscount && location.promoDiscount.id,
            currency: location.selectedCar && location.selectedCar.currency,
            total: total - discount,
            tripAdditionalOptions: Object.keys(location.addedOptions).map(x => {
                let option = location.selectedCar.additionalOptions && location.selectedCar.additionalOptions.find(y => y.name === x);
                let subAdditionalOptionId = null;

                if (option) {
                    if (location.addedOptions[x] > DEFAULT_ZERO_VALUE && !(option.variants && option.variants.length > DEFAULT_ZERO_VALUE)) {
                        return {
                            id: option.id,
                            additionalOptionId: option.additionalOptionTypeId,
                            subAdditionalOptionId,
                            quantity: location.addedOptions[x],
                            type: option.type
                        }
                    }
    
                    return null;
                } else {
                    option = location.selectedCar.additionalOptions
                          && location.selectedCar.additionalOptions.find(y => y.variants && y.variants.find(y => y.name === x));
                    const variant = option && option.variants && option.variants.find(y => y.name === x);
                    subAdditionalOptionId = variant && variant.id;
    
                    if (option && location.addedOptions[x] > DEFAULT_ZERO_VALUE) {
                        return {
                            id: option.id,
                            additionalOptionId: option.additionalOptionTypeId,
                            subAdditionalOptionId,
                            quantity: location.addedOptions[x],
                            type: option.type
                        }
                    }
    
                    return null;
                }
            }).filter(x => x),
            tripJson
        }

        return homeService.editCheckoutPaypal(editTripRequest) 
        .then(data => {
            return data.orderId;
        }) 
        .finally(() => dispatch(setValue(PROP_KEYS.isLoadedCheckout, true)));
    };
}

const invoicePayment = () => {
    return (dispatch, getState) => {
        dispatch(setValue(PROP_KEYS.isLoadedCheckout, false));
        const location = getState().location;
        const tripRequest = mapToTripRequest(location);
        const userRequest = mapToUserRequest(location); 
        const tripJson = JSON.stringify(location);
        const login = {
            userName: location.invoiceEmail,
            password: location.invoicePassword
        };

        homeService.invoicePayment(tripRequest, login, userRequest, tripJson)
                   .then(data => {
                       if (data) {
                            dispatch(clear());
                            history.push(`/success-payment`);
                       }
                   })
                   .catch(error => {
                        notification.error({
                            message: error.toString()
                        });
                   })
                   .finally(() => dispatch(setValue(PROP_KEYS.isLoadedCheckout, true)));
    };
}

const createCheckoutPaypal = (userName) => {
    return (dispatch, getState) => {
        dispatch(setValue(PROP_KEYS.isLoadedCheckout, false));
        const location = getState().location;
        const tripRequest = mapToTripRequest(location);
        const userRequest = mapToUserRequest(location);
        const tripJson = JSON.stringify(location);

        return homeService.createCheckoutPaypal(tripRequest, userRequest, tripJson, userName) 
        .then(res => {
            return res;
        })
        .then(data => {
            return data.orderId;
        }) 
        .finally(() => dispatch(setValue(PROP_KEYS.isLoadedCheckout, true)));
    };
}

const paypalCheckoutSessionCompleted = (orderID) => {
    return (dispatch, _getState) => {
        dispatch(setValue(PROP_KEYS.isLoadedCheckout, false));
        return homeService.paypalCheckoutSessionCompleted(orderID) 
        .then(ok => {
            dispatch(clear());

            if (ok) {
                history.push(`/success-payment`);
            } else {
                history.push(`/cancel-payment`);
            }
       })
       .finally(() => dispatch(setValue(PROP_KEYS.isLoadedCheckout, true)));
    };
}

const paypalCancelStatus = (details) => {
    return (dispatch, getState) => {
        dispatch(setValue(PROP_KEYS.isLoadedCheckout, false));
        const location = getState().location;

        homeService.paypalCancelStatus({ sessionId: '', sessionDetails: JSON.stringify(details), tripIds: [ location.tripId ] })
                   .finally(() => dispatch(setValue(PROP_KEYS.isLoadedCheckout, true)));
    };
}

const paypalFailStatus = (err) => {
    return (dispatch, getState) => {
        dispatch(setValue(PROP_KEYS.isLoadedCheckout, false));
        const location = getState().location;

        homeService.paypalFailStatus({ sessionId: '', sessionDetails: JSON.stringify(err), tripIds: [ location.tripId ] })
                   .then(() => {
                        dispatch(clear());
                        history.push(`/cancel-payment`);
                   })
                   .finally(() => dispatch(setValue(PROP_KEYS.isLoadedCheckout, true)));
    };
}

const returnToCheckout = (sessionId, edit = false) => {
    return (dispatch) => {
        homeService.returnToCheckout(sessionId)
                   .then(data => {
                        dispatch(setAll(data));
                        history.push(edit ? '/edit' : `/`);
                   })
                   .finally(() => dispatch(setValue(PROP_KEYS.isLoadedCheckout, true)));
    };
}

const setDriverInfo = (driverInfo, isAgent = false) => {
    return (dispatch) => {
        dispatch(setValue(isAgent ? PROP_KEYS.isLoadedAgentModal : PROP_KEYS.isLoadedDriverModal, false));

        homeService.setDriverInfo(driverInfo, isAgent)
                   .then(ok => {
                        history.push(`/`);
                   })
                   .finally(() => dispatch(setValue(isAgent ? PROP_KEYS.isLoadedAgentModal : PROP_KEYS.isLoadedDriverModal, true)));
    };
}

const setDriverWithoutDetailsInfo = (token, isAgent = false) => {
    return (dispatch) => {
        dispatch(setValue(isAgent ? PROP_KEYS.isLoadedAgentModal : PROP_KEYS.isLoadedDriverModal, false));

        homeService.setDriverWithoutDetailsInfo(token, isAgent)
                   .then(ok => {
                        history.push(`/`);
                   })
                   .finally(() => dispatch(setValue(isAgent ? PROP_KEYS.isLoadedAgentModal : PROP_KEYS.isLoadedDriverModal, true)));
    };
}

const checkPromocode = (promocode) => {
    return (dispatch) => {
        dispatch(setValue(PROP_KEYS.isLoadedCheckout, false));

        homeService.checkPromocode(promocode)
                   .then(data => {
                        if (data) {
                            dispatch(setValue(PROP_KEYS.promoDiscount, data));
                        }
                   })
                   .catch(error => {
                        notification.error({
                            message: 'The promocode you entered is invalid. Please try again!'
                        })
                   })
                   .finally(() => dispatch(setValue(PROP_KEYS.isLoadedCheckout, true)));
    };
}

const loadHtml = (pageKey) => {
    return (dispatch) => {
        dispatch(setValue(PROP_KEYS.isLoadedCheckout, false));
        
        homeService.loadHtml(pageKey)
                   .then(data => {
                        if (data) {
                            dispatch(setValue(pageKey, data));
                        }
                   })
                   .catch(error => {
                        notification.error({
                            message: 'Error while loading html'
                        })
                   })
                   .finally(() => dispatch(setValue(PROP_KEYS.isLoadedCheckout, true)));
    };
}

export const homeActions = {
    loadHtml,
    clear,
    setAll,
    setValue,
    setTripValue,
    setLocationValue,
    addOption,
    loadResults,
    removeOption,
    beginCheckout,
    createCheckoutPaypal,
    paypalCheckoutSessionCompleted,
    paypalCancelStatus,
    paypalFailStatus,
    invoicePayment,
    routeNotFoundSubmit,
    returnToCheckout,
    checkPromocode,
    editCheckoutPaypal,
    setDriverInfo,
    setDriverWithoutDetailsInfo,
    loadLocations,
    setSelectedCar,
    logUnfinalized
};

// reducer
const initialState = {
    [PROP_KEYS.pickupLocation]: null,
    [PROP_KEYS.destination]: null,
    [PROP_KEYS.pickupLocationString]: null,
    [PROP_KEYS.destinationString]: null,
    [PROP_KEYS.pickupLocationObj]: null,
    [PROP_KEYS.destinationObj]: null,
    [PROP_KEYS.returnTransfer]: false,
    [PROP_KEYS.addedOptions]: {},
    [PROP_KEYS.paymentMethodToggle]: PAYMENT_METHODS.Checkout,
    [PROP_KEYS.promo]: null,
    [PROP_KEYS.showPromo]: false,
    [PROP_KEYS.pickupLocationDatetime]: moment().set({ 'minutes': DEFAULT_ZERO_VALUE, 'seconds': DEFAULT_ZERO_VALUE }).add(1, 'hours'),
    [PROP_KEYS.destinationDatetime]: moment().set({ 'minutes': DEFAULT_ZERO_VALUE, 'hours': DEFAULT_ZERO_VALUE, 'seconds': DEFAULT_ZERO_VALUE }).add(1, 'day'),
    [PROP_KEYS.routeNotFound]: false,
    [PROP_KEYS.hourlyBooking]: false,
    [PROP_KEYS.hourlyBookingHours]: DEFAULT_ZERO_VALUE + 4,
    [PROP_KEYS.promoDiscount]: null,
    [PROP_KEYS.isLoadedDriverModal]: true,
    [PROP_KEYS.isLoadedAgentModal]: true,
    [PROP_KEYS.notFoundIsLoaded]: true,
    [PROP_KEYS.invoiceEmail]: null,
    [PROP_KEYS.invoicePassword]: null,
    [PROP_KEYS.aboutPage]: null,
    [PROP_KEYS.partnersPage]: null,
    [PROP_KEYS.faqPage]: null,
    [PROP_KEYS.privacyAndTermsPage]: null
};

export const location = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.SET_VALUE: {
            const { key, value } = action;

            return {
                ...state,
                [key]: value
            };
        }
        case TYPES.CLEAR: {
            return {
                ...initialState
            }
        }
        case TYPES.SET_ALL: {
            const { value } = action;
            
            return {
                ...value
            }
        }
        case TYPES.ADD_OPTION: {
            const { value, parent } = action;

            if (value) {
                const addedOptionsCopy = { ...state.addedOptions };
                const selectedCarCopy = { ...state.selectedCar };
                const additionalOptionsCopy = selectedCarCopy && selectedCarCopy.additionalOptions.find(x => x.name === value);
                let added = addedOptionsCopy[value];
                let parentAdded = addedOptionsCopy[parent] || DEFAULT_ZERO_VALUE;
                let newOption = { ...addedOptionsCopy, [value]: DEFAULT_ZERO_VALUE + 1 };
                
                if (additionalOptionsCopy && additionalOptionsCopy.variants && additionalOptionsCopy.variants.length > DEFAULT_ZERO_VALUE) {
                    additionalOptionsCopy.variants.forEach((x, i) => {
                        if ((!added || added === DEFAULT_ZERO_VALUE) && i === DEFAULT_ZERO_VALUE) {
                            newOption[x.name] = DEFAULT_ZERO_VALUE + 1;
                            addedOptionsCopy[x.name] = DEFAULT_ZERO_VALUE + 1;
                            parentAdded += 1;
                        } else {
                            newOption[x.name] = DEFAULT_ZERO_VALUE;
                            addedOptionsCopy[x.name] = DEFAULT_ZERO_VALUE;
                        }
                    });
                    newOption[value] = !added ? DEFAULT_ZERO_VALUE + 1 : DEFAULT_ZERO_VALUE;
                }

                const obj = parent ? { ...addedOptionsCopy, [value]: ++added, [parent]: ++parentAdded } : { ...addedOptionsCopy, [value]: ++added };
            
                return {
                    ...state,
                    addedOptions: added || added === DEFAULT_ZERO_VALUE ? obj : { ...newOption }
                }
            }
        
            return { ...state };
        }
        case TYPES.REMOVE_OPTION: {
            const { value, parent, all } = action;

            if (value) {
                const addedOptionsCopy = { ...state.addedOptions };
                const selectedCarCopy = { ...state.selectedCar };
                const additionalOptionsCopy = selectedCarCopy && selectedCarCopy.additionalOptions.find(x => x.name === value);
                let added = addedOptionsCopy[value];
                let parentAdded = addedOptionsCopy[parent] || DEFAULT_ZERO_VALUE;

                if (additionalOptionsCopy && additionalOptionsCopy.variants && additionalOptionsCopy.variants.length > DEFAULT_ZERO_VALUE) {
                    additionalOptionsCopy.variants.forEach(x => addedOptionsCopy[x.name] = DEFAULT_ZERO_VALUE);
                    added = DEFAULT_ZERO_VALUE;
                } else {
                    --added;
                }

                if (all) {
                    added = DEFAULT_ZERO_VALUE;
                }

                const obj = parent ? { ...addedOptionsCopy, [value]: added, [parent]: --parentAdded } : { ...addedOptionsCopy, [value]: added };
            
                return {
                    ...state,
                    addedOptions: obj
                }
            }

            return { ...state };
        }
        default:
            return state;
    }
}

const mapToUserRequest = (location) => {
    const checkCompanyAccountRole = Cookies.get('user') && (JSON.parse(Cookies.get('user')).role[0] === 'CompanyAccount');
    const userRequest = Cookies.get('user') && !checkCompanyAccountRole
                      ? JSON.parse(Cookies.get('user')) 
                      : {
                            email: location.userEmail,
                            fullName: location.userFullName,
                            phone: location.userPhone
                        };

    return {
        email: userRequest.email || STRING_EMPTY,
        fullName: userRequest.fullName || STRING_EMPTY,
        phone: userRequest.phone || `${DEFAULT_ZERO_VALUE}`
    }
}

const mapToUserEditRequest = (location) => {
    const checkCompanyAccountRole = Cookies.get('user') && (JSON.parse(Cookies.get('user')).role[0] === 'CompanyAccount');
    const userRequest = Cookies.get('user') && !checkCompanyAccountRole
                      ? JSON.parse(Cookies.get('user')) 
                      : {
                            id: location.tripContactId,
                            email: location.userEmail,
                            fullName: location.userFullName,
                            phone: location.userPhone
                        };
                        
    return !checkCompanyAccountRole ?  {
        email: userRequest.email || STRING_EMPTY,
        fullName: userRequest.fullName || STRING_EMPTY,
        phone: userRequest.phone || `${DEFAULT_ZERO_VALUE}`
    } : {
        id: userRequest.id || `${DEFAULT_ZERO_VALUE}`,
        email: userRequest.email || STRING_EMPTY,
        fullName: userRequest.fullName || STRING_EMPTY,
        phone: userRequest.phone || `${DEFAULT_ZERO_VALUE}`
    }
}

const mapToTripRequest = (location) => {
    const {  
        addedOptions, selectedCar,
        pickupLocationDatetime, destinationDatetime,
        returnTransfer, hourlyBooking, hourlyBookingHours, userComment,
        pickupLocationObj, destinationObj, promoDiscount, flightNumber, returnFlightNumber
    } = location;
    const total = homeService.calculateTotal(addedOptions, selectedCar, returnTransfer);
    const discount = promoDiscount && homeService.calculateDiscount(selectedCar, promoDiscount.value, promoDiscount.typeOfRule, returnTransfer);

    return {
        pickupLocation: pickupLocationObj,
        dropoffLocation: destinationObj,
        flightNumber: flightNumber,
        returnFlightNumber: returnFlightNumber,
        startTripDatetime: pickupLocationDatetime,
        returnTransferTripDatetime: returnTransfer ? destinationDatetime : null,
        returnTransferRouteId: returnTransfer && selectedCar ? selectedCar.return_transfer_route_id : null,
        isReturnTransfer: returnTransfer,
        isHourlyBooking: hourlyBooking,
        bookingHours: hourlyBooking ? hourlyBookingHours : DEFAULT_ZERO_VALUE,
        bookingNumber: DEFAULT_ZERO_VALUE,
        comment: userComment,
        routeId: selectedCar && selectedCar.routeId,
        image: selectedCar && selectedCar.image,
        promoCodeId: promoDiscount && promoDiscount.id,
        currency: selectedCar && selectedCar.currency,
        total: total - discount,
        tripAdditionalOptions: selectedCar && Object.keys(addedOptions).map(x => {
            let option = selectedCar && selectedCar.additionalOptions && selectedCar.additionalOptions.find(y => y.name === x);
            let subAdditionalOptionId = null;

            if (option) {
                if (addedOptions[x] > DEFAULT_ZERO_VALUE && !(option.variants && option.variants.length > DEFAULT_ZERO_VALUE)) {
                    return {
                        id: option.id,
                        additionalOptionId: option.additionalOptionTypeId,
                        subAdditionalOptionId,
                        quantity: addedOptions[x],
                        type: option.type
                    }
                }

                return null;
            } else {
                option = selectedCar && selectedCar.additionalOptions
                      && selectedCar.additionalOptions.find(y => y.variants && y.variants.find(y => y.name === x));
                const variant = option && option.variants && option.variants.find(y => y.name === x);
                subAdditionalOptionId = variant && variant.id;

                if (option && addedOptions[x] > DEFAULT_ZERO_VALUE) {
                    return {
                        id: option.id,
                        additionalOptionId: option.additionalOptionTypeId,
                        subAdditionalOptionId,
                        quantity: addedOptions[x],
                        type: option.type
                    }
                }

                return null;
            }
        }).filter(x => x)
    }
}