import React from 'react';
import { withLayout } from '../../common/layout';
import I18n from '../../i18n';
import { Modal } from 'antd';
import successImg from '../../assets/icons/success_payment.svg';
import { Link } from 'react-router-i18n';

class ResendPassword extends React.Component {
    render() {
        return (
            <div id='success-confirm'>
                <Modal title="Basic Modal"
                       visible={true}
                       centered={true}
                       footer={null}
                       maskClosable={false}
                       getContainer={() => document.getElementById('success-confirm')}>
                    <div className="d-flex flex-column align-items-center">
                        <img className="m-3" src={successImg} alt='success-confirm' />
                        <span className="payment-title mt-3 mb-3"><I18n t="thankForResendPass" /></span>
                        <span className="payment-subtitle mt-3 mb-3"><I18n t="thankForResendPassSub" /></span>
                        <div className="d-flex">
                            <Link to="/">
                                <button className="payment-button mt-3">
                                    <I18n t="closeTitle" />
                                </button>
                            </Link>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const connectedResendPassword = (withLayout(ResendPassword));
export { connectedResendPassword as ResendPassword };