import React from "react";
import { Layout } from 'antd';
import windowSize from 'react-window-size';
import default_user from '../assets/icons/default_user.png';
import default_company from '../assets/icons/default_company.png';
import logo from '../assets/icons/logo.svg';
import { ProgressPages } from "../global/progress-pages";
import { Link } from 'react-router-i18n';
import I18n from '../i18n';
import { userService } from '../pages/user/user-service';
import Cookies from 'js-cookie';

const { Header, Content } = Layout;

export const withProgressLayout = (WrappedComponent, currentPage) => {
    class BaseLayout extends React.Component {
        logout = () => {
            userService.logout();
        }

        render() {
            const user = Cookies.get('user') && JSON.parse(Cookies.get('user'));
            const name = user && (user.companyName || user.fullName);

            return (
                <Layout className="progress-layout"
                        style={{
                            width: this.props.windowWidth,
                            height: this.props.windowHeight
                        }}>
                    <Header>
                        <div className="d-flex justify-content-between">
                            <Link to="/"><img src={logo} alt="User logo" /></Link>
                            <ProgressPages currentPage={currentPage} />
                            {
                                Cookies.get('token') && user && (
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <Link to="/user">
                                             <div className="user-logo d-flex align-items-center">
                                                 <div className="user-logo-image ml-5">
                                                     <img src={user.companyName ? default_company : default_user} alt="User logo" />
                                                     <span>{name}</span>
                                                 </div>
                                            </div>
                                        </Link>
                                        <div className="user-login d-flex align-items-center">
                                            <button onClick={this.logout}><span><I18n t="logoutTitle" /></span></button>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </Header>
                    <Content>
                        <WrappedComponent {...this.props} />
                    </Content>
                </Layout>
            );
        }
    };

    return windowSize(BaseLayout);
}