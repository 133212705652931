 /* global google */
import React from "react";
import {
  DirectionsRenderer
} from "react-google-maps";

export class MapDirection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            directions: null
        };
    }

    componentDidMount() {
        const { origin, destination, setDistance, setDuration } = this.props;
        
        const directionsService = new google.maps.DirectionsService();
        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    this.setState({
                        directions: result
                    }, () => {
                        const { distance, duration } = result.routes[0].legs[0];
                        setDistance(distance.text);
                        setDuration(duration.text);
                    });
                } else {
                    console.error(result);
                }
            }
        );
    } 

    render() {
        return (this.state.directions && <DirectionsRenderer options={{ preserveViewport: true, suppressMarkers: true, polylineOptions: { strokeColor: "#605DF3", strokeWeight: 6 } }} directions={this.state.directions} />)
    }
}