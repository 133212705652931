import React from 'react';
import { connect } from 'react-redux';
import { withAdminLayout } from '../../common/admin-layout';
import { ADMIN_PAGES } from '../../assets/constants/global';
import { adminActions } from './admin-reducer';
import { BaseAdmin } from './base';
import { PROP_KEYS, ACTION_LOAD_TYPES, TEMPLATE_TYPES } from './admin-constants';

class AdminTrips extends React.Component {
    componentDidMount() {
        const { loadData } = this.props;

        loadData();
    }

    render() {
        const { 
            trips, tripsErrorMsg, handleDataPageChange, handleDataPageSizeChange,
            tripsPagination, isLoadedTrips, handleSort, exportData, tripColumnTypes
        } = this.props;
        const baseProps = {
            data: trips,
            isLoaded: isLoadedTrips,
            errorMsg: tripsErrorMsg,
            handleExportData: exportData,
            pagination: tripsPagination,
            handlePageChange: handleDataPageChange,
            handlePageSizeChange: handleDataPageSizeChange,
            sortData: handleSort,
            showImport: false,
            showTemplate: false,
            columnTypes: tripColumnTypes
        };

        return (
            <BaseAdmin {...baseProps} />
        );
    }
}

const mapStateToProps = (state) => {
    const { admin } = state;
    const { trips, tripsPagination, isLoadedTrips, tripColumnTypes } = admin;

    return {
        trips, isLoadedTrips, tripsPagination, tripColumnTypes
    };
}

const { action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey } = {
    action: ACTION_LOAD_TYPES.trip,
    isLoadedKey: PROP_KEYS.isLoadedTrips,
    paginationKey: PROP_KEYS.tripsPagination,
    dataKey: PROP_KEYS.trips,
    fileKey: TEMPLATE_TYPES.Trip,
    columnTypesKey: PROP_KEYS.tripColumnTypes
}

const mapDispatchToProps = {
    loadData: () => adminActions.load({ 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    exportData: () => adminActions.exportData({ 
        action, isLoadedKey, fileKey
    }),
    handleDataPageChange: (page, pageSize) => adminActions.handlePaginationChange(page, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleDataPageSizeChange: (_page, pageSize) => adminActions.handlePaginationChange(1, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleSort: (_pagination, _filters, sorter) => adminActions.handleSort(1, sorter, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    })
}

const connectedAdminTrips = connect(mapStateToProps, mapDispatchToProps)(withAdminLayout(AdminTrips, ADMIN_PAGES.AdminTrips));
export { connectedAdminTrips as AdminTrips };