import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link, animateScroll as scroll, scroller } from 'react-scroll';
import I18n from '../../i18n';
import LoadingOverlay from 'react-loading-overlay';
import { withProgressLayout } from '../../common/progress-layout';
import { CarTile } from '../../global/car-tile';
import { PROP_KEYS } from './home-constants';
import { homeActions } from './home-reducer';

class HomeResults extends React.Component {
    onSelectCar = (car) => {
        const { setSelectedCar, setLoadedCheckout } = this.props;

        setSelectedCar(car);
        const ride_details_page = scroller.get("ride_details_page");
        const results_page = scroller.get("results_page");

        if (ride_details_page && results_page) {
            const height = ride_details_page.clientHeight && results_page.clientHeight && ride_details_page.clientHeight + results_page.clientHeight;
            scroll.scrollTo(height);
        }

        setTimeout(() => {
            setLoadedCheckout(true);
        }, 200);
    }

    render() {
        const { isOpenedResults, isLoadedResults, results } = this.props;

        return (
            <Fragment>
                {
                    isOpenedResults && (
                        <LoadingOverlay className="results w-100" active={!isLoadedResults} spinner>
                            <div className="back-to">
                                <Link to="ride_details_page" spy={true} smooth={true}>
                                    <div className="d-flex align-items-center">
                                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 1L2 5L6 9" stroke="#959CAC" strokeWidth="2" />
                                        </svg>
                                        <I18n t="backToAddressTitle" />
                                    </div>
                                </Link>
                            </div>
                            <div className="d-flex results-title"><I18n t="selectACarTitle" /></div>
                            <div className="d-flex flex-wrap mt-2">
                                {
                                    results && results.map((car, index) => (
                                        <div key={`car_${index}`} className={`col-6 ${index % 2 > 0 ? 'pl-3' : 'pr-3'} p-0 pb-3 pt-3`}>
                                            <CarTile {...{ ...car, onSelect: () => this.onSelectCar(car) }} />
                                        </div>
                                    ))
                                }
                            </div>
                        </LoadingOverlay>
                    )
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { location, i18n: { locale } } = state;

    return {
        ...location,
        locale
    };
}

const mapDispatchToProps = {
    setSelectedCar: (value) => homeActions.setSelectedCar(value),
    setLoadedCheckout: (value) => homeActions.setValue(PROP_KEYS.isLoadedCheckout, value)
}

const progressHomeResults = withRouter(connect(mapStateToProps, mapDispatchToProps)(withProgressLayout(HomeResults, 'results')));
export { progressHomeResults as HomeResults };