import { userService } from './user-service';
import { history } from '../../helpers/history';
import { PROP_KEYS } from './user-constants';
import { notification } from 'antd';
import { DEFAULT_ZERO_VALUE, USER_ROLES } from '../../assets/constants/global';
import { homeActions } from '../home/home-reducer';
import { PROP_KEYS as HOME_PROP_KEYS, PAYMENT_METHODS } from '../home/home-constants';
import Cookies from 'js-cookie';

// types
const TYPES = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'USERS_LOGOUT',
    SET_USER_VALUE: 'SET_USER_VALUE',
    SET_SUB_VALUE: 'SET_SUB_VALUE'
};

// actions
const request = (user) => ({ type: TYPES.LOGIN_REQUEST, user })
const success = (token) => ({ type: TYPES.LOGIN_SUCCESS, token })
const failure = (error) => ({ type: TYPES.LOGIN_FAILURE, error })
const setUserValue = (key, value) =>  ({ type: TYPES.SET_USER_VALUE, key, value })
const setSubValue = (key, subKey, value) => ({ type: TYPES.SET_SUB_VALUE, key, subKey, value })
const companyLogin = (userName, password) => {
    return (dispatch, getState) => {
        const locale = getState().i18n.locale;
        dispatch(setUserValue(PROP_KEYS.isLoadedLogin, false));

        userService.companyLogin(userName, password, locale)
            .then(
                ({ token, user }) => { 
                    dispatch(success(token));
                    dispatch(request(user));
                    
                    if (user.role && user.role.includes(USER_ROLES.Admin)) {
                        history.push(`/${locale}/admin`);
                    } else {
                        history.push(`/${locale}/user`);
                    }
                },
                error => {
                    dispatch(failure(error));
                    notification.error({
                        message: error.toString()
                    })
                }
            )
            .finally(() => dispatch(setUserValue(PROP_KEYS.isLoadedLogin, true)));
    };
}

const personalLogin = (userName, bookingNumber) => {
    return (dispatch, getState) => {
        const locale = getState().i18n.locale;
        dispatch(setUserValue(PROP_KEYS.isLoadedLogin, false));

        userService.personalLogin(userName, parseInt(bookingNumber), locale)
            .then(
                ({ token, user }) => {
                    dispatch(success(token));
                    dispatch(request(user));

                    if (user.role && user.role.includes(USER_ROLES.Admin)) {
                        history.push(`/${locale}/admin`);
                    } else {
                        history.push(`/${locale}/user`);
                    }
                },
                error => {
                    dispatch(failure(error));
                    notification.error({
                        message: error.toString()
                    })
                }
            )
            .finally(() => dispatch(setUserValue(PROP_KEYS.isLoadedLogin, true)));
    };
}

const companySignin = (companyInfo) => {
    return (dispatch, getState) => {
        const locale = getState().i18n.locale;
        dispatch(request({ name: companyInfo.name }));
        dispatch(setUserValue(PROP_KEYS.isLoadedSignin, false));

        userService.companySignin(companyInfo, locale)
            .then(
                ok => {
                    notification.success({
                        message: `Company account successfully created!`
                    });
                    history.push(`/${locale}/admin-accounts`);
                },
                error => {
                    dispatch(failure(error));
                    notification.error({
                        message: error.toString()
                    })
                }
            )
            .finally(() => {
                dispatch(setUserValue(PROP_KEYS.isLoadedSignin, true));
                history.push(`/${locale}/admin-accounts`);
            });
    };
}

const personalSignin = (userInfo) => {
    return (dispatch, getState) => {
        const locale = getState().i18n.locale;
        dispatch(request({ name: userInfo.name }));
        dispatch(setUserValue(PROP_KEYS.isLoadedSignin, false));

        userService.personalSignin(userInfo, locale)
            .then(
                ok => {
                    notification.success({
                        message: `Personal account successfully created!`
                    });
                    history.push(`/${locale}/admin-accounts`);
                },
                error => {
                    dispatch(failure(error));
                    notification.error({
                        message: error.toString()
                    })
                }
            )
            .finally(() => dispatch(setUserValue(PROP_KEYS.isLoadedSignin, true)));
    };
}

const resetPassword = (info) => {
    return (dispatch, getState) => {
        dispatch(setUserValue(PROP_KEYS.isLoadedLogin, false));
        const locale = getState().i18n.locale;

        userService.resetPassword(info, locale)
            .then(
                ok => {
                    notification.success({
                        message: `Password successfully reseted!`
                    });
                    history.push(`/login`);
                }
            )
            .catch((error) => notification.error({
                message: JSON.parse(error).toString()
            }))
            .finally(() => dispatch(setUserValue(PROP_KEYS.isLoadedLogin, true)))
    };
}

const forgotPassword = (email) => {
    return (dispatch, getState) => {
        dispatch(setUserValue(PROP_KEYS.isLoadedLogin, false));
        const locale = getState().i18n.locale;

        userService.forgotPassword(email, locale)
            .then(
                ok => { 
                    history.push(`/resend-password`);
                }
            )
            .catch((error) => notification.error({
                message: JSON.parse(error).toString()
            }))
            .finally(() => dispatch(setUserValue(PROP_KEYS.isLoadedLogin, true)))
    };
}

const changePassword = (passwordObj) => {
    return (dispatch, getState) => {
        dispatch(setUserValue(PROP_KEYS.isLoadedChangePassword, false));
        const email = getState().authentication.user.email;
        const info = { email, ...passwordObj };
        
        userService.changePassword(info)
            .then(() => {
                dispatch(setUserValue(PROP_KEYS.newPassword, null));
                dispatch(setUserValue(PROP_KEYS.oldPassword, null));
                dispatch(setUserValue(PROP_KEYS.repeatNewPassword, null));
                notification.success({
                    message: `Password successfully updated!`
                });
            })
            .catch((error) => notification.error({
                message: JSON.parse(error).toString()
            }))
            .finally(() => {
                dispatch(setUserValue(PROP_KEYS.isLoadedChangePassword, true));
            })
    }
}

const updateProfileDetails = () => {
    return (dispatch, getState) => {
        dispatch(setUserValue(PROP_KEYS.isLoadedChangePassword, false));
        const user = getState().authentication.user;
        const updateProfileDetailsObj = { email: user.email, fullName: user.fullName, phone: user.phone };

        userService.updateProfileDetails(updateProfileDetailsObj)
            .then(() => {
                notification.success({
                    message: `Basic info successfult updated!`
                });
                Cookies.set('user', JSON.stringify(user));
                dispatch(setSubValue(PROP_KEYS.user, 'fullName', user.fullName));
            })
            .catch((error) => notification.error({
                message: JSON.parse(error).toString()
            }))
            .finally(() => {
                dispatch(setUserValue(PROP_KEYS.isLoadedChangePassword, true));
            })
    }
}

const loadMyOrders = () => {
    return (dispatch, getState) => {
        const { myOrdersCurrentPage, myOrdersItemsPerPage } = getState().authentication;
        dispatch(setUserValue(PROP_KEYS.isLoadedMyOrders, false));

        userService.loadMyOrders(myOrdersCurrentPage, myOrdersItemsPerPage)
                   .then(({ myOrders, pagination }) => {
                        dispatch(setUserValue(PROP_KEYS.myOrders, myOrders));
                        dispatch(setUserValue(PROP_KEYS.myOrdersCurrentPage, pagination.offset));
                        dispatch(setUserValue(PROP_KEYS.myOrdersItemsPerPage, pagination.limit));
                        dispatch(setUserValue(PROP_KEYS.myOrdersTotal, pagination.total));
                   })
                   .finally(() => dispatch(setUserValue(PROP_KEYS.isLoadedMyOrders, true)));
    };
}

const loadProfileDetails = () => {
    return (dispatch) => {
        dispatch(setUserValue(PROP_KEYS.isLoadedMyOrders, false));

        userService.loadProfileDetails()
                   .then(profileDetails => {
                        dispatch(setUserValue(PROP_KEYS.profileDetails, profileDetails));
                   })
                   .finally(() => dispatch(setUserValue(PROP_KEYS.isLoadedMyOrders, true)));
    };
}

const deleteTrip = (tripId) => {
    return (dispatch, getState) => {
        dispatch(setUserValue(PROP_KEYS.isLoadedMyOrders, false));
        const locale = getState().i18n.locale;

        userService.deleteTrip(tripId)
            .then(
                ok => {
                    dispatch(loadMyOrders());
                    notification.success({
                        message: `Trip #${tripId} successfully deleted`
                    })
                    history.push(`/${locale}/user`);
                },
                error => {
                    notification.error({
                        message: error.toString()
                    })
                }
            )
            .finally(() => dispatch(setUserValue(PROP_KEYS.isLoadedMyOrders, true)));
    };
}

const editTrip = (tripId) => {
    return (dispatch, getState) => {
        const locale = getState().i18n.locale;

        userService.editTrip(tripId)
            .then(
                trip => {
                    const mappedTrip = mapEditTrip(trip);

                    dispatch(homeActions.setAll({ 
                        ...mappedTrip,
                        tripId,
                        [HOME_PROP_KEYS.isLoadedResults]: true,
                        [HOME_PROP_KEYS.isLoadedCheckout]: true,
                        [HOME_PROP_KEYS.isOpenedResults]: false,
                        [HOME_PROP_KEYS.isOpenedCheckout]: false
                    }));
                    history.push(`/${locale}/edit`);
                },
                error => {
                    notification.error({
                        message: error.toString()
                    })
                }
            )
            .finally(() => dispatch(setUserValue(PROP_KEYS.isLoadedSignin, true)));

        
    }
}

const setCurrentPage = (page, pageSize) => {
    return (dispatch) => {
        dispatch(setUserValue(PROP_KEYS.myOrdersCurrentPage, page));
        dispatch(setUserValue(PROP_KEYS.myOrdersItemsPerPage, pageSize));
        dispatch(loadMyOrders());
    }
}

const setItemsPerPage = (current, size) => {
    return (dispatch) => {
        dispatch(setUserValue(PROP_KEYS.myOrdersCurrentPage, current));
        dispatch(setUserValue(PROP_KEYS.myOrdersItemsPerPage, size));
        dispatch(loadMyOrders());
    }
}

export const userActions = {
    companyLogin,
    personalLogin,
    companySignin,
    personalSignin,
    setUserValue,
    setSubValue,
    loadMyOrders,
    setCurrentPage,
    setItemsPerPage,
    resetPassword,
    changePassword,
    updateProfileDetails,
    forgotPassword,
    deleteTrip,
    editTrip
};

// reducer
let token = Cookies.get('token');
let user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : {};

const initialState = { 
    [PROP_KEYS.user]: user,
    [PROP_KEYS.token]: token,
    [PROP_KEYS.isLoadedMyOrders]: true,
    [PROP_KEYS.myOrders]: [],
    [PROP_KEYS.myOrdersCurrentPage]: 1,
    [PROP_KEYS.myOrdersItemsPerPage]: 5,
    [PROP_KEYS.myOrdersTotal]: DEFAULT_ZERO_VALUE,
    [PROP_KEYS.isLoadedLogin]: true,
    [PROP_KEYS.isLoadedSignin]: true,
    [PROP_KEYS.isLoadedChangePassword]: true,
    [PROP_KEYS.oldPassword]: null,
    [PROP_KEYS.newPassword]: null,
    [PROP_KEYS.repeatNewPassword]: null
};

export const authentication = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.SET_USER_VALUE: {
            const { key, value } = action;

            return {
                ...state,
                [key]: value
            };
        }

        case TYPES.SET_SUB_VALUE : {
            const { key, subKey, value } = action;

            return {
                ...state,
                [key]: {
                    ...state[key],
                    [subKey]: value
                }
            };
        }

        case TYPES.LOGIN_REQUEST:
            return {
                ...state,
                user: action.user
            };
        case TYPES.LOGIN_SUCCESS:
            return {
                ...state,
                token: action.token
            };
        case TYPES.LOGIN_FAILURE:
            return {
                ...initialState
            };
        case TYPES.LOGOUT:
            return {
                ...initialState
            };
        default:
            return state;
    }
}

const mapEditTrip = (trip) => {
    let addedOptions = {};
    if (trip.selectedOptions) {
        trip.selectedOptions.forEach(x => {
            if (x.subAdditionalOptionName) {
                const added = addedOptions[x.additionalOptionName];
                addedOptions = { 
                    ...addedOptions, 
                    [x.additionalOptionName]: (added || DEFAULT_ZERO_VALUE) + x.quantity,
                    [x.subAdditionalOptionName]: x.quantity
                }
            } else {
                addedOptions = { 
                    ...addedOptions, 
                    [x.additionalOptionName]: x.quantity
                }
            }
        })
    }

    return {
        pickupLocation: mapToLatLng(trip.pickupLocationAddressCoordinate),
        destination: mapToLatLng(trip.dropoffLocationAddressCoordinate),
        pickupLocationDatetime: trip.pickupLocationDatetime,
        hourlyBooking: trip.hourlyBooking,
        hourlyBookingHours: (!trip.hourlyBookingHours || trip.hourlyBookingHours === DEFAULT_ZERO_VALUE) ? 4 : trip.hourlyBookingHours,
        addedOptions,
        pickupLocationString: trip.pickupLocationString,
        destinationString: trip.destinationString,
        promocode: trip.promocode,
        promoDiscount: trip.promoDiscount,
        userComment: trip.comments,
        currency: trip.currency,
        oldTotal: trip.total,
        paymentMethodToggle: trip.paymentMethodToggle === null ? PAYMENT_METHODS.Invoice : trip.paymentMethodToggle,
        flightNumber: trip.flightNumber,
        returnFlightNumber: trip.returnFlightNumber,
        tripContactId: trip.tripContact.id || `${DEFAULT_ZERO_VALUE}`,
        userEmail: trip.tripContact.email,
        userFullName: trip.tripContact.fullName,
        userPhone: trip.tripContact.phone
    };
}

const mapToLatLng = (address) => {
    const { Latitude, Longitude } = JSON.parse(address);

    return { lat: Latitude, lng: Longitude }
}