import React from "react";
import { I18n as Translater } from 'react-redux-i18n';
import { USER_INPUT_TYPES } from '../assets/constants/global';
import { UserInput } from './user-input';
import { Modal } from 'antd';
import I18n from '../i18n';
import Cookies from 'js-cookie';
import { SubmitButton } from './submit-button';

export const RouteNotFound = (props) => {
    const { onCancel, setNotFoudName, setNotFoudEmail, routeNotFoundSubmit, notFoundEmail, notFoundName, isLoaded } = props;
    const user = Cookies.get('user') && JSON.parse(Cookies.get('user'));
    
    return (
        <div id='route-not-found'>
            <Modal title="RouteNotFound Modal"
                   visible={true}
                   centered={true}
                   footer={null}
                   maskClosable={false}
                   onCancel={onCancel}
                   getContainer={() => document.getElementById('route-not-found')}>
                <div className="d-flex flex-column align-items-center">
                    <span className="payment-title mt-3 mb-3"><I18n t="routeNotFoundTitle" /></span>
                    <span className="payment-subtitle mt-3 mb-3">{ user ? <I18n t="routeNotFoundForLoginedUserMsg" /> : <I18n t="routeNotFoundMsg" /> }</span>
                    <div className="d-flex justify-content-center w-100">
                        {
                            user ? (
                                <SubmitButton loading={!isLoaded} label={Translater.t('submitTitle')} onClick={routeNotFoundSubmit} />
                            ) : (
                                <div className="d-flex logined">
                                    <UserInput validate={false} placeholder={Translater.t('nameTitle')} containerClassName='mr-3' required={true} type={USER_INPUT_TYPES.string} onChange={setNotFoudName} />
                                    <UserInput placeholder={Translater.t('emailTitle')} containerClassName='mr-3' required={true} type={USER_INPUT_TYPES.email} onChange={setNotFoudEmail} />
                                    <SubmitButton loading={!isLoaded} label={'✓'} onClick={routeNotFoundSubmit} disabled={!(notFoundEmail && notFoundName)} />
                                </div>
                            )
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}
    