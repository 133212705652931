import React from "react";
import moment from "moment";
import { DatePicker, Dropdown, Menu } from 'antd';
import calendar from '../assets/icons/calendar.png';
import time from '../assets/icons/time.png';
import I18n from '../i18n';
import { timeList } from '../helpers/date-helper';
import { STRING_EMPTY } from '../assets/constants/global';

const { Item } = Menu;

export class LocationDateTime extends React.Component {
    constructor(props) {
        super(props);
        const { value } = props;

        this.state = {
            value: moment(value) || moment({ hour: 0, minute: 0 }),
            days: this.createDates(value)
        }
    }

    setDay = (day) => {
        this.setState(state => {
            const hours = state.value.get('hours');
            const minutes = state.value.get('minutes');
            day.set({ 'hours': hours, 'minutes': minutes });

            return {
                value: day
            }
        }, () => this.props.setDate(this.state.value));
    }

    setDate = (date) => {
        if (date) {
            this.setState(state => {
                const hours = state.value.get('hours');
                const minutes = state.value.get('minutes');
                date.set({ 'hours': hours, 'minutes': minutes });

                return {
                    value: date,
                    days: this.createDates(date)
                }
            }, () => this.props.setDate(date));
        }
    }

    setTime = (time) => {
        if (time) {
            const hours = time.get('hours');
            const minutes = time.get('minutes');

            this.setState(state => {
                const newDate = moment({ ...state.value });
                newDate.set({ 'hours': hours, 'minutes': minutes });

                return {
                    value: newDate
                }
            }, () => this.props.setDate(this.state.value));
        }
    }

    createDates = (date) => {
        const firstDay = moment(date) && moment(date).isValid() ? date : moment();

        return [
            moment(firstDay),
            moment(firstDay).add(1, "day"),
            moment(firstDay).add(2, "day")
        ]
    }

    renderDays = () => {
        const { prevValue } = this.props;
        const { days, value } = this.state;

        return days.map(day => {
            const dayName = moment().isSame(day, "day") ? 'today'
                          : moment().add(1, 'day').isSame(day, "day") ? 'tomorrow'
                          : day.format('ddd');
            const dayClassName = day.isSame(value, "day") ? 'selected' : STRING_EMPTY;
            const validClassName = prevValue && day.isBefore(prevValue) ? 'invalid' : STRING_EMPTY;

            return (
                <div key={day.format('LLL')} className={`day-tile ${dayClassName} ${validClassName}`} onClick={() => this.setDay(day)}>
                    <span>{day.format('DD')}</span>
                    <span className="text-lowercase">{dayName}</span>
                </div>
            )
        })
    }
 
    render() {
        const { disabledDate, disabledTime } = this.props;
        const { value } = this.state;
        const hours = timeList(disabledTime, value);
        const dropdownHours = (
            <Menu>
                {
                    hours.map((hour, index) => {
                        const timeClassName = moment(hour).isSame(value, "hour") && moment(hour).isSame(value, "minute") ? 'selected' : STRING_EMPTY;
                
                        return (
                            <Item key={`hour_${index}`}
                                       className={timeClassName}
                                       onClick={() => this.setTime(hour)}>
                                {moment(hour).format('HH:mm')}
                            </Item>
                        );
                    })
                }
            </Menu>
        );

        return (
            <div className="w-100 d-flex location-date-time">
                {this.renderDays()}
                <div className="day-tile">
                    <DatePicker onChange={this.setDate}
                                allowClear={false}
                                disabledDate={disabledDate}
                                suffixIcon={<img src={calendar} alt="Select date" />} />
                </div>
                <div className="time-tile d-flex flex-row">
                    <Dropdown getPopupContainer={trigger => trigger.parentNode} overlay={dropdownHours} placement="bottomCenter" trigger={['click']}>
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-column">
                                <span><I18n t="timeTitle" /></span>
                                <span>{moment(value).format('HH:mm')}</span>
                            </div>
                            <img className="time-img" src={time} alt="Select time" />
                        </div>
                    </Dropdown>
                </div>
            </div>
        )
    }
}