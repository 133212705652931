import React from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './helpers/history';
import { HomePage } from './pages/home';
import { About } from './pages/about';
import { FAQ } from './pages/faq';
import { Partners } from './pages/partners';
import { PrivacyAndTerms } from './pages/privacy-and-terms';
import { SuccessPayment } from './pages/success-payment';
import { CancelPayment } from './pages/cancel-payment';
import { SuccessConfirm } from './pages/success-confirm';
import { baseUrl } from "./i18n/config";
import { PrivateRoute } from './common/private-route';
import { AdminRoute } from './common/admin-route';
import { UserProfile } from './pages/user';
import { TripDetails } from './pages/user/user-trip-details';
import { ResendPassword } from './pages/resend-password';
import { AdminLocations } from './pages/admin-panel/locations';
import { AdminAdditionalOptions } from './pages/admin-panel/additional-options';
import { AdminVip } from './pages/admin-panel/vip';
import { AdminAffiliates } from './pages/admin-panel/affiliates';
import { AdminAffiliateSettlements } from './pages/admin-panel/affiliate-settlements';
import { AdminVehicles } from './pages/admin-panel/vehicles';
import { AdminExtraPrices } from './pages/admin-panel/extra-prices';
import { AdminPaymentRules } from './pages/admin-panel/payment-rules';
import { AdminRoutes } from './pages/admin-panel/routes';
import { AdminTrips } from './pages/admin-panel/trips';
import { AdminTripSettlements } from './pages/admin-panel/trip-settlements';
import { AdminAccounts } from './pages/admin-panel/accounts';
import { AdminDispatch } from './pages/admin-panel/dispatch';
import { AdminNotFoundRoutes } from './pages/admin-panel/not-found-routes';
import { AdminDiscounts } from './pages/admin-panel/discount';
import { AdminUnfinalizedTrips } from './pages/admin-panel/unfinalized-trips';

import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import './assets/styles/app.scss';
import { HomeRoute } from './common/home-route';

class App extends React.Component {
    render() {
        return (
            <Router history={history}>
                <AdminRoute path={`${baseUrl}/admin`} component={AdminDispatch} />
                <AdminRoute path={`${baseUrl}/admin-locations`} component={AdminLocations} />
                <AdminRoute path={`${baseUrl}/admin-additional-options`} component={AdminAdditionalOptions} />
                <AdminRoute path={`${baseUrl}/admin-vip`} component={AdminVip} />
                <AdminRoute path={`${baseUrl}/admin-affiliates`} component={AdminAffiliates} />
                <AdminRoute path={`${baseUrl}/admin-affiliate-settlements`} component={AdminAffiliateSettlements} />
                <AdminRoute path={`${baseUrl}/admin-vehicles`} component={AdminVehicles} />
                <AdminRoute path={`${baseUrl}/admin-extra-prices`} component={AdminExtraPrices} />
                <AdminRoute path={`${baseUrl}/admin-payment-rules`} component={AdminPaymentRules} />
                <AdminRoute path={`${baseUrl}/admin-routes`} component={AdminRoutes} />
                <AdminRoute path={`${baseUrl}/admin-not-found-routes`} component={AdminNotFoundRoutes} />
                <AdminRoute path={`${baseUrl}/admin-trips`} component={AdminTrips} />
                <AdminRoute path={`${baseUrl}/admin-trip-settlements`} component={AdminTripSettlements} />
                <AdminRoute path={`${baseUrl}/admin-unfinalized-trips`} component={AdminUnfinalizedTrips} />
                <AdminRoute path={`${baseUrl}/admin-discounts`} component={AdminDiscounts} />
                <AdminRoute path={`${baseUrl}/admin-accounts`} component={AdminAccounts} />
                <AdminRoute path={`${baseUrl}/signin`} component={AdminAccounts} componentProps={{ signin: true }} />
                <PrivateRoute path={`${baseUrl}/user`} component={UserProfile} componentProps={{ withoutBackgroundImage: true }} />
                <PrivateRoute path={`${baseUrl}/trip-details/:tripId`} component={TripDetails} />
                <HomeRoute path={`${baseUrl}/home`} component={HomePage} />
                <Route exact path={`${baseUrl}/`} render={() => <HomePage edit={false} /> } />
                <Route path={`${baseUrl}/login`} render={() => <HomePage login /> } />
                <Route path={`${baseUrl}/edit`} render={() => <HomePage edit /> } />
                <Route path={`${baseUrl}/driver-confirmation/:token`} render={(props) => <HomePage driver {...props} /> } />
                <Route path={`${baseUrl}/agent-confirmation/:token`} render={(props) => <HomePage agent {...props} /> } />
                <Route path={`${baseUrl}/about`} render={() => <About withoutBackgroundImage={true} /> } />
                <Route path={`${baseUrl}/faq`} render={() => <FAQ withoutBackgroundImage={true} /> } />
                <Route path={`${baseUrl}/partners`} render={() => <Partners withoutBackgroundImage={true} /> } />
                <Route path={`${baseUrl}/forgot-password`} render={(props) => <HomePage forgotPassword {...props} /> } />
                <Route path={`${baseUrl}/resend-password`} render={() => <ResendPassword /> } />
                <Route path={`${baseUrl}/email-confirm`} render={() => <SuccessConfirm email /> } />
                <Route path={`${baseUrl}/success-confirm`} render={() => <SuccessConfirm /> } />
                <Route path={`${baseUrl}/success-payment`} render={() => <SuccessPayment /> } />
                <Route path={`${baseUrl}/cancel-payment`} render={() => <CancelPayment /> } />
                <Route path={`${baseUrl}/cancel-payment/:sessionId`} render={(props) => <CancelPayment {...props} /> } />
                <Route path={`${baseUrl}/cancel-payment/edit/:sessionId`} render={(props) => <CancelPayment edit {...props} /> } />
                <Route path={`${baseUrl}/privacy-and-terms`} render={() => <PrivacyAndTerms withoutBackgroundImage={true} /> } />
            </Router>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 