import React from 'react';
import { connect } from 'react-redux';
import { withAdminLayout } from '../../common/admin-layout';
import { ADMIN_PAGES, STRING_EMPTY, DEFAULT_ZERO_VALUE, DISPATCH_SEARCH_DEFAULT_VALUE } from '../../assets/constants/global';
import { adminActions } from './admin-reducer';
import { BaseAdmin } from './base';
import {
    ClearOutlined
} from '@ant-design/icons';
import { PROP_KEYS, ACTION_LOAD_TYPES, TEMPLATE_TYPES, PAYMENT_STATUSES } from './admin-constants';
import { Tooltip, Select, Input, Button, DatePicker } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

class AdminDispatch extends React.Component {
    componentDidMount() {
        const { loadData } = this.props;

        loadData();
    }

    getClassByStatus = (tripStatus) => {
        return {
            [PAYMENT_STATUSES.PAYMENT_CONFIRMED_WITHOUT_DETAILS]: 'incompleted',
            [PAYMENT_STATUSES.PAYMENT_DONE]: 'succeeded ',
            [PAYMENT_STATUSES.PAYMENT_CONFIRMED]: 'canceled',
            [PAYMENT_STATUSES.PAYMENT_UNCONFIRMED]: 'failed',
        }[tripStatus];
    }

    render() {
        const {
            dispatches, doneTrips = [], setDoneTrips, markAsDone, handleDataPageChange, handleDataPageSizeChange, dispatchPagination, isLoadedDispatch, handleSort, dispatchColumnTypes,
            setSearchBy, setSearchText, setSearchToday, setSearchYesterday, setSearchRange, clearSearch
        } = this.props;
        const title = (text) => <Tooltip title={text}><div className='text-truncate text-uppercase title-header'>{text}</div></Tooltip>;
        const columns = [{
            key: 'id',
            dataIndex: 'id',
            width: `8%`,
            title: title('Id'),
            render: (text, { tripStatus, isVip }) => (
                <div className="d-flex align-items-center flex-column">
                    <div className={`text-truncate ${this.getClassByStatus(tripStatus)}`}>{text}</div>
                    { isVip && <span className='vip-label mt-2'>VIP</span> }
                </div>
            )
        }, {
            key: 'date',
            dataIndex: 'date',
            width: '10%',
            title: title('Date, Time'),
            render: (text) => {
                const date = moment(text);
                const dayName = moment().isSame(date, "day") ? 'today'
                    : moment().add(-1, 'day').isSame(date, "day") ? 'yesterday'
                        : date.format('DD MMM');

                return (
                    <div className="d-flex flex-column">
                        <div>{dayName}</div>
                        <div>{date.format('HH:mm')}</div>
                    </div>
                )
            }
        }, {
            key: 'passenger',
            dataIndex: 'passenger',
            width: '10%',
            title: title('Passenger'),
            render: ({ fullName, email, phone }) => {
                const value = fullName ? `${fullName}` : email;

                return (
                    <div className="d-flex flex-column">
                        <div style={{ maxWidth: '120px' }} className='text-truncate'><Tooltip title={value}>{value}</Tooltip></div>
                        { phone && <div style={{ maxWidth: '120px' }} className='text-truncate'><Tooltip title={phone}>{phone}</Tooltip></div> }
                    </div>
                )
            }
        }, {
            key: 'departure',
            dataIndex: 'departure',
            width: '10%',
            title: title('Departure, Appointment'),
            render: (_text, { pickupLocationAddress, dropoffLocationAddress }) => (
                <div className="d-flex flex-column">
                    <div style={{ maxWidth: '140px' }} className='text-truncate'><Tooltip title={pickupLocationAddress}>{pickupLocationAddress}</Tooltip></div>
                    <div style={{ maxWidth: '140px' }} className='text-truncate'><Tooltip title={dropoffLocationAddress}>{dropoffLocationAddress}</Tooltip></div>
                </div>
            )
        }, {
            key: 'companyName',
            dataIndex: 'companyName',
            width: '10%',
            title: title('Company'),
            render: (text) => (
                <div className="d-flex flex-column">
                    {text || 'Private'}
                </div>
            )
        }, {
            key: 'comments',
            dataIndex: 'comments',
            width: '10%',
            title: title('Comment'),
            render: (text) => (
                <div className="d-flex flex-column">
                    {text || STRING_EMPTY}
                </div>
            )
        }, {
            key: 'tripAdditionalOptions',
            dataIndex: 'tripAdditionalOptions',
            width: '15%',
            title: title('Additional Options'),
            render: (tripAdditionalOptions, { affiliate }) => (
                <div className="d-flex flex-column">
                    {
                        tripAdditionalOptions && tripAdditionalOptions.map(({ additionOption, clientPrice }, idx) => (
                            <div key={`tripAdditionalOptions_${idx}`} className="d-flex justify-content-between w-100">
                                <Tooltip title={additionOption.description}>{additionOption.name}</Tooltip>
                                <div className="lighter">{ clientPrice === DEFAULT_ZERO_VALUE ? 'Free' : `${clientPrice}${affiliate && affiliate.currency && getSymbolFromCurrency(affiliate.currency)}`}</div>
                            </div>
                        ))
                    }
                </div>
            )
        }, {
            key: 'affiliate',
            dataIndex: 'affiliate',
            width: '12%',
            title: title('Affiliate Company'),
            render: ({ name, phone }) => (
                <div className="d-flex flex-column">
                    <div style={{ maxWidth: '140px' }} className='text-truncate'><Tooltip title={name}>{name}</Tooltip></div>
                    <div style={{ maxWidth: '140px' }} className='text-truncate'><Tooltip title={phone}>{phone}</Tooltip></div>
                </div>
            )
        }, {
            key: 'driver',
            dataIndex: 'driver',
            width: '12%',
            title: title('Driver/Agent'),
            render: (driver, { agent, isVip }) => {
                const value = driver && `${driver.fullName}`;
                const phone = driver && driver.phone;

                return (
                    <div className="d-flex flex-column">
                        <div style={{ maxWidth: '140px' }} className='text-truncate'><Tooltip title={value}>{value}</Tooltip></div>
                        <div style={{ maxWidth: '140px' }} className='text-truncate'><Tooltip title={phone}>{phone}</Tooltip></div>
                    </div>
                )
            }
        }];

        const rowSelection = {
            columnTitle: <div>DONE</div>,
            selectedRowKeys: doneTrips,
            onChange: setDoneTrips,
            getCheckboxProps: record => ({
                disabled: record.tripStatus === 'Done', // Column configuration not to be checked
            }),
        };

        const header = (
            <div className='d-flex dispatch-header'>
                <div className='d-flex justify-content-between w-100 main-filters'>
                    <div className="d-flex">
                        <Button className="date-button" onClick={markAsDone}>Mark as done</Button>
                    </div>
                    <div className="d-flex">
                        <Select defaultValue="Passenger" value={dispatchPagination.searchBy} style={{ width: 120 }} onChange={setSearchBy}>
                            <Option value="Passenger">Passenger</Option>
                            <Option value="PickupLocationAddress">PickupLocationAddress</Option>
                            <Option value="DropoffLocationAddress">DropoffLocationAddress</Option>
                            <Option value="CompanyName">CompanyName</Option>
                            <Option value="TripStatus">TripStatus</Option>
                            <Option value="Comments">Comments</Option>
                            <Option value="Affiliate">Affiliate</Option>
                            <Option value="Driver">Driver</Option>
                        </Select>
                        <Search
                            placeholder="Input Search Text"
                            onSearch={setSearchText}
                            style={{ width: 200 }}
                        />
                    </div>
                    <div className="d-flex">
                        <Button className="date-button" onClick={setSearchToday}>Today</Button>
                        <Button className="date-button" onClick={setSearchYesterday}>Yesterday</Button>
                        <RangePicker className="date-button" onChange={setSearchRange} />
                        <Button style={{ minWidth: '40px', padding: '0'}} className="date-button" onClick={() => clearSearch(DISPATCH_SEARCH_DEFAULT_VALUE)}><ClearOutlined /></Button>
                    </div>
                </div>
                <div className="d-flex statuses">
                    <div className="col">
                        <div><span style={{ background: '#6bbd80' }} className="dot"></span> Done</div>
                        <div><span style={{ background: '#f3d789' }} className="dot"></span> Confirmed</div>
                    </div>
                    <div className="col">
                        <div><span style={{ background: '#ff4d4f' }} className="dot"></span> Un-Confirmed</div>
                        <div><span style={{ background: '#959CAC' }} className="dot"></span> Confirmed without details</div>
                    </div>
                </div>
            </div>
        );
        const baseProps = {
            data: dispatches,
            isLoaded: isLoadedDispatch,
            pagination: dispatchPagination,
            handlePageChange: handleDataPageChange,
            handlePageSizeChange: handleDataPageSizeChange,
            sortData: handleSort,
            columnTypes: dispatchColumnTypes,
            showImport: false,
            showTemplate: false,
            showExport: false,
            specialColumns: columns,
            specialHeader: header,
            rowSelection,
            specialRowKey: x => `${x.id}_${x.isVip}`
        };

        return (
            <BaseAdmin {...baseProps} />
        );
    }
}

const mapStateToProps = (state) => {
    const { admin } = state;
    const { dispatches, doneTrips, dispatchPagination, isLoadedDispatch, dispatchColumnTypes } = admin;

    return {
        dispatches, doneTrips, isLoadedDispatch, dispatchPagination, dispatchColumnTypes
    };
}

const { action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey } = {
    action: ACTION_LOAD_TYPES.dispatch,
    isLoadedKey: PROP_KEYS.isLoadedDispatch,
    paginationKey: PROP_KEYS.dispatchPagination,
    dataKey: PROP_KEYS.dispatches,
    fileKey: TEMPLATE_TYPES.Dispatch,
    columnTypesKey: PROP_KEYS.dispatchColumnTypes
}

const mapDispatchToProps = {
    loadData: () => adminActions.load({
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleDataPageChange: (page, pageSize) => adminActions.handlePaginationChange(page, pageSize, {
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleDataPageSizeChange: (_page, pageSize) => adminActions.handlePaginationChange(1, pageSize, {
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleSort: (_pagination, _filters, sorter) => adminActions.handleSort(1, sorter, {
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    setSearchBy: (text) => adminActions.setSubValue(paginationKey, 'searchBy', text),
    setSearchText: (text) => adminActions.setSearchText(text, { action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey }),
    setSearchToday: () => adminActions.setSearchDate(moment.utc().startOf('day'), { action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey }),
    setSearchYesterday: () => adminActions.setSearchDate(moment.utc().add(-1, 'day').startOf('day'), { action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey }),
    setSearchRange: (dates) => adminActions.setSearchRange(dates, { action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey }),
    clearSearch: (defaultValue) => adminActions.clearSearch(defaultValue, { action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey }),
    setDoneTrips: (keys) => adminActions.setValue(PROP_KEYS.doneTrips, keys),
    markAsDone: () => adminActions.markAsDone({
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    })
}

const connectedAdminDispatch = connect(mapStateToProps, mapDispatchToProps)(withAdminLayout(AdminDispatch, ADMIN_PAGES.AdminDispatch));
export { connectedAdminDispatch as AdminDispatch };