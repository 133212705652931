import React from "react";
import { Switch } from 'antd';

export const ToggleWithLabel = ({ label, checked, onChange, disabled = false }) => {
    return (
        <div className="toggle-with-label">
            <Switch {...{checked, onChange, disabled}} />
            <span>{label}</span>
        </div>
    )
}