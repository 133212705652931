import React from 'react';
import { connect } from 'react-redux';

import { withAdminLayout } from '../../common/admin-layout';
import { ADMIN_PAGES, DEFAULT_ZERO_VALUE, FREE_COST, AFFILIATE_SETTLEMENT_SEARCH_DEFAULT_VALUE } from '../../assets/constants/global';
import { adminActions } from './admin-reducer';
import { BaseAdmin } from './base';
import { PROP_KEYS, ACTION_LOAD_TYPES, ACTION_UPDATE_TYPES } from './admin-constants';

import { Tooltip, Select, Input, Button, Checkbox } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';

const { Option } = Select;
const { Search } = Input;

class AdminAffiliateSettlements extends React.Component {
    componentDidMount() {
        const { loadData } = this.props;
        loadData();
    }

    onSelectChange = selectedRowKeys => {
        const { checkInvoiced } = this.props;
        checkInvoiced(selectedRowKeys);
    };

    render() {
        const {
            affiliateSettlements, affiliateSettlementsErrorMsg, handleDataPageChange, handleDataPageSizeChange,
            setSearchBy, setSearchText, setInvoiced, affiliateSettlementsInvoiced,
            affiliateSettlementsPagination, isLoadedaffiliateSettlements, affiliateSettlementsColumnTypes
        } = this.props;

        const title = (text) => <Tooltip title={text}><div className='text-truncate text-uppercase title-header'>{text}</div></Tooltip>;

        const columns = [{
            key: 'Invoiced',
            dataIndex: 'trips',
            title: title('Invoiced'),
            width: '5%',
            render: (trips) => (
                trips.map(item =>
                    <div className="mt-1" key={`${item.id}_${item.hasVipService}`}>
                        <Checkbox value={`${item.id}_${item.hasVipService}`}></Checkbox>
                    </div>
                )
            )
        }, {
            key: 'id',
            dataIndex: 'trips',
            width:"15%",
            title: title('Un-Settled Trips'),
            render: trips => trips.map(item => (
                <div className="d-flex mt-1" key={item.id}>
                    <div className="text-truncate">{item.id}</div>
                    {item.hasVipService === true ? <div className="vip-label ml-3">VIP</div> : ''}                    
                </div>)
            )
        }, {
            key: 'total',
            dataIndex: 'total',
            width:"15%",
            title: title('Order Cost'),
            render: (total, { currency }) => (
                <div className="d-flex flex-column">
                    <div>{total === DEFAULT_ZERO_VALUE ? FREE_COST : `${total}${currency && getSymbolFromCurrency(currency)}`}</div>
                </div>
            )
        }, {
            key: 'affiliateName',
            dataIndex: 'affiliateName',
            width:"25%",
            title: title('Supplier'),
            render: (affiliateName) => <div className={`text-truncate`}>{affiliateName}</div>
        }, {
            key: 'comment',
            dataIndex: 'trips',
            width:"40%",
            title: title('Comments'),
            render: trips => trips.map(item => <div className={`text-truncate`} key={item.id}>{item.comment}</div>)
        }];

        const header = (
            <div className='d-flex w-100'>
                <div className="d-flex">
                    <Select defaultValue={AFFILIATE_SETTLEMENT_SEARCH_DEFAULT_VALUE} value={affiliateSettlementsPagination.searchBy} style={{ width: 150 }} onChange={setSearchBy}>
                        <Option value="Id">Un-Settled Trips</Option>
                        <Option value="AffiliateName">Supplier</Option>
                        <Option value="Comments">Comments</Option>
                    </Select>
                    <Search
                        placeholder="Input Search Text"
                        onSearch={setSearchText}
                        style={{ width: 200 }}
                    />
                </div>
                <div className="d-flex">
                    <Button className="date-button" onClick={setInvoiced}>Mark as invoiced</Button>
                </div>


            </div>
        );
        const baseProps = {
            data: affiliateSettlements,
            isLoaded: isLoadedaffiliateSettlements,
            errorMsg: affiliateSettlementsErrorMsg,
            pagination: affiliateSettlementsPagination,
            handlePageChange: handleDataPageChange,
            handlePageSizeChange: handleDataPageSizeChange,
            showImport: false,
            showTemplate: false,
            showExport: false,
            columnTypes: affiliateSettlementsColumnTypes,
            specialColumns: columns,
            specialHeader: header,
        };

        return (
            <Checkbox.Group className="d-flex flex-column w-100 align-items-center" onChange={this.onSelectChange} value={affiliateSettlementsInvoiced}>
                <BaseAdmin {...baseProps} />
            </Checkbox.Group>
        );
    }
}

const mapStateToProps = (state) => {
    const { admin } = state;
    const { affiliateSettlements, affiliateSettlementsPagination, isLoadedaffiliateSettlements, affiliateSettlementsColumnTypes, affiliateSettlementsInvoiced } = admin;

    return {
        affiliateSettlements, isLoadedaffiliateSettlements, affiliateSettlementsPagination, affiliateSettlementsColumnTypes, affiliateSettlementsInvoiced
    };
}

const { action, updateCheckedDataAction, isLoadedKey, paginationKey, dataKey, columnTypesKey, checkedDataKey, hasVip } = {
    action: ACTION_LOAD_TYPES.affiliateSettlements,
    updateCheckedDataAction: ACTION_UPDATE_TYPES.affiliateSettlementsInvoiced,
    isLoadedKey: PROP_KEYS.isLoadedaffiliateSettlements,
    paginationKey: PROP_KEYS.affiliateSettlementsPagination,
    dataKey: PROP_KEYS.affiliateSettlements,
    columnTypesKey: PROP_KEYS.affiliateSettlementsColumnTypes,
    checkedDataKey: PROP_KEYS.affiliateSettlementsInvoiced,
    hasVip: true
}

const mapDispatchToProps = {
    loadData: () => adminActions.load({
        action, isLoadedKey, paginationKey, dataKey, columnTypesKey
    }),
    handleDataPageChange: (page, pageSize) => adminActions.handlePaginationChange(page, pageSize, {
        action, isLoadedKey, paginationKey, dataKey, columnTypesKey
    }),
    handleDataPageSizeChange: (_page, pageSize) => adminActions.handlePaginationChange(1, pageSize, {
        action, isLoadedKey, paginationKey, dataKey, columnTypesKey
    }),
    setSearchBy: (text) => adminActions.setSubValue(paginationKey, 'searchBy', text),
    setSearchText: (text) => adminActions.setSearchText(text, {
        action, isLoadedKey, paginationKey, dataKey, columnTypesKey
    }),
    checkInvoiced: (selectedKeys) => adminActions.markAsChecked(selectedKeys, {
        action, isLoadedKey, paginationKey, dataKey, columnTypesKey, checkedDataKey
    }),
    setInvoiced: () => adminActions.setChecked({
        action, updateCheckedDataAction, isLoadedKey, paginationKey, dataKey, columnTypesKey, checkedDataKey, hasVip
    }),
}

const connectedAdminAffiliateSettlements = connect(mapStateToProps, mapDispatchToProps)(withAdminLayout(AdminAffiliateSettlements, ADMIN_PAGES.AdminAffiliateSettlements));
export { connectedAdminAffiliateSettlements as AdminAffiliateSettlements };