import React from 'react';
import { connect } from 'react-redux';

import { withAdminLayout } from '../../common/admin-layout';
import { ADMIN_PAGES, DEFAULT_ZERO_VALUE, FREE_COST, INVOICED_COLUMN_TITLE, TRIP_SETTLEMENT_SEARCH_DEFAULT_VALUE } from '../../assets/constants/global';
import { adminActions } from './admin-reducer';
import { BaseAdmin } from './base';
import { PROP_KEYS, ACTION_LOAD_TYPES, TEMPLATE_TYPES, ACTION_UPDATE_TYPES } from './admin-constants';

import { ClearOutlined } from '@ant-design/icons';
import { Tooltip, Select, Input, Button, DatePicker } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

class AdminTripSettlements extends React.Component {
    componentDidMount() {
        const { loadData } = this.props;
        loadData();
    }

    onSelectChange = selectedRowKeys => {
        const { checkInvoiced } = this.props;
        checkInvoiced(selectedRowKeys);
    };

    render() {
        const {
            tripSettlements, tripSettlementsErrorMsg, handleDataPageChange, handleDataPageSizeChange, tripSettlementsInvoiced,
            setSearchBy, setSearchText, setSearchRange, clearSearch, setInvoiced,
            tripSettlementsPagination, isLoadedtripSettlements, tripSettlementsColumnTypes
        } = this.props;

        const rowSelection = {
            columnTitle: <div>{INVOICED_COLUMN_TITLE}</div>,
            selectedRowKeys: tripSettlementsInvoiced,
            onChange: this.onSelectChange,
        };

        const title = (text) => <Tooltip title={text}><div className='text-truncate text-uppercase title-header'>{text}</div></Tooltip>;

        const columns = [{
            key: 'id',
            dataIndex: 'id',
            title: title('Order Number'),
            render: (id) => <div className={`text-truncate`}>{id}</div>
        }, {
            key: 'company',
            dataIndex: 'company',
            title: title('Company'),
            render: (companyName) => <div className={`text-truncate`}>{companyName}</div>
        }, {
            key: 'date',
            dataIndex: 'date',
            title: title('Trip Date'),
            render: (tripDate) => {
                const date = moment(tripDate);
                const dayName = moment().isSame(date, "day") ? 'today'
                    : moment().add(-1, 'day').isSame(date, "day") ? 'yesterday'
                        : date.format('DD MMM');

                return (
                    <div className="d-flex flex-column">
                        <div>{dayName}</div>
                        <div>{date.format('HH:mm')}</div>
                    </div>
                )
            }
        }, {
            key: 'clientTotal',
            dataIndex: 'clientTotal',
            title: title('Cost'),
            render: (clientTotal, { currency }) => (
                <div className="d-flex flex-column">
                    <div>{clientTotal === DEFAULT_ZERO_VALUE ? FREE_COST : `${clientTotal}${currency && getSymbolFromCurrency(currency)}`}</div>
                </div>
            )
        }, {
            key: 'comments',
            dataIndex: 'comments',
            title: title('Comments'),
            render: (comments) => <div className={`text-truncate`}>{comments}</div>
        }];

        const header = (
            <div className='d-flex w-100'>
                <div className="d-flex">
                    <Select defaultValue={TRIP_SETTLEMENT_SEARCH_DEFAULT_VALUE} value={tripSettlementsPagination.searchBy} style={{ width: 150 }} onChange={setSearchBy}>
                        <Option value="OrderNumber">Order Number</Option>
                        <Option value="Company">Company</Option>
                        <Option value="Cost">Cost</Option>
                        <Option value="Comments">Comments</Option>
                    </Select>
                    <Search
                        placeholder="Input Search Text"
                        onSearch={setSearchText}
                        style={{ width: 200 }}
                    />
                </div>
                <div className="d-flex">
                    <RangePicker className="date-button" onChange={setSearchRange} />
                    <Button style={{ minWidth: '40px' }} className="date-button" onClick={() => clearSearch(TRIP_SETTLEMENT_SEARCH_DEFAULT_VALUE)}><ClearOutlined /></Button>
                </div>
                <div className="d-flex">
                    <Button className="date-button" onClick={setInvoiced}>Mark as invoiced</Button>
                </div>
                

            </div>
        );

        const baseProps = {
            data: tripSettlements,
            isLoaded: isLoadedtripSettlements,
            errorMsg: tripSettlementsErrorMsg,
            pagination: tripSettlementsPagination,
            handlePageChange: handleDataPageChange,
            handlePageSizeChange: handleDataPageSizeChange,
            showImport: false,
            showTemplate: false,
            showExport: false,
            columnTypes: tripSettlementsColumnTypes,
            specialColumns: columns,
            specialHeader: header,
            rowSelection: rowSelection,
        };

        return (
            <BaseAdmin {...baseProps} />
        );
    }
}

const mapStateToProps = (state) => {
    const { admin } = state;
    const { tripSettlements, tripSettlementsPagination, isLoadedtripSettlements, tripSettlementsColumnTypes, tripSettlementsInvoiced } = admin;

    return {
        tripSettlements, isLoadedtripSettlements, tripSettlementsPagination, tripSettlementsColumnTypes, tripSettlementsInvoiced
    };
}

const { action, updateCheckedDataAction, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey, checkedDataKey } = {
    action: ACTION_LOAD_TYPES.tripSettlements,
    updateCheckedDataAction: ACTION_UPDATE_TYPES.tripSettlementsInvoiced,
    isLoadedKey: PROP_KEYS.isLoadedtripSettlements,
    paginationKey: PROP_KEYS.tripSettlementsPagination,
    dataKey: PROP_KEYS.tripSettlements,
    fileKey: TEMPLATE_TYPES.TripSettlement,
    columnTypesKey: PROP_KEYS.tripSettlementsColumnTypes,
    checkedDataKey: PROP_KEYS.tripSettlementsInvoiced
}

const mapDispatchToProps = {
    loadData: () => adminActions.load({
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleDataPageChange: (page, pageSize) => adminActions.handlePaginationChange(page, pageSize, {
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleDataPageSizeChange: (_page, pageSize) => adminActions.handlePaginationChange(1, pageSize, {
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    setSearchBy: (text) => adminActions.setSubValue(paginationKey, 'searchBy', text),
    setSearchText: (text) => adminActions.setSearchText(text, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey 
    }),
    setSearchRange: (dates) => adminActions.setSearchRange(dates, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey 
    }),
    clearSearch: (defaultValue) => adminActions.clearSearch(defaultValue, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey 
    }),
    checkInvoiced: (selectedKeys) => adminActions.markAsChecked(selectedKeys, {
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey, checkedDataKey
    }),
    setInvoiced: () => adminActions.setChecked({
        action, updateCheckedDataAction, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey, checkedDataKey
    }),
}

const connectedAdminTripSettlements = connect(mapStateToProps, mapDispatchToProps)(withAdminLayout(AdminTripSettlements, ADMIN_PAGES.AdminTripSettlements));
export { connectedAdminTripSettlements as AdminTripSettlements };