import React, { Fragment } from 'react';
import moment, { locale } from 'moment';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { homeActions } from './home-reducer';
import I18n from '../../i18n';
import { I18n as Translater } from 'react-redux-i18n';
import { ToggleWithLabel } from '../../global/toggle-with-label';
import { SubmitButton } from '../../global/submit-button';
import { LocationInput } from '../../global/location-input';
import { LocationDateTime } from '../../global/location-date-time';
import { listOfHours } from '../../helpers/date-helper';
import { PROP_KEYS } from './home-constants';
import { animateScroll as scroll, scroller } from 'react-scroll';
import windowSize from 'react-window-size';
import { DEFAULT_ZERO_VALUE } from '../../assets/constants/global';
import { SocialLinks } from '../../global/social-links';

const { Option } = Select;

class HomeTransfer extends React.Component {

    submit = () => {
        const { setOpenResults, loadResults } = this.props;

        setOpenResults(true);
        let height = DEFAULT_ZERO_VALUE;

        const header_mobile = document.getElementsByName("header_mobile")[0];
        const ride_details_page = scroller.get("ride_details_page");

        if (header_mobile && header_mobile.clientHeight) {
            height += header_mobile.clientHeight;
        }
        
        if (ride_details_page && ride_details_page.clientHeight) {
            height += ride_details_page.clientHeight;
        }

        // console.log('HomeTransfer scrollTo ->', height);
        scroll.scrollTo(height);
        loadResults();
    }

    render() {
        const {
            setPickupLocation,
            setDestination,
            setPickupLocationDatetime,
            setDestinationDatetime,
            setReturnTransfer,
            setHourlyBooking,
            setHourlyBookingHours,
            returnTransfer,
            hourlyBooking,
            pickupLocation,
            destination,
            pickupLocationDatetime,
            destinationDatetime,
            hourlyBookingHours,
            pickupLocationString,
            setPickupLocationString,
            destinationString,
            setDestinationString,
            setPickupLocationObj,
            setDestinationObj,
            edit,
        } = this.props;
        const hours = listOfHours();
        const disabledSubmit = !(pickupLocation && destination)
                             || (returnTransfer && moment(destinationDatetime).isBefore(pickupLocationDatetime, 'day'));

        return (
            <Fragment>
                <h1 className="title mb-3">
                    <I18n t="bookTransferTitle" />
                </h1>
                <LocationInput value={pickupLocation}
                               locale={locale}
                               stringValue={pickupLocationString}
                               placeholder={Translater.t('pickupLocationPlaceholder')}
                               onChange={setPickupLocation}
                               onChangeAddress={setPickupLocationString}
                               onChangeLocationObject={setPickupLocationObj} />
                <div className="connect-division"></div>
                <LocationDateTime value={pickupLocationDatetime}
                                  setDate={setPickupLocationDatetime}
                                  disabledTime={false}
                                  disabledDate={(current) => current && moment(current.valueOf()).isSameOrBefore(moment())} />
                <LocationInput value={destination}
                               locale={locale}
                               stringValue={destinationString}
                               placeholder={Translater.t('destinationPlaceholder')}
                               onChange={setDestination}
                               onChangeAddress={setDestinationString}
                               onChangeLocationObject={setDestinationObj} />
                {
                    returnTransfer && (
                        <Fragment>
                            <div className="connect-division"></div>
                            <LocationDateTime value={destinationDatetime}
                                              prevValue={pickupLocationDatetime}
                                              setDate={setDestinationDatetime}
                                              disabledDate={(current) => current && moment(current.valueOf()).isBefore(pickupLocationDatetime)} />
                        </Fragment>
                    )
                }
                <div className="mt-3 mb-3">
                    {
                        !edit && (
                            <ToggleWithLabel label={Translater.t('returnTransfer')}
                                             disabled={edit}
                                             checked={returnTransfer}
                                             onChange={setReturnTransfer} />
                        )
                    }
                    <div className="d-flex flex-row hourly-booking">
                        <ToggleWithLabel label={Translater.t('hourlyBooking')}
                                         checked={hourlyBooking}
                                         onChange={setHourlyBooking} />
                        {
                            hourlyBooking && (
                                <Select onChange={setHourlyBookingHours} value={hourlyBookingHours}>
                                {
                                    hours.map((hour, index) => {
                                        const option = Translater.t('hoursTitle');
                                        const value = `${hour} ${option}`;
                                    
                                        return (<Option className="text-lowercase" key={hour} value={hour}>{value}</Option>);
                                    })
                                }
                                </Select>
                            )
                        }
                    </div>
                </div>
                <SubmitButton label={Translater.t('searchTitle')}
                              disabled={disabledSubmit}
                              onClick={this.submit} />
                <SocialLinks facebookLink='https://www.facebook.com/ridewazup'
                             twitterLink='https://twitter.com/ridewazup'
                             instagramLink='https://www.instagram.com/ridewazup/'
                             linkedinLink='https://www.linkedin.com/company/ridewazup/'
                             youtubeLink='https://www.youtube.com/channel/UCKd8lUyegU9x2iE9m7JNpdA' />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { location, i18n: { locale } } = state;

    return {
        ...location,
        locale
    };
}

const mapDispatchToProps = {
    setPickupLocation: (value) => homeActions.setLocationValue(PROP_KEYS.pickupLocation, value),
    setDestination: (value) => homeActions.setLocationValue(PROP_KEYS.destination, value),
    setPickupLocationDatetime: (value) => homeActions.setTripValue(PROP_KEYS.pickupLocationDatetime, value),
    setDestinationDatetime: (value) => homeActions.setTripValue(PROP_KEYS.destinationDatetime, value),
    setReturnTransfer: (value) => homeActions.setTripValue(PROP_KEYS.returnTransfer, value),
    setHourlyBooking: (value) => homeActions.setTripValue(PROP_KEYS.hourlyBooking, value),
    setHourlyBookingHours: (value) => homeActions.setTripValue(PROP_KEYS.hourlyBookingHours, value),
    setPickupLocationString: (value) => homeActions.setValue(PROP_KEYS.pickupLocationString, value),
    setDestinationString: (value) => homeActions.setValue(PROP_KEYS.destinationString, value),
    setPickupLocationObj: (value) => homeActions.setValue(PROP_KEYS.pickupLocationObj, value),
    setDestinationObj: (value) => homeActions.setValue(PROP_KEYS.destinationObj, value),
    setOpenResults: (value) => homeActions.setValue(PROP_KEYS.isOpenedResults, value),
    loadResults: homeActions.loadResults
}

const progressHomeTransfer = connect(mapStateToProps, mapDispatchToProps)(windowSize(HomeTransfer));
export { progressHomeTransfer as HomeTransfer };