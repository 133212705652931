import React from "react";
import { I18n as Translater } from 'react-redux-i18n';
import { USER_INPUT_TYPES, STRING_EMPTY } from '../assets/constants/global';
import { UserInput } from './user-input';
import { Modal, Tabs } from 'antd';
import I18n from '../i18n';
import { SubmitButton } from './submit-button';
import { Link } from 'react-router-i18n';
import close from '../assets/icons/close.svg';

const { TabPane } = Tabs;

export class Signin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            personalEmail: STRING_EMPTY,
            personalPhone: STRING_EMPTY,
            personalPassword: STRING_EMPTY,
            personalConfirmPassword: STRING_EMPTY,
            personalFullName: STRING_EMPTY,
            companyName: STRING_EMPTY,
            companyContactFullName: STRING_EMPTY,
            companyPhone: STRING_EMPTY,
            companyEmail: STRING_EMPTY,
            companyPassword: STRING_EMPTY,
            companyConfirmPassword: STRING_EMPTY
        }
    }

    setValue = (key, value) => {
        this.setState({ [key]: value })
    }

    render() {
        const { handlePersonalSignIn, handleCompanySignIn, isLoadedSignin = true } = this.props;
        const { 
            personalEmail, personalPhone, personalPassword, personalConfirmPassword, personalFullName,
            companyName, companyContactFullName, companyPhone, companyEmail, companyPassword, companyConfirmPassword
        } = this.state
        const disabledSubmit = personalEmail === STRING_EMPTY
                            || personalPhone === STRING_EMPTY
                            || personalPassword === STRING_EMPTY
                            || personalConfirmPassword === STRING_EMPTY
                            || personalFullName === STRING_EMPTY;
        const disabledCompanySubmit = companyName === STRING_EMPTY 
                                   || companyContactFullName === STRING_EMPTY
                                   || companyPhone === STRING_EMPTY
                                   || companyEmail === STRING_EMPTY
                                   || companyPassword === STRING_EMPTY
                                   || companyConfirmPassword === STRING_EMPTY;

        return (
            <div id='signin'>
                <Modal title="SignIn Modal"
                       visible={true}
                       centered={true}
                       footer={null}
                       maskClosable={false}
                       closeIcon={<Link to='/admin-accounts'><img src={close} alt='close' /></Link>}
                       getContainer={() => document.getElementById('signin')}>
                    <div className="d-flex flex-column align-items-center">
                        <span className='payment-title'><I18n t='createAnAccountTitle' /></span>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={Translater.t('personalAccountTitle')} key="personalAccount">
                                <div className="d-flex flex-column w-100">
                                    <UserInput optional={false} placeholder={Translater.t('emailTitle')} containerClassName='mr-3' type={USER_INPUT_TYPES.email} onChange={(value) => this.setValue('personalEmail', value)} />
                                    <UserInput optional={false} placeholder={Translater.t('fullNameTitle')} type={USER_INPUT_TYPES.string} onChange={(value) => this.setValue('personalFullName', value)} />
                                    <UserInput optional={false} placeholder={Translater.t('phoneTitle')} type={USER_INPUT_TYPES.phone} onChange={(value) => this.setValue('personalPhone', value)} />
                                    <UserInput optional={false} placeholder={Translater.t('passwordTitle')} type={USER_INPUT_TYPES.password} onChange={(value) => this.setValue('personalPassword', value)} />
                                    <UserInput optional={false} placeholder={Translater.t('passwordConfirmTitle')} type={USER_INPUT_TYPES.password} onChange={(value) => this.setValue('personalConfirmPassword', value)} />
                                    <SubmitButton label={Translater.t('createTitle')}
                                                  disabled={disabledSubmit}
                                                  onClick={() => handlePersonalSignIn({
                                                    email: personalEmail,
                                                    fullName: personalFullName,
                                                    phone: personalPhone,
                                                    password: personalPassword,
                                                    passwordConfirm: personalConfirmPassword
                                                  })}
                                                  loading={!isLoadedSignin} />
                                    {/* <div className='do-not-have-account'>
                                        <span><I18n t='alreadyHaveAnAccountTitle' /></span>
                                        <Link to='/login'><I18n t='loginTitle' /></Link>
                                    </div> */}
                                </div>
                            </TabPane>
                            <TabPane tab={Translater.t('companyTitle')} key="company">
                                <div className="d-flex flex-column w-100">
                                    <UserInput optional={false} placeholder={Translater.t('emailTitle')} containerClassName='mr-3' type={USER_INPUT_TYPES.email} onChange={(value) => this.setValue('companyEmail', value)} />
                                    <UserInput optional={false} placeholder={Translater.t('companyNameTitle')} containerClassName='mr-3' type={USER_INPUT_TYPES.string} onChange={(value) => this.setValue('companyName', value)} />
                                    <UserInput optional={false} placeholder={Translater.t('fullNameTitle')} type={USER_INPUT_TYPES.string} onChange={(value) => this.setValue('companyContactFullName', value)} />
                                    <UserInput optional={false} placeholder={Translater.t('phoneTitle')} type={USER_INPUT_TYPES.phone} onChange={(value) => this.setValue('companyPhone', value)} />
                                    <UserInput optional={false} placeholder={Translater.t('passwordTitle')} type={USER_INPUT_TYPES.password} onChange={(value) => this.setValue('companyPassword', value)} />
                                    <UserInput optional={false} placeholder={Translater.t('passwordConfirmTitle')} type={USER_INPUT_TYPES.password} onChange={(value) => this.setValue('companyConfirmPassword', value)} />
                                    <SubmitButton label={Translater.t('createTitle')}
                                                  disabled={disabledCompanySubmit}
                                                  onClick={() => handleCompanySignIn({
                                                    email: companyEmail,
                                                    fullName: companyContactFullName,
                                                    companyName: companyName,
                                                    phone: companyPhone,
                                                    password: companyPassword,
                                                    passwordConfirm: companyConfirmPassword
                                                  })}
                                                  loading={!isLoadedSignin} />
                                    {/* <div className='do-not-have-account'>
                                        <span><I18n t='alreadyHaveAnAccountTitle' /></span>
                                        <Link to='/login'><I18n t='loginTitle' /></Link>
                                    </div> */}
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </Modal>
            </div>
        )
    }
}   
