import React from 'react';
import { connect } from 'react-redux';
import { withAdminLayout } from '../../common/admin-layout';
import { ADMIN_PAGES } from '../../assets/constants/global';
import { adminActions } from './admin-reducer';
import { BaseAdmin } from './base';
import { PROP_KEYS, ACTION_LOAD_TYPES, TEMPLATE_TYPES, ACTION_IMPORT_TYPES } from './admin-constants';

class AdminDiscounts extends React.Component {
    componentDidMount() {
        const { loadData } = this.props;

        loadData();
    }

    render() {
        const { 
            discounts, discountsErrorMsg, handleDataPageChange, handleDataPageSizeChange,
            discountsPagination, isLoadedDiscounts, importData, exportData,
            setImportedData, downloadTemplate, cleanDataErrorMsg, handleSort, discountColumnTypes
        } = this.props;
        const baseProps = {
            data: discounts,
            isLoaded: isLoadedDiscounts,
            errorMsg: discountsErrorMsg,
            handleImportData: importData,
            handleExportData: exportData,
            setImportedData: setImportedData,
            downloadTemplate: downloadTemplate,
            pagination: discountsPagination,
            handlePageChange: handleDataPageChange,
            handlePageSizeChange: handleDataPageSizeChange,
            cleanErrorMsg: cleanDataErrorMsg,
            sortData: handleSort,
            columnTypes: discountColumnTypes
        };

        return (
            <BaseAdmin {...baseProps} />
        );
    }
}

const mapStateToProps = (state) => {
    const { admin } = state;
    const { discounts, discountsPagination, isLoadedDiscounts, discountsErrorMsg, discountColumnTypes } = admin;

    return {
        discounts, isLoadedDiscounts, discountsPagination, discountsErrorMsg, discountColumnTypes
    };
}

const { action, isLoadedKey, paginationKey, dataKey, importAction, importedKey, errorMsgKey, fileKey, columnTypesKey } = {
    action: ACTION_LOAD_TYPES.discount,
    isLoadedKey: PROP_KEYS.isLoadedDiscounts,
    paginationKey: PROP_KEYS.discountsPagination,
    dataKey: PROP_KEYS.discounts,
    importAction: ACTION_IMPORT_TYPES.discount,
    importedKey: PROP_KEYS.importedDiscount,
    errorMsgKey: PROP_KEYS.discountsErrorMsg,
    fileKey: TEMPLATE_TYPES.Discount,
    columnTypesKey: PROP_KEYS.discountColumnTypes
}

const mapDispatchToProps = {
    loadData: () => adminActions.load({ 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    importData: () => adminActions.importData({
        importAction, action, isLoadedKey, importedKey, errorMsgKey, paginationKey, dataKey
    }),
    exportData: () => adminActions.exportData({ 
        action, isLoadedKey, fileKey
    }),
    setImportedData: (file) => adminActions.setValue(importedKey, file),
    cleanDataErrorMsg: () => adminActions.setValue(errorMsgKey, []),
    downloadTemplate: () => adminActions.downloadTemplate({
        isLoadedKey, fileKey
    }),
    handleDataPageChange: (page, pageSize) => adminActions.handlePaginationChange(page, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleDataPageSizeChange: (_page, pageSize) => adminActions.handlePaginationChange(1, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleSort: (_pagination, _filters, sorter) => adminActions.handleSort(1, sorter, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    })
}

const connectedAdminDiscounts = connect(mapStateToProps, mapDispatchToProps)(withAdminLayout(AdminDiscounts, ADMIN_PAGES.AdminDiscounts));
export { connectedAdminDiscounts as AdminDiscounts };