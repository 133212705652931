import React from 'react';
import { connect } from 'react-redux';
import { withLayout } from '../../common/layout';
import { I18n as Translater } from 'react-redux-i18n';
import { Tabs } from 'antd';
import { MyOrders } from './user-my-orders';
import { UserProfileDetails } from './user-profile'
import Desktop from '../../common/desktop-mode';
import Tablet from '../../common/tablet-mode';
import Mobile from '../../common/mobile-mode';
import { RESOLUTION_TYPES } from '../../assets/constants/global';

import './user.scss';

const { TabPane } = Tabs;

class UserProfile extends React.Component {
    render() {
        const { user } = this.props;
        
        return (
            <div className="d-flex flex-column user-profile">
                { user && user.name && <div className="d-flex w-100 hello-title">{`${Translater.t('helloTitle')}, ${user.name}`}</div> }
                <div className="d-flex w-100 user-tabs">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={Translater.t('myOrdersTitle')} key="myOrders">
                            <Desktop><MyOrders mode={RESOLUTION_TYPES.desktop} /></Desktop>
                            <Tablet><MyOrders mode={RESOLUTION_TYPES.tablet} /></Tablet>
                            <Mobile><MyOrders mode={RESOLUTION_TYPES.mobile} /></Mobile>
                        </TabPane>
                        <TabPane tab={Translater.t('profileDetailsTitle')} key="profileDetails">
                            <UserProfileDetails></UserProfileDetails>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedUserProfile = connect(mapStateToProps)(withLayout(UserProfile));
export { connectedUserProfile as UserProfile };