import { createI18n } from 'react-router-i18n';
import { locales } from "./config";
import translations from './translations';

const I18n = createI18n(
    locales,
    translations,
    'Unknown text',
);

export default I18n;