import React, { Fragment } from "react";
import I18n from '../i18n';
import windowSize from 'react-window-size';
import { Link } from 'react-router-i18n';
import { I18n as Translater } from 'react-redux-i18n';
import default_user from '../assets/icons/default_user.png';
import default_company from '../assets/icons/default_company.png';
import logo from '../assets/icons/white_logo.svg';
import { Layout, Menu } from 'antd';
import {
    LeftOutlined,
    RightOutlined,
    FlagOutlined,
    PlusCircleOutlined,
    CarOutlined,
    DollarOutlined,
    BranchesOutlined,
    CrownOutlined,
    BankOutlined,
    PaperClipOutlined,
    TeamOutlined,
    RocketOutlined,
    AimOutlined,
    HeatMapOutlined,
    PercentageOutlined,
    ProfileOutlined,
    AuditOutlined,
    ContainerOutlined
} from '@ant-design/icons';
import { ADMIN_PAGES } from "../assets/constants/global";
import Desktop from "./desktop-mode";
import Tablet from "./tablet-mode";
import Mobile from "./mobile-mode";
import { userService } from '../pages/user/user-service';
import Cookies from 'js-cookie';

const { Header, Sider, Content } = Layout;
const { Item } = Menu;

export const withAdminLayout = (Component, selectedKey) => {
    class BaseLayout extends React.Component {
        constructor() {
            super();

            this.state = {
                collapsed: false
            }
        }

        logout = () => {
            userService.logout();
        }

        toggle = () => {
            this.setState(prevState => ({
                collapsed: !prevState.collapsed,
            }));
        };

        menu = () => {
            return (
                <Fragment>
                    <Desktop><div className="logo"><Link to="/"><img src={logo} alt="Logo" /></Link></div></Desktop>
                    <Tablet><div className="logo"><Link to="/"><img src={logo} alt="Logo" /></Link></div></Tablet>
                    <Menu mode="inline" selectedKeys={selectedKey} defaultSelectedKeys={ADMIN_PAGES.AdminLocations}>
                        <Item key={ADMIN_PAGES.AdminDispatch} icon={<AimOutlined />}>
                            <Link to="/admin"><I18n t="dispatchTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminLocations} icon={<FlagOutlined />}>
                            <Link to="/admin-locations"><I18n t="locationsTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminAffiliates} icon={<BankOutlined />}>
                            <Link to="/admin-affiliates"><I18n t="affiliatesTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminVehicles} icon={<CarOutlined />}>
                            <Link to="/admin-vehicles"><I18n t="vehiclesTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminAdditionalOptions} icon={<PlusCircleOutlined />}>
                            <Link to="/admin-additional-options"><I18n t="additionalOptionsTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminVip} icon={<CrownOutlined />}>
                            <Link to="/admin-vip"><I18n t="vipTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminExtraPrices} icon={<DollarOutlined />}>
                            <Link to="/admin-extra-prices"><I18n t="extraPricesTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminPaymentRules} icon={<PaperClipOutlined />}>
                            <Link to="/admin-payment-rules"><I18n t="paymentRulesTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminRoutes} icon={<BranchesOutlined />}>
                            <Link to="/admin-routes"><I18n t="routesTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminNotFoundRoutes} icon={<HeatMapOutlined />}>
                            <Link to="/admin-not-found-routes"><I18n t="notFoundRoutesTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminTrips} icon={<RocketOutlined />}>
                            <Link to="/admin-trips"><I18n t="tripsTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminUnfinalizedTrips} icon={<ProfileOutlined  />}>
                            <Link to="/admin-unfinalized-trips"><I18n t="unfinalizedTripsTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminDiscounts} icon={<PercentageOutlined />}>
                            <Link to="/admin-discounts"><I18n t="discountsTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminAffiliateSettlements} icon={<ContainerOutlined />}>
                            <Link to="/admin-affiliate-settlements"><I18n t="affiliateSettlementsTitle" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminTripSettlements} icon={<AuditOutlined />}>
                            <Link to="/admin-trip-settlements"><I18n t="tripSettlements" /></Link>
                        </Item>
                        <Item key={ADMIN_PAGES.AdminAccounts} icon={<TeamOutlined />}>
                            <Link to="/admin-accounts"><I18n t="accountsTitle" /></Link>
                        </Item>
                    </Menu>
                </Fragment>
            )
        };

        render() {
            const user = Cookies.get('user') && JSON.parse(Cookies.get('user'));
            const pageTitle = {
                [ADMIN_PAGES.AdminDiscounts]: Translater.t('discountsTitle'),
                [ADMIN_PAGES.AdminLocations]: Translater.t('locationsTitle'),
                [ADMIN_PAGES.AdminAdditionalOptions]: Translater.t('additionalOptionsTitle'),
                [ADMIN_PAGES.AdminVip]: Translater.t('vipTitle'),
                [ADMIN_PAGES.AdminAffiliates]: Translater.t('affiliatesTitle'),
                [ADMIN_PAGES.AdminAffiliateSettlements]: Translater.t('affiliateSettlementsTitle'),
                [ADMIN_PAGES.AdminVehicles]: Translater.t('vehiclesTitle'),
                [ADMIN_PAGES.AdminExtraPrices]: Translater.t('extraPricesTitle'),
                [ADMIN_PAGES.AdminPaymentRules]: Translater.t('paymentRulesTitle'),
                [ADMIN_PAGES.AdminTrips]: Translater.t('tripsTitle'),
                [ADMIN_PAGES.AdminAccounts]: Translater.t('accountsTitle'),
                [ADMIN_PAGES.AdminRoutes]: Translater.t('routesTitle'),
                [ADMIN_PAGES.AdminDispatch]: Translater.t('dispatchTitle'),
                [ADMIN_PAGES.AdminNotFoundRoutes]: Translater.t('notFoundRoutesTitle'),
                [ADMIN_PAGES.AdminTripSettlements]: Translater.t('tripSettlements'),
                [ADMIN_PAGES.AdminUnfinalizedTrips]: Translater.t('unfinalizedTripsTitle'),
            }[selectedKey];

            return (
                <Layout className='admin-layout'>
                    <Desktop>
                        <Sider trigger={null} collapsible collapsed={this.state.collapsed} collapsedWidth={120}>
                            {this.menu()}
                        </Sider>
                    </Desktop>
                    <Tablet>
                        <Sider trigger={null} collapsible collapsed={this.state.collapsed} collapsedWidth={80}>
                            {this.menu()}
                        </Sider>
                    </Tablet>
                    <Mobile>
                        <Sider trigger={null} collapsed={true} breakpoint='xs' collapsedWidth={40}>
                            {this.menu()}
                        </Sider>
                    </Mobile>
                    <Layout className="site-layout">
                        <Header className='admin-header'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className="d-flex">
                                    <Desktop>
                                        {
                                            this.state.collapsed 
                                                ? <RightOutlined className='trigger menu-button' onClick={this.toggle} /> 
                                                : <LeftOutlined className='trigger menu-button' onClick={this.toggle} />
                                        }
                                    </Desktop>
                                    <Tablet>
                                        {
                                            this.state.collapsed 
                                                ? <RightOutlined className='trigger menu-button' onClick={this.toggle} /> 
                                                : <LeftOutlined className='trigger menu-button' onClick={this.toggle} />
                                        }
                                    </Tablet>
                                    <span className="page-title">{pageTitle}</span>
                                </div>
                                {
                                    user && (
                                        <div className='d-flex justify-content-end'>
                                            <Link to="/user">
                                                 <div className="user-logo d-flex align-items-center">
                                                     <div className="user-logo-image ml-5">
                                                         <img src={user.companyName ? default_company : default_user} alt="User logo" />
                                                         <span>{user.companyName || user.fullName}</span>
                                                     </div>
                                                </div>
                                            </Link>
                                            <div className="user-login d-flex align-items-center">
                                                <button onClick={this.logout}><span><I18n t="logoutTitle" /></span></button>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </Header>
                        <Content>
                            <Component {...this.props} />
                        </Content>
                    </Layout>
                </Layout>
            );
        }
    };

    return windowSize(BaseLayout);
}