import React from 'react';
import { connect } from 'react-redux';
import { withLayout } from '../../common/layout';
import { homeActions } from '../home/home-reducer';
import { PROP_KEYS } from '../home/home-constants';

class About extends React.Component {

    componentDidMount() {
        document.getElementById("initialTripAdvisorWidget").className = "tripAdvisorWidget-content";
        this.props.getAboutHtml();
    }

    componentWillUpdate() {
        document.getElementById("initialTripAdvisorWidget").className = "tripAdvisorWidget-content";
    }

    componentWillUnmount() {
        document.getElementById("initialTripAdvisorWidget").className = "display-none";
    }

    render() {
        const html = { __html: this.props.aboutPage };

        return (
                <div dangerouslySetInnerHTML={html}></div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, location: { aboutPage } } = state;
    const { user } = authentication;
    return {
        user,
        aboutPage
    };
}

const mapDispatchToProps = {
    getAboutHtml: () => homeActions.loadHtml(PROP_KEYS.aboutPage)
}

const connectedAbout = connect(mapStateToProps, mapDispatchToProps)(withLayout(About));
export { connectedAbout as About };