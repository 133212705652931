import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-i18n';
import { USER_ROLES } from '../assets/constants/global';
import Cookies from 'js-cookie';

export const AdminRoute = ({ component: Component, componentProps, ...rest }) => {
    const token = Cookies.get('token');
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : {};

    return (
        <Route {...rest} render={routeProps => (
            !token 
                ? <Redirect to={`/login`} /> 
                : user && user.role && user.role.includes(USER_ROLES.Admin)
                ? <Component { ...{...routeProps, ...componentProps } } />
                : <Redirect to={`/`} /> 
        )} />
    );
};