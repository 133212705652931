import React from 'react';
import { connect } from 'react-redux';
import { withLayout } from '../../common/layout';
import { PROP_KEYS } from '../home/home-constants';
import { homeActions } from '../home/home-reducer';

class Partners extends React.Component {

    componentDidMount() {
        document.getElementById("initialTripAdvisorWidget").className = "tripAdvisorWidget-content";
        this.props.getPartnersHtml();
    }

    componentWillUpdate() {
        document.getElementById("initialTripAdvisorWidget").className = "tripAdvisorWidget-content";
    }
    
    componentWillUnmount() {
        document.getElementById("initialTripAdvisorWidget").className = "display-none";
    }

    render() {
        const html = {__html: this.props.partnersPage};

        return (
            <div dangerouslySetInnerHTML={html}></div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, location: { partnersPage } } = state;
    const { user } = authentication;
    return {
        user,
        partnersPage
    };
}

const mapDispatchToProps = {
    getPartnersHtml: () => homeActions.loadHtml(PROP_KEYS.partnersPage)
}

const connectedPartners = connect(mapStateToProps,mapDispatchToProps)(withLayout(Partners));
export { connectedPartners as Partners };