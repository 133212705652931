export const CAR_TYPES = {
    standart: 'Standart',
    premium: 'Premium',
    vip: 'VIP',
    economy: 'Economy'
}

export const PROP_KEYS = {
    position: 'position',
    returnTransfer: 'returnTransfer',
    hourlyBooking: 'hourlyBooking',
    pickupLocation: 'pickupLocation',
    destination: 'destination',
    pickupLocationDatetime: 'pickupLocationDatetime',
    destinationDatetime: 'destinationDatetime',
    hourlyBookingHours: 'hourlyBookingHours',
    isOpenedResults: 'isOpenedResults',
    isLoadedResults: 'isLoadedResults',
    addedOptions: 'addedOptions',
    results: 'results',
    directions: 'directions',
    pickupLocationString: 'pickupLocationString',
    destinationString: 'destinationString',
    selectedCar: 'selectedCar',
    isOpenedCheckout: 'isOpenedCheckout',
    isLoadedCheckout: 'isLoadedCheckout',
    userEmail: 'userEmail',
    userPhone: 'userPhone',
    userName: 'userName',
    userPassword: 'userPassword',
    userFullName: 'userFullName',
    userComment: 'userComment',
    promocode: 'promocode',
    paymentMethodToggle: 'paymentMethodToggle',
    checkout: 'checkout',
    distance: 'distance',
    duration: 'duration',
    promo: 'promo',
    showPromo: 'showPromo',
    pickupLocationObj: 'pickupLocationObj',
    destinationObj: 'destinationObj',
    tripIds: 'tripIds',
    routeNotFound: 'routeNotFound',
    notFoundName: 'notFoundName',
    notFoundEmail: 'notFoundEmail',
    promoDiscount: 'promoDiscount',
    login: 'login',
    signin: 'signin',
    isLoadedDriverModal: 'isLoadedDriverModal',
    isLoadedAgentModal: 'isLoadedAgentModal',
    oldTotal: 'oldTotal',
    paypalOrderId: 'paypalOrderId',
    paypalTripIds: 'paypalTripIds',
    pickupLocationIsAirport: 'pickupLocationIsAirport',
    dropoffLocationIsAirport: 'dropoffLocationIsAirport',
    flightNumber: 'flightNumber', 
    returnFlightNumber: 'returnFlightNumber',
    invoiceEmail: 'invoiceEmail',
    invoicePassword: 'invoicePassword',
    notFoundIsLoaded: 'notFoundIsLoaded',
    aboutPage: 'aboutPage',
    faqPage: 'faqPage',
    partnersPage: 'partnersPage',
    privacyAndTermsPage: 'privacyAndTermsPage'
}

export const PAYMENT_METHODS = {
    Checkout: 'Stripe',
    PayPal: 'PayPal',
    Invoice: 'Invoice'
}

export const DISCOUNT_TYPE = {
    Percentage: 'Percentage',
    Money: 'Money'
}

export const ADDITIONAL_OPTION_TYPE = {
    GENERAL: 'GENERAL',
    AFFILIATE: 'AFFILIATE',
    VIP: 'VIP'
}