import React from 'react';
import { connect } from 'react-redux';
import { withAdminLayout } from '../../common/admin-layout';
import { ADMIN_PAGES } from '../../assets/constants/global';
import { adminActions } from './admin-reducer';
import { BaseAdmin } from './base';
import { PROP_KEYS, ACTION_LOAD_TYPES, TEMPLATE_TYPES, ACTION_IMPORT_TYPES } from './admin-constants';

class AdminAffiliates extends React.Component {
    componentDidMount() {
        const { loadData } = this.props;

        loadData();
    }

    render() {
        const { 
            affiliates, affiliatesErrorMsg, handleDataPageChange, handleDataPageSizeChange,
            affiliatesPagination, isLoadedAffiliates, importData, exportData,
            setImportedData, downloadTemplate, cleanDataErrorMsg, handleSort, affiliateColumnTypes
        } = this.props;
        const baseProps = {
            data: affiliates,
            isLoaded: isLoadedAffiliates,
            errorMsg: affiliatesErrorMsg,
            handleImportData: importData,
            handleExportData: exportData,
            setImportedData: setImportedData,
            downloadTemplate: downloadTemplate,
            pagination: affiliatesPagination,
            handlePageChange: handleDataPageChange,
            handlePageSizeChange: handleDataPageSizeChange,
            cleanErrorMsg: cleanDataErrorMsg,
            sortData: handleSort,
            columnTypes: affiliateColumnTypes
        };

        return (
            <BaseAdmin {...baseProps} />
        );
    }
}

const mapStateToProps = (state) => {
    const { admin } = state;
    const { affiliates, affiliatesPagination, isLoadedAffiliates, affiliatesErrorMsg, affiliateColumnTypes } = admin;

    return {
        affiliates, isLoadedAffiliates, affiliatesPagination, affiliatesErrorMsg, affiliateColumnTypes
    };
}

const { action, isLoadedKey, paginationKey, dataKey, importAction, importedKey, errorMsgKey, fileKey, columnTypesKey } = {
    action: ACTION_LOAD_TYPES.affiliate,
    isLoadedKey: PROP_KEYS.isLoadedAffiliates,
    paginationKey: PROP_KEYS.affiliatesPagination,
    dataKey: PROP_KEYS.affiliates,
    importAction: ACTION_IMPORT_TYPES.affiliate,
    importedKey: PROP_KEYS.importedAffiliates,
    errorMsgKey: PROP_KEYS.affiliatesErrorMsg,
    fileKey: TEMPLATE_TYPES.Affiliate,
    columnTypesKey: PROP_KEYS.affiliateColumnTypes
}

const mapDispatchToProps = {
    loadData: () => adminActions.load({ 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    importData: () => adminActions.importData({
        importAction, action, isLoadedKey, importedKey, errorMsgKey, paginationKey, dataKey
    }),
    exportData: () => adminActions.exportData({ 
        action, isLoadedKey, fileKey
    }),
    setImportedData: (file) => adminActions.setValue(importedKey, file),
    cleanDataErrorMsg: () => adminActions.setValue(errorMsgKey, []),
    downloadTemplate: () => adminActions.downloadTemplate({
        isLoadedKey, fileKey
    }),
    handleDataPageChange: (page, pageSize) => adminActions.handlePaginationChange(page, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleDataPageSizeChange: (_page, pageSize) => adminActions.handlePaginationChange(1, pageSize, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    }),
    handleSort: (_pagination, _filters, sorter) => adminActions.handleSort(1, sorter, { 
        action, isLoadedKey, paginationKey, dataKey, fileKey, columnTypesKey
    })
}

const connectedAdminAffiliates = connect(mapStateToProps, mapDispatchToProps)(withAdminLayout(AdminAffiliates, ADMIN_PAGES.AdminAffiliates));
export { connectedAdminAffiliates as AdminAffiliates };