import { config } from '../../config';
import { STRING_EMPTY } from '../../assets/constants/global';
import { userService } from '../user/user-service';
import Cookies from 'js-cookie';

export const adminService = {
    load: (action, props) => {
        const requestOptions = {
            method: 'POST',
            body: props && JSON.stringify(props),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('token')}`
            }
        };

        return fetch(`${config.apiUrl}/api/${action}`, requestOptions)
            .then(handleResponse)
            .then(({ data = [], pagination }) => {
                return { data, pagination }
            });
    },
    import: (action, file) => {
        const formData = new FormData();
        formData.append('file', file);
        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `Bearer ${Cookies.get('token')}`
            }
        };

        return fetch(`${config.apiUrl}/api/${action}`, requestOptions)
            .then(handleImportResponse);
    },
    loadTemplate: (name) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Cookies.get('token')}`
            }
        };

        return fetch(`${config.apiUrl}/api/import/get-table-template?name=${name}`, requestOptions)
            .then(handleResponse)
            .then(data => {
                return { data }
            });
    },
    markAsDone: (doneTrips) => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(doneTrips),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('token')}`
            }
        };

        return fetch(`${config.apiUrl}/api/trip/mark-as-done`, requestOptions)
            .then(handleResponse);
    }
};

const handleResponse = (response) => {
    return response ? response.text().then(text => {
        const data = text && text !== STRING_EMPTY ? JSON.parse(text) : {};
        if (!response.ok) {
            if (response.status === 401) {
                return userService.refreshToken();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    }) : Promise.reject('error');
}

const handleImportResponse = (response) => {
    return response.text().then(text => {
        const data = text && text !== STRING_EMPTY ? JSON.parse(text) : {};
        if (!response.ok) {
            if (response.status === 401) {
                return userService.refreshToken();
            }

            const error = data || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}