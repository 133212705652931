import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-i18n';
import Cookies from 'js-cookie';

export const PrivateRoute = ({ component: Component, componentProps, ...rest }) => {
    return (
        <Route {...rest} render={routeProps => (
            Cookies.get('token')
                ? <Component { ...{...routeProps, ...componentProps } } />
                : <Redirect to={`/login`} />
        )} />
    );
};