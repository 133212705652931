import moment from "moment";

export const timeList = (disabledTime, selectedDate = moment()) => {
    const start = disabledTime 
                && moment().isSame(selectedDate, 'day') ? moment().set({ 'minutes': 0 }).add(1, 'hours')
                                                        : moment().set({ 'hours': 0, 'minutes': 0 });
    const max = 24 - moment(start).hour();
    const minutes = [0, 30];
    const times = [];
    for(let i = 0; i < max; i++) {
        minutes.forEach(minute => {
            times.push(moment(start).add(i, 'hours').add(minute, 'minutes'));
        })
    }
    
    return times;
}

export const listOfHours = () => {
    const times = [];
    for(let i = 4; i < 25; ++i) {
        times.push(i);
    }
    
    return times;
}