import moment from 'moment';
import { I18n as Translater } from 'react-redux-i18n';
import getSymbolFromCurrency from 'currency-symbol-map';

import logo from '../../assets/icons/logo.svg';
import default_car from '../../assets/icons/default_car.png';

export const recordToRawHtml = (record) => {
    const { 
        startTripDatetime, pickupLocation, dropoffLocation, pickupInstruction,
        driverPhone, carTitle, total, currency, contact, id, image, comments
    } = record;

    return (`
        <div style='font-family: Arial, Helvetica, sans-serif;'>
            <div style='display: flex; justify-content:flex-end'><img src=${logo} alt="logo" /></div>
            <div style='font-size: 32px;line-height: 35px;color: #393644;'>Hello, ${contact.fullName}</div>
            <div style='font-size: 18px;line-height: 35px;color: #393644; font-weight: 500;'>Your booking ${id} from <a style='color:#4E31B5; text-decoration: none;' href='http://devwazupfrontend-env.eba-peajaupd.us-east-2.elasticbeanstalk.com'>WAZUP.com<a></div>
            <div style='font-size: 12px;line-height: 160%;color: #393644;padding-top:24px;'>Thank you for choosing WazUp.com for your transportation needs. We are happy to confirm that your booking was successful. Please make sure to read this email along with our meeting instructions to ensure all details are correct and to familiarize yourself with important information about your transfer.</div>
            <div style='font-size: 12px;line-height: 160%;color: #393644;padding-top:24px;'>You can amend your booking to <a style='color:#4E31B5; text-decoration: none;' href='http://devwazupfrontend-env.eba-peajaupd.us-east-2.elasticbeanstalk.com/user'>wazup.com/user<a></div>
            <div style='display: flex;width: 100%;'>
                <div style='display: flex;width: 33.33%;flex-direction: column;'>
                    <span style='font-size: 14px;line-height: 160%;text-transform: capitalize;color: #393644;opacity: 0.8;padding-top:24px;'>Date & Time</span>
                    <span style='font-weight: 500;font-size: 18px;line-height: 160%;color: #393644;'>${moment(startTripDatetime).format('D MMM YYYY HH:mm')}</span>
                    <span style='font-size: 14px;line-height: 160%;text-transform: capitalize;color: #393644;opacity: 0.8;padding-top:24px;'>Service Charge</span>
                    <span style='font-weight: 500;font-size: 18px;line-height: 160%;color: #393644;'>${getSymbolFromCurrency(currency)} ${total}</span>
                </div>
                <div style='display: flex;width: 33.33%;flex-direction: column;'>
                    <span style='font-size: 14px;line-height: 160%;text-transform: capitalize;color: #393644;opacity: 0.8;padding-top:24px;'>Order Number</span>
                    <span style='font-weight: 500;font-size: 18px;line-height: 160%;color: #393644;'>${id}</span>
                    <span style='font-size: 14px;line-height: 160%;text-transform: capitalize;color: #393644;opacity: 0.8;padding-top:24px;'>Service provider phone</span>
                    <span style='font-weight: 500;font-size: 18px;line-height: 160%;color: #393644;'>${driverPhone || '-'}</span>
                </div>
                <div style='display: flex;width: 33.33%;flex-direction: column;'>
                    <span style='font-size: 14px;line-height: 160%;text-transform: capitalize;color: #393644;opacity: 0.8;padding-top:24px;'>Your vehicle</span>
                    <span style='font-weight: 500;font-size: 18px;line-height: 160%;color: #393644;'>${carTitle}</span>
                    <img  width='190' src=${image || default_car} alt="vehicle" />
                </div>
            </div>
            <div style='display: flex;width: 100%;flex-direction: column;'>
                <span style='font-size: 14px;line-height: 160%;text-transform: capitalize;color: #393644;opacity: 0.8;padding-top:24px;'>${Translater.t('pickupLocationTitle')}</span>
                <span style='font-weight: 500;font-size: 18px;line-height: 160%;color: #393644;'>${pickupLocation.name}</span>
            </div>
            <div style='display: flex;width: 100%;flex-direction: column;'>
                <span style='font-size: 14px;line-height: 160%;text-transform: capitalize;color: #393644;opacity: 0.8;padding-top:24px;'>Dropoff</span>
                <span style='font-weight: 500;font-size: 18px;line-height: 160%;color: #393644;'>${dropoffLocation.name}</span>
            </div>
            <div style='display: flex;width: 100%;flex-direction: column;'>
                <span style='font-size: 14px;line-height: 160%;text-transform: capitalize;color: #393644;opacity: 0.8;padding-top:24px;'>Comments</span>
                <span style='font-weight: 500;font-size: 18px;line-height: 160%;color: #393644;'>${comments || '-'}</span>
            </div>
            <div style='display: flex;width: 100%; background: #C2C2C5; flex-direction: column;'>
                <div style='font-weight: 500;font-size: 16px;line-height: 160%;color: #393644;padding-top:32px;'>Special pickup instructions:</div>
                <div style='font-size: 12px;line-height: 160%;color: #393644;padding-top:18px;'>${pickupInstruction}</div>
                <div style='font-weight: 500;font-size: 16px;line-height: 160%;color: #393644;padding-top:24px;'>We thank you for your business and wish you a pleasant trip.</div>
                <div><a style='color:#4E31B5; text-decoration: none;font-weight: 500; font-size: 16px; line-height: 160%;padding-top:32px;' href='http://devwazupfrontend-env.eba-peajaupd.us-east-2.elasticbeanstalk.com'>www.WAZUP.com</a></div>
            </div>
        </div>
    `)
}