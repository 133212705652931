import React from 'react';
import { withLayout } from '../../common/layout';
import I18n from '../../i18n';
import { Modal } from 'antd';
import successImg from '../../assets/icons/success_payment.svg';
import { Link } from 'react-router-i18n';

class SuccessPayment extends React.Component {
    render() {
        return (
            <div id='success-modal'>
                <Modal title="Basic Modal"
                       visible={true}
                       centered={true}
                       footer={null}
                       maskClosable={false}
                       getContainer={() => document.getElementById('success-modal')}>
                    <div className="d-flex flex-column align-items-center">
                        <img className="m-3" src={successImg} alt='success-modal' />
                        <span className="payment-title mt-3 mb-3"><I18n t="paymentSuccessMsg" /></span>
                        <span className="payment-subtitle mt-3 mb-3"><I18n t="paymentSuccessSubMsg"></I18n></span>
                        <Link to="/">
                            <button className="payment-button mt-3">
                                <I18n t="closeTitle" />
                            </button>
                        </Link>
                    </div>
                </Modal>
            </div>
        );
    }
}

const connectedSuccessPayment = (withLayout(SuccessPayment));
export { connectedSuccessPayment as SuccessPayment };