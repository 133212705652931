import React from 'react';
import { connect } from 'react-redux';
import { withLayout } from '../../common/layout';
import { homeActions } from '../home/home-reducer';
import { PROP_KEYS } from '../home/home-constants';

class FAQ extends React.Component {

    componentDidMount() {
        document.getElementById("initialTripAdvisorWidget").className = "tripAdvisorWidget-content";
        this.props.getFaqHtml();
    }

    componentWillUpdate() {
        document.getElementById("initialTripAdvisorWidget").className = "tripAdvisorWidget-content";
    }

    componentWillUnmount() {
        document.getElementById("initialTripAdvisorWidget").className = "display-none";
    }

    render() {
        const html = { __html: this.props.faqPage };

        return (
            <div dangerouslySetInnerHTML={html}></div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, location: { faqPage } } = state;
    const { user } = authentication;
    return {
        user,
        faqPage
    };
}

const mapDispatchToProps = {
    getFaqHtml: () => homeActions.loadHtml(PROP_KEYS.faqPage)
}

const connectedFAQ = connect(mapStateToProps, mapDispatchToProps)(withLayout(FAQ));
export { connectedFAQ as FAQ };