import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import windowSize from 'react-window-size';
import { Layout, Button, Popconfirm, Tooltip, Modal } from 'antd';
import { Link } from 'react-router-i18n';
import { TIME_TYPE, CARD_TYPES } from './user-constants';
import printJS from 'print-js';
import { recordToRawHtml } from './user-formatters';
import moment from 'moment';
import { I18n as Translater } from 'react-redux-i18n';
import getSymbolFromCurrency from 'currency-symbol-map';
import { MapComponent } from '../../direction-maps';
import { config } from '../../config';
import { userActions } from './user-reducer';
import { IssuesCloseOutlined } from '@ant-design/icons';
import { STRING_EMPTY } from '../../assets/constants/global';

import paypal from '../../assets/icons/paypal.png';
import showDetails from '../../assets/icons/show_details.svg';
import print from '../../assets/icons/print.svg';
import edit from '../../assets/icons/edit.svg';
import blocked from '../../assets/icons/blocked.svg';
import remove from '../../assets/icons/remove.svg';
import user from '../../assets/icons/user.svg';
import car from '../../assets/icons/car.svg';
import phone from '../../assets/icons/phone.svg';
import email from '../../assets/icons/email.svg';
import cardLast4NumbImg from '../../assets/icons/cardLast4Numb.svg';
import mastercard from '../../assets/icons/mastercard.svg';
import visa from '../../assets/icons/visa.svg';

const { Header, Footer, Content } = Layout;

class TripDetails extends React.Component {
    confirmDelete = (record) => {
        const { deleteTrip } = this.props;

        Modal.confirm({
            icon: <IssuesCloseOutlined style={{ color: '#ff4d4f' }} />,
            title: 'Confirm delete',
            content: `Your trip is due to begin in less than ${record.minFreeCancelationTime} hours. But refund might not be given since it’s a late cancel! ${Translater.t('deleteTripMsg')}`,
            okText: 'Delete',
            cancelText: 'Cancel',
            centered: true,
            onOk: () => deleteTrip(record.id)
        });
    }
    
    render() {
        const { match, windowHeight, windowWidth, myOrders, locale, deleteTrip, editTrip } = this.props;
        const record = match && match.params && match.params.tripId && myOrders.find(x => x.id === parseInt(match.params.tripId));

        return (
            <div className='trip-details'>
                {
                    record && (
                        <Layout style={{
                            height: windowHeight,
                            width: windowWidth
                        }}>
                          <Header>
                              <div className="d-flex justify-content-between">
                                    <Link to='/user'>
                                        <Button type="link">
                                            <img className='go-back' src={showDetails} alt="showDetails" />
                                        </Button>
                                    </Link>
                                    <div className="d-flex flex-column card-details">
                                        { record.cardBrand && record.cardBrand !== STRING_EMPTY && <img className='card' src={record.cardBrand === CARD_TYPES.Visa ? visa : record.cardBrand === CARD_TYPES.MasterCard ? mastercard : paypal} alt="cardBrand" /> }
                                        <div className="d-flex align-items-baseline price-block mt-2">
                                            <span className="dollar">{getSymbolFromCurrency(record.currency)}</span>
                                            <span className="price">
                                                {record.total.toFixed(0)}.<span className="decimal">{(record.total % 1).toFixed(2) * 100}</span>
                                            </span>
                                        </div>
                                        { record.cardLast4Numb && <span className='location-label mt-1'><img className='mr-2' src={cardLast4NumbImg} alt="cardLast4NumbImgPast" />{record.cardLast4Numb}</span> }
                                    </div>
                              </div>
                          </Header>
                          <Content>
                              <div className="d-flex flex-column">
                                    { record.includedVipService && <span className='vip-user-label mb-3'>VIP Service Included</span> }
                                    <div className="d-flex date-time align-items-center">
                                      <span className='date mt-1'>{moment(record.startTripDatetime).format('D MMM')}</span>
                                      <span className='date time mt-1'>{moment(record.startTripDatetime).format('HH:mm')}</span>
                                    </div>
                                    <span className='trip-label'>{Translater.t('pickupLocationTitle')}</span>
                                    <span className='trip-value mt-1'>{record.pickupLocation.title}</span>
                                    <span className='trip-label mt-3'>{Translater.t('destinationTitle')}</span>
                                    <span className='trip-value mt-1'>{record.dropoffLocation.title}</span>
                                    <div className="d-flex">
                                    <MapComponent
                                          center={{ lat: record.pickupLocation.lat, lng: record.pickupLocation.lng }}
                                          pickupLocation={{ lat: record.pickupLocation.lat, lng: record.pickupLocation.lng }}
                                          destination={{ lat: record.dropoffLocation.lat, lng: record.dropoffLocation.lng }}
                                          isTripDetails={true}
                                          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.googleMapKey}&libraries=geometry,drawing,places&language=${locale}`}
                                          loadingElement={<div style={{ height: windowHeight }} />}
                                          containerElement={<div className="w-100" />}
                                          mapElement={<div className="map-element" style={{ height: 120, maxHeight: 120, maxWidth: windowWidth }} />}
                                    />
                                    </div>
                                    <span className='trip-label mt-3'>{Translater.t('driverTitle')}</span>
                                    {
                                        record.driver && (
                                            <Fragment>
                                                <span className='trip-value mt-1'><img className='mr-2' src={user} alt="driverName" />{record.driver.fullName}</span>
                                                <span className='trip-value mt-1'><img className='mr-2' src={phone} alt="driverPhone" />{record.driver.phone}</span>
                                            </Fragment>
                                        )
                                    }
                                    <span className='trip-label mt-3'>{Translater.t('agentTitle')}</span>
                                    {
                                        record.agent && (
                                            <Fragment>
                                                <span className='trip-value mt-1'><img className='mr-2' src={user} alt="driverName" />{record.agent.fullName}</span>
                                                <span className='trip-value mt-1'><img className='mr-2' src={phone} alt="driverPhone" />{record.agent.phone}</span>
                                            </Fragment>
                                        )
                                    }
                                    <span className='trip-value mt-1'><img className='mr-2' src={car} alt="car" />{record.carTitle}</span>
                                    {
                                        record.contact && (
                                            <Fragment>
                                                <span className='trip-label mt-3'>{Translater.t('passengerTitle')}</span>
                                                <span className='trip-value mt-1'><img className='mr-2' src={user} alt="userName" />{record.contact.fullName}</span>
                                                <span className='trip-value mt-1'><img className='mr-2' src={phone} alt="userPhone" />{record.contact.phone}</span>
                                                <span className='trip-value mt-1'><img className='mr-2' src={email} alt="userEmail" />{record.contact.email}</span>
                                            </Fragment>
                                        )
                                    }
                              </div>
                          </Content>
                          <Footer>
                            <div className="d-flex justify-content-between actions">
                                <Button onClick={() => printJS({ printable: recordToRawHtml(record), type: 'raw-html' })}
                                    className="user-trip-datails-button mr-2"
                                    type="text">
                                    <img src={print} alt="print" />
                                </Button>

                                { 
                                    record.timeType === TIME_TYPE.Upcoming && (
                                        record.minFreeCancelationTime && 
                                        moment(new Date()).isAfter(moment(record.startTripDatetime).add(record.minFreeCancelationTime * (-1), 'hour')) ? (
                                            <Tooltip getPopupContainer={trigger => trigger.parentNode} trigger={[ 'hover', 'click' ]} className='disabled' title={`Your trip is due to begin in less than ${record.minFreeCancelationTime} hours, please contact the SUPPLIER PHONE directly for any last minute changes.`}>
                                                <Button type="text" className="user-trip-datails-button mr-2">
                                                    <img src={edit} alt="edit" />
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            <Popconfirm title={Translater.t('editTripMsg')}
                                                        onConfirm={() => editTrip(record.id)}
                                                        onCancel={() => ({})}
                                                        okText={Translater.t('yesTitle')}
                                                        cancelText={Translater.t('noTitle')}>
                                                   <Button type="text" className="user-trip-datails-button mr-2">
                                                        <img src={edit} alt="edit" />
                                                    </Button>
                                            </Popconfirm>
                                        )
                                    )
                                }
                                { 
                                    record.timeType === TIME_TYPE.Past 
                                        ? (<img className='mr-2 disabled' src={blocked} alt="blocked" />)
                                        : (
                                            record.minFreeCancelationTime && 
                                            moment(new Date()).isAfter(moment(record.startTripDatetime).add(record.minFreeCancelationTime * (-1), 'hour')) ? (
                                                <Button type="text" className="user-trip-datails-button mr-2">
                                                    <img src={remove} alt="remove" onClick={() => this.confirmDelete(record)} />
                                                </Button>                                                
                                            ) : (
                                                <Popconfirm title={Translater.t('deleteTripMsg')}
                                                            onConfirm={() => deleteTrip(record.id)}
                                                            onCancel={() => ({})}
                                                            okText={Translater.t('yesTitle')}
                                                            cancelText={Translater.t('noTitle')}
                                                            placement="topRight">
                                                    <Button type="text" className="user-trip-datails-button mr-2">
                                                        <img src={remove} alt="remove" />
                                                    </Button>
                                                </Popconfirm>
                                            )
                                        )
                                    }
                            </div>
                          </Footer>
                        </Layout>
                    )
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, i18n: { locale } } = state;
    const { myOrders } = authentication;

    return {
        myOrders,
        locale
    };
}

const mapDispatchToProps = {
    deleteTrip: (tripId) => userActions.deleteTrip(tripId),
    editTrip: (tripId) => userActions.editTrip(tripId)
}

const connectedTripDetails = connect(mapStateToProps, mapDispatchToProps)(windowSize(TripDetails));
export { connectedTripDetails as TripDetails };