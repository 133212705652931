import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import { authentication } from './pages/user/user-reducer';
import { location } from './pages/home/home-reducer';
import { admin } from './pages/admin-panel/admin-reducer';
import translations from './i18n/translations';
import {
    setLocale,
    loadTranslations,
    syncTranslationWithStore,
  } from "react-redux-i18n";
import { defaultLocale, locales } from "./i18n/config";

const loggerMiddleware = createLogger();

const rootReducer = combineReducers({
    authentication,
    location,
    admin,
    i18n: i18nReducer
});

const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));

locales.forEach(locale =>
    window.location.pathname.includes(`/${locale}`) ?
        store.dispatch(setLocale(locale)) :
        store.dispatch(setLocale(defaultLocale)));

export default store;