import React from "react";
import { Radio } from 'antd';

export const PaymentRadio = (props) => {
    const { value, title, selected, children, disabled } = props;
    
    return (
        <div className={`d-flex align-items-center w-100 p-5 mt-3 payment-radio`}>
            <div className="d-flex flex-column w-100">
                <Radio disabled={disabled} value={value}>{title}</Radio>
                {
                    selected && (
                        <div className='d-flex child'>{children}</div>
                    )
                }
            </div>
        </div>
    )
}
    